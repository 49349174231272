import { formatCurrency } from "@/hooks/format-currency";
import styles from "./styles.module.scss";

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  data?: any[];
}

const movements: any = {
  total_sales_counts: { title: "Кількість" },
  total_sales_value: { title: "Сумма", format: formatCurrency },
};

export const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  data,
}) => {
  if (active && payload && payload.length) {
    const tooltipData = payload.map((entry) => {
      const dataItem = data?.find((item: any) => item.name === entry.name);
      return { ...entry, ...dataItem };
    });

    return (
      <div className={styles.tooltip}>
        {tooltipData.map((entry, index) => (
          <p key={index} style={{ color: "#000" }}>
            {movements[entry.name].title}:{" "}
            {movements[entry.name].format
              ? movements[entry.name].format(entry.value || 0)
              : entry.value}
          </p>
        ))}
      </div>
    );
  }

  return null;
};
