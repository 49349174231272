import Link from "next/link";
import styles from "./styles.module.scss";
import { useRouter } from "next/router";

export const Paginations = ({
  page,
  counts,
}: {
  page: number;
  counts: number;
}) => {
  const router: any = useRouter();
  const { query } = router;

  const totalPages = Math.ceil(counts / 10);
  const currentPage = Number(page);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const getPageNumbersToShow = () => {
    if (totalPages <= 6) {
      return pageNumbers;
    }
    if (!query.page || currentPage < 3) {
      return [1, 2, 3, "...", totalPages - 2, totalPages - 1, totalPages];
    }
    if (currentPage >= totalPages - 2) {
      return [1, "...", totalPages - 2, totalPages - 1, totalPages];
    }
    return [
      1,
      "...",
      currentPage - 1,
      currentPage,
      currentPage + 1,
      "...",
      totalPages,
    ];
  };

  const pageNumbersToShow = getPageNumbersToShow();
  if (pageNumbersToShow.length < 2) return null;
  return (
    <div className={styles.pages}>
      {pageNumbersToShow.map((pageNumber) => {
        if (pageNumber === "...")
          return <div className={styles.pageBTN}>{pageNumber}</div>;
        return (
          <Link
            key={pageNumber}
            href={{
              pathname: router.pathname,
              query: { ...query, page: pageNumber },
            }}
            passHref
          >
            <div
              className={`${styles.pageBTN} ${
                currentPage === pageNumber ? styles.active : ""
              }`}
            >
              {pageNumber}
            </div>
          </Link>
        );
      })}
    </div>
  );
};
