import moment from "moment";
import styles from "./styles.module.scss";
import { DateInput } from "@mantine/dates";
import { FetchAPI } from "@/hooks/fetch";
import { ModalButtons, Select } from "@/components";
import { NumberInput } from "@mantine/core";
import { useEffect, useState } from "react";
import { Notification } from "@/hooks/notification";

export const AddBooking = ({
  closeFunction,
  onUpdate,
}: {
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const [selectSection, setSelectSection] = useState<number | null>(null);
  const [data, setData] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [product, setProductData] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(true);

  const changeStorage = async () => {
    if (selectSection) {
      const { data: prods } = await FetchAPI(
        "GET",
        `/api/controls/storage?id=${selectSection}`
      );

      await setProducts(
        prods.filter(
          (x: any) =>
            x.product_type === "product" || x.product_type === "not_formal"
        )
      );
    }
  };

  const submit = async (e: any) => {
    e.preventDefault();
    const formData: any = new FormData(e.target);

    const jsonObject: any = {
      item_type: product.product_type,
      item_id: product.product_id,
    };
    for (const [key, value] of formData.entries()) {
      jsonObject[key] = value;
    }

    await FetchAPI("POST", `/api/controls/booking`, jsonObject, null, {
      "Content-Type": "application/json",
    });

    await Notification("Успішно заброньовано", { autoClose: 3000 }, "info");

    closeFunction();
    onUpdate();
  };

  useEffect(() => {
    changeStorage();
  }, [selectSection]);

  useEffect(() => {
    (async () => {
      const { data: debitors } = await FetchAPI("GET", `/api/helper/debitors`);
      const { data: storages } = await FetchAPI(
        "GET",
        `/api/controls/storages`
      );

      await setData({
        storages: storages.data,
        debitors: debitors.data,
      });
      await setLoad(false);
    })();
  }, []);

  return (
    <form className={styles.content} onSubmit={submit}>
      <Select
        placeholder="Покупець"
        required={true}
        list={data.debitors}
        name={"debitor_id"}
        error={{
          enabled: data?.debitors?.length === 0,
          text: "Відсутні покупці",
        }}
      />

      <Select
        placeholder="Склад"
        required={true}
        list={data.storages}
        name={"storage_id"}
        onChange={async (e: any) => {
          await setSelectSection(Number(e.target.value));
        }}
      />

      {products && (
        <div className={styles.flex}>
          <Select
            placeholder="Продукт"
            required={true}
            list={products?.map((el: any) => {
              return {
                id: el.id,
                title: `${el.product_title} (№ партії ${moment(
                  data.create_date
                ).format("YYYY-MM-DD")})`,
              };
            })}
            name={"part_id"}
            onChange={(e: any) => {
              const find = products.find(
                (x: any) => x.id === Number(e.target.value)
              );

              if (find) {
                setProductData(find);
              }
            }}
            error={{
              enabled: products.length === 0,
              text: "Елементи для переміщення відсутні",
            }}
          />
          <NumberInput
            required={true}
            disabled={!product}
            max={product?.counts || 0}
            min={0}
            name="counts"
            label="Кількість"
            placeholder={product ? `Макс. ${product.counts}` : ""}
          />
        </div>
      )}

      <DateInput required={true} label={"Дата закінчення"} name="to_date" />

      <ModalButtons closeFunction={closeFunction} />
    </form>
  );
};
