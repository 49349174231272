type paginationType = {
  productsCurrentParPage: number;
  totalProcuts: number;
  paginate: (number: number) => void;
};

const Pagination = ({
  productsCurrentParPage,
  totalProcuts,
  paginate,
}: paginationType) => {
  const pageNumbers = [];

  for (let i = 0; i < Math.ceil(totalProcuts / productsCurrentParPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <div style={{ marginRight: "55px" }}>
      {pageNumbers.map((number) => (
        <button
          key={number}
          className="buttonPagination"
          onClick={() => paginate(number)}
        >
          {number + 1}
        </button>
      ))}
    </div>
  );
};
export default Pagination;
