import getConfig from "next/config";
import styles from "./styles.module.scss";
import { FetchAPI } from "@/hooks/fetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatCurrency } from "@/hooks/format-currency";
import { Icons } from "@/components";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import {
  Badge,
  Blockquote,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Group,
  Image,
  LoadingOverlay,
  Skeleton,
  Tabs,
  Text,
} from "@mantine/core";
import {
  faBreadSlice,
  faBurger,
  faCarrot,
  faComment,
  faDownload,
  faImage,
} from "@fortawesome/free-solid-svg-icons";

const {
  publicRuntimeConfig: { API_URL },
} = getConfig();

export const ViewProduct = ({
  data,
  closeFunction,
}: {
  data?: any;
  closeFunction: () => void;
}) => {
  const [content, setContent] = useState<any>(null);
  const [visible, { open, close }] = useDisclosure(true);
  const sizes: any = {
    kg: { title: "гр." },
    st: { title: "шт." },
    l: { title: "мл." },
  };

  const getFileLink = (filename: string) => {
    return `${API_URL}/product/${filename}`;
  };

  const init = async () => {
    await open();
    const { data: categories } = await FetchAPI(
      "GET",
      `/api/helper/categories`
    );
    const { data: not_formal } = await FetchAPI(
      "GET",
      `/api/helper/not_formal`
    );
    const { data: products } = await FetchAPI("GET", `/api/helper/product`);

    const { data: packages } = await FetchAPI("GET", `/api/helper/package`);

    await setContent({
      categories: categories.data,
      not_formals: not_formal.data,
      packages: packages.data,
      products: products.data,
    });
    setTimeout(() => {
      close();
    }, 1000);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Box className={styles.content} pos="relative">
      {/* <LoadingOverlay visible={visible} zIndex={1000} /> */}
      <Card shadow="sm" padding="xs" radius="md" withBorder>
        {visible ? (
          <Skeleton style={{ flex: "none" }} h={160} w={"100%"} radius="sm" />
        ) : (
          <Card.Section>
            {data?.image?.filename ? (
              <Image
                src={getFileLink(data.image.filename)}
                height={160}
                alt="Norway"
              />
            ) : (
              <Flex
                h={160}
                w="100%"
                justify="center"
                align={"center"}
                className={styles.notPhoto}
              >
                <FontAwesomeIcon icon={faImage} />
              </Flex>
            )}
          </Card.Section>
        )}

        <Flex justify="space-between" mt="md" mb="xs">
          <Text fw={500}>{data?.title || "..."}</Text>
          <Badge color="pink">On Sale</Badge>
        </Flex>

        <Flex direction={"column"} gap={"7px"}>
          {visible ? (
            <Skeleton style={{ flex: "none" }} h={14} w={"100%"} radius="sm" />
          ) : (
            <Text size={"14px"} lh={"14px"}>
              Одиниця виміру: {data?.size || "..."}
            </Text>
          )}
          {visible ? (
            <Skeleton style={{ flex: "none" }} h={14} w={"100%"} radius="sm" />
          ) : (
            <Text size={"14px"} lh={"14px"}>
              Роздрібна вартість: {formatCurrency(data?.small_price) || "..."}
            </Text>
          )}
          {visible ? (
            <Skeleton style={{ flex: "none" }} h={14} w={"100%"} radius="sm" />
          ) : (
            <Text size={"14px"} lh={"14px"}>
              Оптова вартість: {formatCurrency(data?.wholesale) || "..."}
            </Text>
          )}
          {visible ? (
            <Skeleton style={{ flex: "none" }} h={14} w={"100%"} radius="sm" />
          ) : (
            <Text size={"14px"} lh={"14px"}>
              Вартість виробництва:{" "}
              {formatCurrency(data?.manufacturing_cost) || "..."}
            </Text>
          )}
        </Flex>
        <Divider my="xs" />

        <Tabs defaultValue="comment">
          <Tabs.List>
            <Tabs.Tab disabled={visible} value="comment" c={"#585d8f"}>
              Коментар
            </Tabs.Tab>
            <Tabs.Tab
              disabled={visible || !data?.product_materials_category}
              value="materials"
              c={"#585d8f"}
            >
              Інгридієнти
            </Tabs.Tab>

            <Tabs.Tab
              disabled={visible || !data?.product_not_formals}
              value="not_formals"
              c={"#585d8f"}
            >
              Напівфабрикати
            </Tabs.Tab>

            <Tabs.Tab
              disabled={visible || !data?.product_childs}
              value="products"
              c={"#585d8f"}
            >
              Продукти
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="comment">
            {visible ? (
              <Skeleton
                style={{ flex: "none" }}
                h={14}
                w={"100%"}
                radius="sm"
                mt={14}
              />
            ) : (
              <Text c="dimmed" mt={14} size={"14px"} lh={"14px"}>
                {data?.comment || "Коментар відсутній"}
              </Text>
            )}
          </Tabs.Panel>
          <Tabs.Panel value="materials">
            <ul className={styles.list}>
              {data?.product_materials_category?.map((el: any, key: number) => {
                const material = content?.categories?.find(
                  (e: any) => e.id === el.categorie_id
                );
                return (
                  <li key={key}>
                    <Flex w={"100%"} justify={"space-between"}>
                      <Text size={"14px"} lh={"14px"}>
                        {material?.title}
                      </Text>
                      <Text size={"14px"} lh={"14px"}>
                        {el.size} {sizes[el.size_val]?.title}
                      </Text>
                    </Flex>
                  </li>
                );
              })}
            </ul>
          </Tabs.Panel>
          <Tabs.Panel value="not_formals">
            <ul className={styles.list}>
              {data?.product_not_formals?.map((el: any, key: number) => {
                const material = content?.not_formals?.find(
                  (e: any) => e.id === el.not_formal_id
                );
                return (
                  <li key={key}>
                    <Flex w={"100%"} justify={"space-between"}>
                      <Text size={"14px"} lh={"14px"}>
                        {material?.title}
                      </Text>
                      <Text size={"14px"} lh={"14px"}>
                        {el.size} {sizes[material?.size]?.title}
                      </Text>
                    </Flex>
                  </li>
                );
              })}
            </ul>
          </Tabs.Panel>
          <Tabs.Panel value="products">
            <ul className={styles.list}>
              {data?.product_childs?.map((el: any, key: number) => {
                const material = content?.products?.find(
                  (e: any) => e.id === el.child_id
                );
                return (
                  <li key={key}>
                    <Flex w={"100%"} justify={"space-between"}>
                      <Text size={"14px"} lh={"14px"}>
                        {material?.title}
                      </Text>
                      <Text size={"14px"} lh={"14px"}>
                        {el.size} {sizes[material?.size]?.title}
                      </Text>
                    </Flex>
                  </li>
                );
              })}
            </ul>
          </Tabs.Panel>
        </Tabs>
        <Button color="blue" fullWidth mt="md" radius="md" loading={visible}>
          {visible ? "" : "Завантажити тех. карту"}
        </Button>
      </Card>
    </Box>
  );
};
