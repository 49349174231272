import Modals from "@/modals";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Button } from "@mantine/core";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "@/containers/modal";
import { Swiper, SwiperSlide } from "swiper/react";
import "moment/locale/uk";
import "swiper/css";

moment.locale("uk");

export const CalendarLine = ({
  data,
  defaultValue,
  onChange,
}: {
  data: any;
  defaultValue: string | null;
  onChange: (value: string) => void;
}) => {
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [selected, setSelected] = useState<any>();
  const [width, setWidth] = useState<number>(0);
  const [paysList, setPaysList] = useState<any>(null);

  const daysArray = [];
  const daysInMonth = currentMonth.daysInMonth();

  for (let day = 1; day <= daysInMonth; day++) {
    const dayName = currentMonth.date(day).format("dd");
    daysArray.push({
      name: dayName,
      day,
      fullDate: currentMonth.date(day).format("DD-MM-YYYY"),
    });
  }

  const goToPreviousMonth = () => {
    setCurrentMonth(currentMonth.clone().subtract(1, "month"));
  };

  const goToNextMonth = () => {
    setCurrentMonth(currentMonth.clone().add(1, "month"));
  };

  useEffect(() => {
    onChange(currentMonth.format("YYYY-MM-DD"));
  }, [currentMonth]);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener(
      "resize",
      () => {
        setWidth(window.innerWidth);
      },
      false
    );

    return () => {
      window.removeEventListener(
        "resize",
        () => {
          setWidth(window.innerWidth);
        },
        false
      );
    };
  }, []);

  return (
    <>
      <div className={styles.calendar}>
        <div className={styles.headLine}>
          <Button variant="white" type="button" onClick={goToPreviousMonth}>
            <FontAwesomeIcon width={14} icon={faArrowLeft} />
          </Button>

          <div className={styles.title}>
            <div className={styles.month}>{currentMonth.format("MMMM")}</div>
            <div className={styles.year}>{currentMonth.format("YYYY")}</div>
          </div>

          <Button variant="white" type="button" onClick={goToNextMonth}>
            <FontAwesomeIcon width={14} icon={faArrowRight} />
          </Button>
        </div>

        <div className={styles.calendarLine}>
          <div className={styles.content}>
            {width >= 1570 ? (
              daysArray.map((day: any, index: number) => (
                <div
                  key={index}
                  className={`
              ${styles.dayBox} 
              ${
                day.fullDate === moment().format("DD-MM-YYYY")
                  ? styles.current
                  : ""
              }
              ${day.fullDate === selected ? styles.selected : ""}              
              `}
                  onClick={() => {
                    setSelected(day.fullDate);
                    if (data[day.fullDate][0]) {
                      setPaysList(data[day.fullDate]);
                    }
                  }}
                >
                  {data[day.fullDate]?.length > 0 && (
                    <div className={`${styles.dot} ${styles.yellow}`}></div>
                  )}
                  <div className={styles.date}>{day.day}</div>
                  <div className={styles.name}>{day.name}</div>
                </div>
              ))
            ) : (
              <Swiper
                spaceBetween={7}
                slidesPerView={10}
                className="dateSwiper"
                breakpoints={
                  {
                    // when window width is >= 640px
                    // 640: {
                    //   slidesPerView: 1,
                    // },
                    // // when window width is >= 768px
                    // 768: {
                    //   slidesPerView: 2,
                    // },
                    // // when window width is >= 1024px
                    // 1024: {
                    //   slidesPerView: 3,
                    // },
                    // // when window width is >= 1366px
                    // 1366: {
                    //   slidesPerView: 4,
                    // },
                  }
                }
              >
                {daysArray.map((day: any, index: number) => (
                  <SwiperSlide key={index}>
                    <div
                      key={index}
                      className={`
                    ${styles.dayBox} 
                    ${
                      day.fullDate === moment().format("DD-MM-YYYY")
                        ? styles.current
                        : ""
                    }
                    ${
                      day.fullDate === selected ? styles.selected : ""
                    }              
                  `}
                      onClick={() => {
                        setSelected(day.fullDate);
                        if (data[day.fullDate][0]) {
                          setPaysList(data[day.fullDate]);
                        }
                      }}
                    >
                      {data[day.fullDate]?.length > 0 && (
                        <div className={`${styles.dot} ${styles.yellow}`}></div>
                      )}
                      <div className={styles.date}>{day.day}</div>
                      <div className={styles.name}>{day.name}</div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </div>

      <Modal
        title={"Список платежів на: " + selected}
        open={paysList}
        closeFunction={() => {
          setPaysList(null);
        }}
      >
        <Modals.PaysList
          data={paysList}
          date={selected}
          closeFunction={() => {
            setPaysList(null);
          }}
        />
      </Modal>
    </>
  );
};
