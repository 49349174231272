import getConfig from "next/config";
import styles from "./styles.module.scss";
import {
  Badge,
  Blockquote,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Group,
  Image,
  LoadingOverlay,
  Skeleton,
  Tabs,
  Text,
} from "@mantine/core";

const {
  publicRuntimeConfig: { API_URL },
} = getConfig();

export const OpenClient = ({
  data,
  closeFunction,
}: {
  data?: any;
  closeFunction: () => void;
}) => {
  return (
    <Box className={styles.content} pos="relative">
      <iframe></iframe>
    </Box>
  );
};
