import styles from "./styles.module.scss";
import { Alert } from "@mantine/core";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatCurrency } from "@/hooks/format-currency";

export const PaysList = ({
  data,
  date,
  closeFunction,
}: {
  data: any[];
  date: any;
  closeFunction: () => void;
}) => {
  return (
    <div className={styles.content}>
      <Alert variant="light" color="blue">
        Список платежів від покупців на <b>{date}</b>
      </Alert>

      {data?.map((pay: any, key: number) => (
        <div key={key} className={styles.payLine}>
          <div className={styles.debtTitle}>{pay.title}</div>
          <div className={styles.debtSumm}>
            До сплати: {formatCurrency(pay.pay_summ || 0)}
          </div>
        </div>
      ))}
    </div>
  );
};
