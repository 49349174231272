import LoadModal from "@/components/load-modal";
import styles from "./styles.module.scss";
import { FetchAPI } from "@/hooks/fetch";
import { init } from "./init.request";
import { Input } from "@mantine/core";
import { ModalButtons, Select, Textarea } from "@/components";
import { movements } from "./default.vars";
import { Notification } from "@/hooks/notification";
import { useEffect, useState } from "react";

export const AddAccountingOfNotFormals = ({
  edit,
  closeFunction,
  onUpdate,
}: {
  edit?: any;
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const [data, setData] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [partions, setPartions] = useState<any[]>([]);
  const [movement, setMovement] = useState<string>("coming");

  useEffect(() => {
    (async () => {
      await init(setData, setFirstLoad);
    })();
  }, []);

  const submit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    await setLoad(true);
    try {
      await FetchAPI(
        edit ? "PUT" : "POST",
        `/api/accounting/accounting_of_not_formals${edit ? `/${edit.id}` : ""}`,
        formData,
        null,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      await Notification(
        edit ? "Рух напівфабрикату змінено" : "Рух напівфабрикату виконано",
        { autoClose: 3000 },
        "info"
      );
      onUpdate();
      closeFunction();
    } catch (e: any) {
      await Notification(
        "Упс... нажаль сталася помилка",
        { autoClose: 3000 },
        "error"
      );
    }
    await setLoad(false);
  };

  return (
    <form className={styles.content} onSubmit={submit}>
      {firstLoad ? (
        <LoadModal length={5} />
      ) : (
        <>
          <Input.Wrapper description="Дата">
            <Input
              type="date"
              name="date"
              required={true}
              placeholder="Дата"
              defaultValue={edit?.date}
            />
          </Input.Wrapper>
          <Select
            defaultSelect={edit?.movement}
            placeholder="Рух"
            required={true}
            list={movements}
            onChange={(e) => {
              setMovement(e.target.value);
            }}
            name={"movement"}
          />

          {movement !== "coming" ? (
            <>
              <Select
                required={true}
                list={data.products_storages}
                placeholder="Напівфабрикат"
                name={"not_formal_id"}
                customValueKey={"product_id"}
                onChange={async (e: any) => {
                  const _val = e.target.value;
                  const { data } = await FetchAPI(
                    "GET",
                    `/api/partions/${_val}/not_formal`
                  );

                  await setPartions(data);
                }}
                error={{
                  enabled: data?.products?.length === 0,
                  text: "Відсутні напівфабрикати",
                }}
              />
              <Select
                required={true}
                list={
                  partions?.map((x: any) => {
                    return {
                      ...x,
                      title: `(№ партії: ${
                        x.batch_of_products?.split("T")[0]
                      }) - ${x.title}`,
                    };
                  }) || []
                }
                placeholder="Партія"
                name={"part_id"}
              />
            </>
          ) : (
            <>
              <Select
                placeholder="Напівфабрикат"
                required={true}
                list={data.materials
                  .filter(
                    (x: any) =>
                      x.product_type === "not_formal" &&
                      x.end_date === null &&
                      x.end_date === null
                  )
                  .map((x: any) => {
                    return {
                      id: x.id,
                      title: `${x.title} (Очікуванна кількість: ${x.expected_quantity})`,
                    };
                  })}
                name={"not_formal_id"}
                defaultSelect={edit?.material_id}
                error={{
                  enabled: data?.materials?.length === 0,
                  text: "Відсутній напівфабрикат",
                }}
              />
            </>
          )}
          <Input.Wrapper description="Кількість">
            <Input
              type="number"
              step="0.01"
              name="counts"
              required={true}
              placeholder={`Кількість`}
            />
          </Input.Wrapper>

          <Textarea
            placeholder="Коментар"
            name="comment"
            defauleValue={edit?.comment}
          />

          <ModalButtons closeFunction={closeFunction} load={load} />
        </>
      )}
    </form>
  );
};
