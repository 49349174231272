import Link from 'next/link';
import styles from './styles.module.scss';
import { useSession } from '@/context/session';

const MainHeader = () => {
    const { session } = useSession();
    return (
        <header className={styles.header}>
            <div></div>
            <div className={styles.session}>
                {session
                    ? <>
                        <div className={styles.sessionBTN}>Вихід</div>
                    </>
                    : <>
                        <Link href={"/login"}><div className={styles.sessionBTN}>Вхід</div></Link>
                        <Link href={"/register"}><div className={styles.sessionBTN}>Реєстрація</div></Link>
                    </>
                }

            </div>
        </header>
    );
};

export default MainHeader;