import getConfig from "next/config";
import LoadAuthForm from "../LoadAuthForm";
import styles from "./styles.module.scss";
import {
  Box,
  Button,
  Input,
  InputBase,
  LoadingOverlay,
  PasswordInput,
  Popover,
  Progress,
  Stack,
  Text,
  Title,
  rem,
} from "@mantine/core";
import { IMaskInput } from "react-imask";
import { Notification } from "@/hooks/notification";
import { setCookie } from "cookies-next";
import { useRouter } from "next/router";
import { useSession } from "@/context/session";
import { useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { IconCheck, IconX } from "@tabler/icons-react";

const {
  publicRuntimeConfig: { API_URL },
} = getConfig();

function PasswordRequirement({
  meets,
  label,
}: {
  meets: boolean;
  label: string;
}) {
  return (
    <Text
      c={meets ? "teal" : "red"}
      style={{ display: "flex", alignItems: "center" }}
      mt={7}
      size="sm"
    >
      {meets ? (
        <IconCheck style={{ width: rem(14), height: rem(14) }} />
      ) : (
        <IconX style={{ width: rem(14), height: rem(14) }} />
      )}{" "}
      <Box ml={10}>{label}</Box>
    </Text>
  );
}

const requirements = [
  { re: /[0-9]/, label: "Числа" },
  { re: /[a-z]/, label: "Маленькі букви" },
  { re: /[A-Z]/, label: "Великі букви" },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Спеціальні символи" },
];

function getStrength(password: string) {
  let multiplier = password.length > 5 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

const RegisterForm = () => {
  const { setSession } = useSession();
  const router = useRouter();
  const [visible, { toggle }] = useDisclosure(false);

  const [error, setError] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(false);
  const [loadForm, setLoadForm] = useState<boolean>(false);
  const [userName, setUsername] = useState<string | null>(null);

  const [popoverOpened, setPopoverOpened] = useState(false);
  const [password, setPassword] = useState("");
  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(password)}
    />
  ));

  const strength = getStrength(password);
  const color = strength === 100 ? "teal" : strength > 50 ? "yellow" : "red";

  const submit = async (e: any) => {
    e.preventDefault();

    try {
      setLoad(true);

      // const result = await fetch(`${API_URL}/api/register`, {
      //   body: JSON.stringify({
      //     name: e.target.name.value,
      //     surname: e.target.surname.value,
      //     email: e.target.email.value,
      //     company: e.target.company.value,
      //     phone: e.target.phone.value,
      //     password: e.target.password.value,
      //   }),
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   method: "POST",
      // });

      // const data = await result.json();

      // if (data?.errors?.length > 0) {
      //   await setError(data?.errors);
      //   await setLoad(false);
      //   if (data?.errors[0]?.text)
      //     await Notification(
      //       data?.errors[0]?.text,
      //       { autoClose: 3000 },
      //       "error"
      //     );
      // } else {
      //   /** TO DO */
      //   // await setLoadForm(true);
      //   // await setCookie("mafi-user-token", data.token);
      //   // await setUsername(data?.username);
      //   // await setSession(data);

      //   await Notification(
      //     `Ви успішно створили профіль користувача, тепер його має активувати адміністрація сервісу. Коли ваш профіль буде активовано ви отримаєте сповіщення на вашу електронну пошту`,
      //     { autoClose: 3000 },
      //     "success"
      //   );
      //   setTimeout(() => {
      //     router.push("/");
      //   }, 1500);
      // }
    } catch (e) {
      console.log(e);
    }
  };

  if (loadForm) return <LoadAuthForm userName={userName} />;

  return (
    <section className={styles.section}>
      <form
        onSubmit={submit}
        className={`${styles.loginForm} ${load ? styles.loading : ""}`}
      >
        <LoadingOverlay
          visible={load}
          zIndex={1000}
          overlayProps={{
            blur: 1,
            color: "rgb(255 242 242 / 50%)",
            bg: "rgb(255 242 242 / 50%)",
          }}
        />
        <Title order={4} ta={"center"} tt={"uppercase"} c={"#228be6"} size={24}>
          Реєстрація
        </Title>

        <Input.Wrapper description="Введіть ваше імя">
          <Input
            name="name"
            type="text"
            required={true}
            error={error?.find((x: any) => x.field === "name")}
            placeholder="Імя"
          />
        </Input.Wrapper>
        <Input.Wrapper description="Введіть ваше прізвище">
          <Input
            name="surname"
            type="text"
            required={true}
            placeholder="Прізвище"
            error={error?.find((x: any) => x.field === "surname")}
          />
        </Input.Wrapper>
        <Input.Wrapper description="Введіть ваш email">
          <Input
            name="email"
            type="email"
            required={true}
            placeholder="Електронна пошта"
            error={error?.find((x: any) => x.field === "email")}
          />
        </Input.Wrapper>
        <Input.Wrapper description="Введыть назву вашої компанії">
          <Input
            name="company"
            type="text"
            required={true}
            placeholder="Компанія"
            error={error?.find((x: any) => x.field === "company")}
          />
        </Input.Wrapper>
        <Input.Wrapper description="Введіть ваш номер телефону">
          <InputBase
            type="tel"
            name="phone"
            required={true}
            component={IMaskInput}
            mask="+38 (000) 00-00-000"
            placeholder="Номер телефону"
            error={error?.find((x: any) => x.field === "phone")}
          />
        </Input.Wrapper>
        <Stack>
          <Popover
            opened={popoverOpened}
            position="bottom"
            width="target"
            transitionProps={{ transition: "pop" }}
          >
            <Popover.Target>
              <div
                onFocusCapture={() => setPopoverOpened(true)}
                onBlurCapture={() => setPopoverOpened(false)}
              >
                <PasswordInput
                  name="password"
                  description="Придумайте складний пароль щоб захистити аккаунт"
                  placeholder="Введіть пароль"
                  required={true}
                  error={error?.find((x: any) => x.field === "password")}
                  visible={visible}
                  onVisibilityChange={toggle}
                  value={password}
                  onChange={(event) => setPassword(event.currentTarget.value)}
                />
              </div>
            </Popover.Target>
            <Popover.Dropdown>
              <Progress color={color} value={strength} size={5} mb="xs" />
              <PasswordRequirement
                label="Введіть більше 6 символів"
                meets={password.length > 5}
              />
              {checks}
            </Popover.Dropdown>
          </Popover>

          <PasswordInput
            description="Повторіть пароль для підтвердження"
            placeholder="Повторіть пароль"
            required={true}
            error={error?.find((x: any) => x.field === "password")}
            visible={visible}
            onVisibilityChange={toggle}
          />
        </Stack>

        <Button type="submit" variant="gradient" loading={load}>
          Створити акаунт
        </Button>
        <Button
          type="button"
          variant="outline"
          onClick={() => router.push("/")}
          disabled={load}
        >
          Я вже зареєстрований
        </Button>
      </form>
    </section>
  );
};

export default RegisterForm;
