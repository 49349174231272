import moment from "moment";
import RangeCalendarData from "../RangeCalendar";
import styles from "./styles.module.scss";
import { _CONST } from "../contants";
import { CalendarLine } from "../CalendarLine";
import { CustomTooltip } from "./tooltip";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FetchAPI } from "@/hooks/fetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatCurrency } from "@/hooks/format-currency";
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { Table } from "../Table";
import { useEffect, useState } from "react";
import {
  Switch,
  Tooltip as MantineTooltip,
  Input,
  CloseButton,
  Loader,
  Box,
  Flex,
} from "@mantine/core";
import { NoneChartData } from "../NoneChartData";

interface ColumnInterface {
  title: string;
  name: string;
}

const columns: ColumnInterface[] = require("./table.json");

export const DebitorsData = () => {
  const [data, setData] = useState<any>([]);
  const [debtFilter, setDebtFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [loadItems, setLoadItems] = useState<boolean>(true);
  const [counts, setCounts] = useState<number>(0);
  const [viewTotalSalesCounts, setViewTotalSalesCounts] =
    useState<boolean>(true);
  const [viewTotalSalesValue, setViewTotalSalesValue] = useState<boolean>(true);
  const [date, setDate] = useState<string | null>(_CONST.current_time);
  const [start, setStart] = useState<string>(_CONST.left_week);
  const [end, setEnd] = useState<string>(_CONST.current_date);
  const [chart, setChart] = useState<any>(null);
  const [calendar, setCalendar] = useState<any>({});
  const [item, setItem] = useState<number>(0);
  const [itemValue, setItemValue] = useState<any | null>(null);

  const getDebitorCalendar = async () => {
    const { data } = await FetchAPI(
      "GET",
      `/api/controls/debitors-calendar/${date}`
    );

    setCalendar(data);
  };
  const getChartData = async (e: number) => {
    const { data } = await FetchAPI(
      "GET",
      `/api/controls/debitors/${start}/${end}/chart/${e}`
    );
    setChart(data);
  };
  const init = async () => {
    await setLoadItems(true);
    const { data } = await FetchAPI(
      "GET",
      `/api/controls/debitors/${start}/${end}`
    );

    await setData(data.data);
    await setCounts(data.counts);
    await setItemValue(data.all_values);
    await setLoadItems(false);
    if (data.chart) {
      setChart(data.chart);
    }
  };

  const parseSearch = (text: string, size: number, sv?: string) => {
    if (text) {
      if (text.length > size) {
        if (sv) {
          const res =
            text
              ?.slice(0, size)
              ?.replace(new RegExp(sv, "ig"), "<b>" + sv + "</b>") + "...";
          return res;
        } else return text.slice(0, size) + "...";
      }
    }
    if (sv) {
      return text?.replace(new RegExp(sv, "ig"), "<b>" + sv + "</b>");
    } else return text;
  };
  const filterBySearch = () => {
    if (Array.isArray(data))
      return (debtFilter ? data?.filter((x: any) => x.debt > 0) : data).filter(
        (i: any) =>
          `${i.title}`
            .toLowerCase()
            .indexOf((searchValue || "").toLowerCase()) >= 0
      );

    return [];
  };

  useEffect(() => {
    getDebitorCalendar();
  }, [date]);

  useEffect(() => {
    (async () => {
      await init();
    })();
  }, [start, end]);

  useEffect(() => {
    if (item > 0) {
      const find = data?.find((x: any) => x.id === item);

      setItemValue(find);
    }
  }, [item]);

  return (
    <div className={styles.controllData}>
      <div className={styles.content}>
        <div className={styles.calendarDate}>
          <CalendarLine
            data={calendar}
            onChange={(e: string) => {
              setDate(e);
            }}
            defaultValue={date}
          />
        </div>
        <div className={styles.mediaBox}>
          <div className={styles.dataBoxChart}>
            {!chart && (
              <NoneChartData text={"Для побудови графіку виберіть покупця"} />
            )}
            {chart && chart.length === 0 && (
              <NoneChartData
                text={`У данного покупця відсутні данні за періо з ${start} до ${end}`}
              />
            )}

            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                width={500}
                height={300}
                data={chart}
                margin={{
                  top: 7,
                  right: 40,
                  left: 40,
                  bottom: 7,
                }}
              >
                <XAxis
                  dataKey="date"
                  fontSize={12}
                  color="#fff"
                  fill="#fff"
                  stroke="#fff"
                  strokeWidth={1}
                />

                <Tooltip
                  content={<CustomTooltip />}
                  isAnimationActive={false}
                  cursor={false}
                />
                {viewTotalSalesCounts && (
                  <Line
                    type="monotone"
                    dataKey="total_sales_counts"
                    stroke="#fff"
                    strokeWidth={3}
                    activeDot={true}
                    animationDuration={0}
                  />
                )}

                {viewTotalSalesValue && (
                  <Line
                    type="monotone"
                    dataKey="total_sales_value"
                    stroke="#fff"
                    strokeWidth={3}
                    activeDot={true}
                    animationDuration={0}
                  />
                )}
              </LineChart>
            </ResponsiveContainer>
            {chart && chart.length > 0 && (
              <Box p={"7px 14px"} style={{ borderRadius: 7 }} bg={"#fff"}>
                <Flex gap={14} justify={"center"}>
                  <Switch
                    label={"Кількість"}
                    defaultChecked={viewTotalSalesCounts}
                    onChange={(e: any) =>
                      setViewTotalSalesCounts(e.target.checked)
                    }
                  />
                  <Switch
                    label={"Сумма"}
                    defaultChecked={viewTotalSalesValue}
                    onChange={(e: any) =>
                      setViewTotalSalesValue(e.target.checked)
                    }
                  />
                </Flex>
              </Box>
            )}
          </div>
        </div>
        <div className={styles.mediaBox}>
          <div className={styles.dataCount}>
            <div className={styles.countTitle}>Сума оплати</div>
            <div className={styles.countValue}>
              {loadItems ? (
                <Loader color="blue" size="xs" m={"7px 0 0 0"} />
              ) : (
                formatCurrency(itemValue?.payment_summ || 0)
              )}
            </div>
          </div>
          <div className={styles.dataCount}>
            <div className={styles.countTitle}>Відвантажено</div>
            <div className={styles.countValue}>
              {loadItems ? (
                <Loader color="blue" size="xs" m={"7px 0 0 0"} />
              ) : (
                itemValue?.total_sales_counts
              )}
            </div>
          </div>
          <div className={styles.dataCount}>
            <div className={styles.countTitle}>Загальна сума</div>
            <div className={styles.countValue}>
              {loadItems ? (
                <Loader color="blue" size="xs" m={"7px 0 0 0"} />
              ) : (
                formatCurrency(itemValue?.total_sales_value || 0)
              )}
            </div>
          </div>
          <div className={styles.dataCount}>
            <div className={styles.countTitle}>Сума боргу</div>
            <div className={styles.countValue}>
              {loadItems ? (
                <Loader color="blue" size="xs" m={"7px 0 0 0"} />
              ) : (
                formatCurrency(itemValue?.debt || 0)
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.dataBox}>
        <Table
          tableName={"sales_accounting"}
          columns={columns}
          values={filterBySearch()}
          customManagement={
            <div className={styles.customManagement}>
              <Input
                type="search"
                placeholder="Пошук"
                onReset={(e: any) => setSearchValue(null)}
                value={searchValue || ""}
                onChange={(e: any) => {
                  setSearchValue(e.target.value);
                }}
                rightSection={
                  <CloseButton
                    aria-label="Очистити"
                    onClick={() => {
                      setSearchValue(null);
                    }}
                    style={{ display: searchValue ? undefined : "none" }}
                  />
                }
              />
              <MantineTooltip
                label="Відображати тільки покупців які мают борг"
                refProp="rootRef"
              >
                <Switch
                  label="Тільки з боргом"
                  onChange={(e: any) => setDebtFilter(e.target.checked)}
                />
              </MantineTooltip>

              <RangeCalendarData
                defaultValues={[start, end]}
                defaultActive={2}
                onChange={async (e: any) => {
                  if (e[0] && e[1]) {
                    await setStart(moment(e[0]).format("YYYY-MM-DD"));
                    await setEnd(moment(e[1]).format("YYYY-MM-DD"));
                  }
                }}
              />
            </div>
          }
          counts={counts}
          searchField="title"
          searchValue={searchValue}
          parseSearch={parseSearch}
          titleTable={"Покупці"}
          customStyles={{ margin: 0, padding: 0 }}
          activeValue={item}
          selectElement={{
            event: (key: any) => {
              getChartData(key);
              setItem(key);
            },
            key: "id",
            active: true,
          }}
        />
      </div>
    </div>
  );
};
