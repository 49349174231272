import { useEffect, useState } from "react";
import styles from "./styles.module.scss";

interface ProductsListInterface {
  name: string;
  list: any[];
  placeholder: string;
}

const Line = ({
  name,
  counts = 0,
  select,
}: {
  name: string;
  counts: number;
  select: any;
}) => {
  const [count, setCount] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  const handleCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCount = parseInt(e.target.value);
    setCount(newCount);
    updateTotal(newCount, price);
  };

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPrice = parseFloat(e.target.value);
    setPrice(newPrice);
    updateTotal(count, newPrice);
  };

  const updateTotal = (count: number, price: number) => {
    const newTotal = count * price;

    setTotal(newTotal);
  };

  useEffect(() => {
    if (select.counts > 0) {
      setCount(counts);
      setPrice(Number(select.price));
      updateTotal(counts || 0, Number(select.price));
    }
  }, [select]);

  return (
    <div className={styles.listLine}>
      <input
        className={styles.input}
        style={{ display: "none" }}
        value={select.id}
        readOnly={true}
        name={`${name}[ids]`}
      />
      <div className={styles.title}>{select.title}</div>
      <div className={styles.inputBox}>
        <span>Кількість</span>
        <input
          type={"number"}
          className={styles.subInput}
          name={`${name}[counts]`}
          placeholder="999"
          defaultValue={counts}
          onChange={handleCountChange}
          min={0}
          step="0.01"
        />
      </div>
      <div className={styles.inputBox}>
        <span>Вартість</span>
        <input
          type={"number"}
          className={styles.subInput}
          name={`${name}[prices]`}
          defaultValue={select.price}
          placeholder="99.99"
          step="0.01"
          onChange={handlePriceChange}
        />
        <div className={styles.valute}>грн</div>
      </div>
      <div className={styles.inputBox}>
        <span>Сумма</span>
        <input
          type={"number"}
          className={styles.subInput}
          name={`${name}[total]`}
          value={total}
          step="0.01"
          readOnly={true}
          placeholder="0"
        />
        <div className={styles.valute}>грн</div>
      </div>
    </div>
  );
};

const ProductsList = ({
  name,
  list = [],
  placeholder,
}: ProductsListInterface) => {
  return (
    <div className={styles.productsList}>
      <h3 className={styles.title}>{placeholder}</h3>

      <div className={styles.selectedList}>
        {list.map((select: any, index: number) => (
          <Line
            name={name}
            select={select}
            counts={select.counts}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductsList;
