import styles from "./styles.module.scss";
import { Avatar } from "../Avatar";
import { deleteCookie } from "cookies-next";
import { FetchAPI } from "@/hooks/fetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";
import { useStaff } from "@/context/staff";
import {
  faExpand,
  faClipboardList,
  faClock,
  faSignOutAlt,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const Header = () => {
  const { session } = useStaff();
  const router = useRouter();
  const [loadLogOut, setLoadLogOut] = useState<"out" | "list" | null>(null);

  const logout = async (red: string, type: "out" | "list") => {
    await setLoadLogOut(type);
    await FetchAPI("DELETE", "/api/pin");
    await deleteCookie("mafi_staff_token");
    router.push(red);
  };
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };
  const toBusinessList = () => {
    logout("/cabinet", "list");
  };

  return (
    <header className={styles.header}>
      <div className={styles.navs}>
        <button
          className={styles.btn}
          title="На весь екран"
          onClick={toggleFullScreen}
        >
          <FontAwesomeIcon icon={faExpand} />
        </button>
        <button
          className={styles.btn}
          title="До списку виробництв"
          onClick={toBusinessList}
        >
          {!loadLogOut || loadLogOut !== "list" ? (
            <FontAwesomeIcon icon={faClipboardList} />
          ) : (
            <FontAwesomeIcon icon={faSpinner} className="spin" />
          )}
        </button>
        <button
          className={styles.btn}
          title="Історія"
          onClick={() => router.push("/workspace/logs")}
        >
          {session?.unreaded > 0 ? (
            <div className={styles.unreaded}>{session?.unreaded}</div>
          ) : (
            ""
          )}
          <FontAwesomeIcon icon={faClock} />
        </button>
      </div>
      <div className={styles.session}>
        <div className={styles.user}>
          <div className={styles.userData}>
            <div className={styles.name}>{session?.title}</div>
            <div className={styles.role}>{session?.role?.title}</div>
          </div>
          <div className={styles.avatarData}>
            <Avatar data={session} size={36} />
          </div>
        </div>
        <button
          className={styles.btn}
          onClick={() => logout("/workspace/pin", "out")}
          title="Вихід"
        >
          {!loadLogOut || loadLogOut !== "out" ? (
            <FontAwesomeIcon icon={faSignOutAlt} />
          ) : (
            <FontAwesomeIcon icon={faSpinner} spin />
          )}
        </button>
      </div>
    </header>
  );
};

export default Header;
