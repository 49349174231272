import ForgotForm from '../forgot-form';
import styles from './styles.module.scss';

const ForgotWidget = () => {
    return <div className={styles.registerPage}>
        <div className={styles.data}>
            <ForgotForm />
        </div>
    </div>
};

export default ForgotWidget