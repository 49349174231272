import getConfig from "next/config";
import styles from "./styles.module.scss";
import { useState } from "react";

const {
  publicRuntimeConfig: { API_URL },
} = getConfig();

interface FileInterface {
  name: string;
  required?: boolean;
  accept: string;
  defaultValue?: null | {
    filename?: string | null;
    id?: null | number;
    mimetype?: null | string;
    size?: null | number | string;
    type?: null | string;
    tableName?: any;
  };
}

const File = ({ name, required, accept, defaultValue }: FileInterface) => {
  const [filename, setFileName] = useState<string | null>(
    defaultValue?.filename || null
  );
  const getFileLink = (file: any) => {
    return `${API_URL}/${file.tableName}/${file.filename}`;
  };

  const selectFile = (e: any) => {
    setFileName(e.target.files[0].name);
  };

  return (
    <div className={styles.labelFile}>
      {defaultValue?.filename && (
        <div className={styles.previewImage}>
          <img className={styles.tableImg} src={getFileLink(defaultValue)} />
        </div>
      )}
      <label className={styles.value}>
        <input
          type="file"
          name={name}
          required={required}
          accept={accept}
          onChange={selectFile}
        />
        <span className={styles.valueData}>{filename || "Фото не обрано"}</span>
        <span className={styles.addFile}>Вибрати файл</span>
      </label>
    </div>
  );
};
export default File;
