import moment from "moment";
import styles from "./styles.module.scss";
import { FetchAPI } from "@/hooks/fetch";
import { formatCurrency } from "@/hooks/format-currency";
import { Input } from "@mantine/core";
import { ModalButtons, RadioGroup, Select, Textarea } from "@/components";
import { Notification } from "@/hooks/notification";
import { useEffect, useState } from "react";
import { DateInput } from "@mantine/dates";

export const AccountingOfRawMaterials = ({
  edit,
  closeFunction,
  onUpdate,
}: {
  edit?: any;
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const sizes: any = {
    kg: { title: "кілограм" },
    st: { title: "штук" },
    l: { title: "літр" },
  };
  const [data, setData] = useState<any>(null);
  const [material, setMaterial] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(true);
  const [counts, setCounts] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [movement, setMovement] = useState<string>("");
  const [customPostponement, setCustomPostponement] = useState<boolean>(false);
  const [batches, setBatches] = useState<any[]>([]);

  const init = async () => {
    const { data: materials } = await FetchAPI("GET", `/api/helper/materials`);
    const { data: checks } = await FetchAPI("GET", `/api/helper/checks`);
    const { data: creditors } = await FetchAPI("GET", `/api/helper/creditors`);

    await setData({
      materials: materials.data,
      checks: checks.data,
      creditors: creditors.data,
    });

    await setLoad(false);
  };

  const changeCreditor = async (e: any) => {
    const find = data?.creditors?.find(
      (x: any) => x.id === Number(e.target.value)
    );

    if (find) {
      setCustomPostponement(find.postponement === 0);
    }
  };

  const submit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    try {
      await FetchAPI(
        edit ? "PUT" : "POST",
        `/api/accounting/accounting_of_raw_materials${
          edit ? `/${edit.id}` : ""
        }`,
        formData,
        null,
        {
          "Content-Type": "multipart/form-data",
        }
      );

      await Notification(
        edit ? "Рух інгридієнту змінено" : "Рух інгридієнту виконано",
        { autoClose: 3000 },
        "info"
      );

      await onUpdate();
      await closeFunction();
    } catch (e: any) {
      await Notification(
        "Упс... нажаль сталася помилка",
        { autoClose: 3000 },
        "error"
      );
    }
  };

  useEffect(() => {
    (async () => {
      await init();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (material && movement !== "coming") {
        const { data: materials } = await FetchAPI(
          "GET",
          `/api/controls/storage/products?id=${material.id}&type=materials`
        );

        setBatches(materials);
      }
    })();
  }, [material, movement]);

  useEffect(() => {
    if (edit && !load) {
      console.log(edit);

      setMovement(edit.movement);
      setMaterial(data?.materials?.find((x: any) => x.id === edit.material_id));
      setCustomPostponement(
        data?.creditors?.find((x: any) => x.id === edit.counteragent)
      );

      setCounts(edit.counts);
      setPrice(edit.price);
    }
  }, [edit, load]);

  return (
    <form className={styles.content} onSubmit={submit}>
      <Input.Wrapper description="Дата">
        <DateInput
          clearable
          defaultValue={edit?.date ? new Date(edit.date) : new Date()}
          required={true}
          placeholder="Дата"
          disabled={!!edit}
          name="date"
        />
      </Input.Wrapper>
      {!load && (
        <Input.Wrapper description="Рух">
          <Select
            defaultSelect={edit?.movement}
            hideLabel={true}
            placeholder="Рух"
            required={true}
            disabled={!!edit}
            list={[
              { id: "coming", title: "Прихід" },
              { id: "discharge", title: "Розхід" },
              { id: "brak", title: "Брак / Списання" },
              { id: "return", title: "Повернення" },

              { id: "adjustment_plus", title: "Коригування +" },
              { id: "adjustment_minus", title: "Коригування -" },
            ]}
            name={"movement"}
            onChange={(e: any) => setMovement(e.target.value)}
          />
        </Input.Wrapper>
      )}
      {!load && (
        <Input.Wrapper description="Сировина">
          <Select
            placeholder="Сировина"
            required={true}
            list={data.materials}
            hideLabel={true}
            name={"material_id"}
            defaultSelect={edit?.material_id}
            disabled={!!edit}
            onChange={(e: any) => {
              setMaterial(
                data?.materials?.find(
                  (x: any) => x.id === Number(e.target.value)
                )
              );
            }}
            error={{
              enabled: data?.materials?.length === 0,
              text: "Відсутня сировина",
            }}
          />
        </Input.Wrapper>
      )}
      {!load && movement === "coming" && (
        <Input.Wrapper description="Постачальник">
          <Select
            placeholder="Постачальник"
            required={true}
            hideLabel={true}
            list={data.creditors}
            name={"counteragent"}
            defaultSelect={edit?.counteragent}
            onChange={(e: any) => changeCreditor(e)}
            error={{
              enabled: data?.creditors?.length === 0,
              text: "Відсутня постачальники",
            }}
          />
        </Input.Wrapper>
      )}

      {customPostponement && (
        <Input.Wrapper description="Відтермінування">
          <Input
            type="number"
            min={0}
            max={356}
            name="postponement"
            required={true}
            placeholder="Відтермінування (к-сть днів)"
          />
        </Input.Wrapper>
      )}

      {movement !== "coming" && (
        <Input.Wrapper description="Партія">
          <Select
            placeholder="Партія"
            hideLabel={true}
            required={true}
            list={batches?.map((x: any) => {
              return {
                ...x,
                title: `№${moment(x.batch_of_products).format(
                  "YYYY-MM-DD"
                )} | К-сть: ${x.counts} | Варт. ${formatCurrency(
                  x.cost_price
                )}`,
              };
            })}
            name={"storage_id"}
            defaultSelect={edit?.storage_id}
            error={{
              enabled: batches?.length === 0,
              text: "Сировина відсутня на складі",
            }}
          />
        </Input.Wrapper>
      )}
      {!load && movement === "coming" && (
        <Input.Wrapper description="Рахунок">
          <Select
            placeholder="Рахунок"
            hideLabel={true}
            required={true}
            list={data.checks}
            name={"check_id"}
            defaultSelect={edit?.check_id}
            error={{
              enabled: data?.checks?.length === 0,
              text: "Відсутні рахунки",
            }}
          />
        </Input.Wrapper>
      )}

      {!load && movement === "coming" && (
        <RadioGroup
          list={[
            {
              title: "ПДВ",
              value: "pdv",
            },
            {
              title: "Без ПДВ",
              value: "no_pdv",
            },
          ]}
          name="pdv"
          defaultSelect={edit?.pdv}
        />
      )}

      {material && (
        <div className={styles.flex}>
          <Input.Wrapper
            description={`Кількість (${sizes[material?.size].title})`}
          >
            <Input
              type="number"
              step="0.01"
              name="counts"
              required={true}
              placeholder={`Кількість (${sizes[material?.size].title})`}
              defaultValue={edit?.counts}
              onChange={(e: any) => setCounts(Number(e.target.value))}
            />
          </Input.Wrapper>
          {movement === "coming" && (
            <>
              <Input.Wrapper
                description={`Вартість за ${sizes[material?.size].title}`}
              >
                <Input
                  type="number"
                  step="0.01"
                  name="price"
                  required={true}
                  placeholder={`Вартість за ${sizes[material?.size].title}`}
                  defaultValue={edit?.price}
                  onChange={(e: any) => setPrice(Number(e.target.value))}
                />
              </Input.Wrapper>
              <Input.Wrapper description="Загальна вартість">
                <Input
                  type="number"
                  step="0.01"
                  name="summ"
                  required={true}
                  readOnly={true}
                  placeholder="Загальна вартість"
                  value={counts * price}
                  defaultValue={edit?.summ}
                />
              </Input.Wrapper>
            </>
          )}
        </div>
      )}
      <Textarea
        placeholder="Коментар"
        name="comment"
        defauleValue={edit?.comment}
      />
      <ModalButtons closeFunction={closeFunction} />
    </form>
  );
};
