import styles from "./styles.module.scss";

interface TextareaInterface {
  name: string;
  required?: boolean;
  placeholder: string;
  defauleValue?: string;
  disabled?: boolean;
}

const Textarea = ({
  name,
  required,
  placeholder,
  defauleValue,
  disabled,
}: TextareaInterface) => {
  return (
    <div className={styles.box}>
      {placeholder && <span>{placeholder}</span>}
      <textarea
        className={`${styles.textarea} ${disabled ? styles.disabled : ""}`}
        name={name}
        required={required}
        placeholder={placeholder}
        defaultValue={defauleValue}
      />
    </div>
  );
};

export default Textarea;
