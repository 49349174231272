import Modals from "..";
import styles from "./styles.module.scss";
import { FetchAPI } from "@/hooks/fetch";
import { Modal } from "@/containers/modal";
import { Notification } from "@/hooks/notification";
import { useEffect, useState } from "react";
import {
  Input,
  ModalButtons,
  RadioGroup,
  Select,
  Textarea,
} from "@/components";
import { valutes } from "@/hooks/valutes";

export const AccountingOfFunds = ({
  edit,
  closeFunction,
  onUpdate,
}: {
  edit?: any;
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const [modal, toggleModal] = useState<boolean>(false);

  const [data, setData] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(true);
  const [type, setType] = useState<string>("income");
  const [counteragentType, setCounteragentType] = useState<string>("debitor");

  const [startCheck, setStartCheck] = useState<any>(null);
  const [endCheck, setEndCheck] = useState<any>(null);
  const [summ, setSumm] = useState<number>(0);
  const [course, setCourse] = useState<number>(0);

  const parseConvert = () => {
    if (startCheck.valute === "uah") {
      return (summ / course).toFixed(2) + valutes[endCheck.valute];
    } else {
      return (summ * course).toFixed(2) + valutes[endCheck.valute];
    }
  };

  const initItems = async () => {
    await setLoad(true);
    const { data: items } = await FetchAPI(
      "GET",
      `/api/items_of_income_and_expenses/${type}`
    );

    await setData((old: any) => {
      return {
        ...old,
        items: items,
      };
    });
    await setLoad(false);
  };

  const init = async () => {
    const { data: checks } = await FetchAPI("GET", `/api/helper/checks`);
    const { data: creditors } = await FetchAPI("GET", `/api/helper/creditors`);
    const { data: debitors } = await FetchAPI("GET", `/api/helper/debitors`);

    await setData((old: any) => {
      return {
        ...old,
        checks: checks.data,
        counteragents: [
          ...creditors.data.map((x: any) => {
            return { ...x, counteragent_type: "creditor" };
          }),
          ...debitors.data.map((x: any) => {
            return { ...x, counteragent_type: "debitor" };
          }),
        ],
      };
    });
  };

  useEffect(() => {
    (async () => {
      init();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await initItems();
    })();
  }, [type]);

  const submit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    await FetchAPI(
      "POST",
      `/api/accounting/accounting_of_funds`,
      formData,
      null,
      {
        "Content-Type": "multipart/form-data",
      }
    );

    await Notification("Рух коштіт виконано", { autoClose: 3000 }, "info");

    closeFunction();
    onUpdate();
  };

  return (
    <>
      <form className={styles.content} onSubmit={submit}>
        <Input type="date" name="date" required={true} placeholder="Дата" />
        <RadioGroup
          list={[
            {
              title: "Дохід",
              value: "income",
            },
            {
              title: "Витрати",
              value: "costs",
            },
            {
              title: "Переміщення",
              value: "moving",
            },
          ]}
          name="movement"
          onChange={setType}
        />
        {type !== "moving" && (
          <RadioGroup
            list={[
              {
                title: "Покупці",
                value: "debitor",
              },
              {
                title: "Постачальники",
                value: "creditor",
              },
            ]}
            name="counteragent_type"
            onChange={setCounteragentType}
          />
        )}

        {type !== "moving" && (
          <Select
            list={
              data?.counteragents?.filter(
                (x: any) => x.counteragent_type === counteragentType
              ) || []
            }
            name={"counteragent"}
            defaultSelect={edit?.counteragent}
            placeholder={
              counteragentType === "debitor" ? "Покупець" : "Постачальник"
            }
          />
        )}

        <Select
          list={data?.items || []}
          name={"expenses_id"}
          defaultSelect={edit?.expenses_id}
          placeholder={type === "costs" ? "Стаття витрат" : "Стаття доходів"}
          addButton={() => toggleModal(true)}
          error={{
            enabled: data?.items?.length === 0,
            text: "Відсутні категорії",
          }}
        />

        <Select
          list={data?.checks || []}
          name={"from_check_id"}
          defaultSelect={edit?.from_check_id}
          placeholder={type === "moving" ? "З рахунку" : "Рахунок"}
          onChange={(e: any) => {
            const find = data.checks.find(
              (x: any) => x.id === Number(e.target.value)
            );

            setStartCheck(find);
          }}
        />
        {startCheck && (
          <div className={styles.checkData}>
            <div className={styles.checkBalance}>
              Баланс: {startCheck?.balance} {valutes[startCheck?.valute]}
            </div>
          </div>
        )}

        {type === "moving" && (
          <>
            <Select
              list={data?.checks || []}
              name={"to_check_id"}
              defaultSelect={edit?.to_check_id}
              placeholder={"На Рахунок"}
              onChange={(e: any) => {
                const find = data.checks.find(
                  (x: any) => x.id === Number(e.target.value)
                );

                setEndCheck(find);
              }}
            />
            {endCheck && (
              <div className={styles.checkData}>
                <div className={styles.checkBalance}>
                  Баланс: {endCheck?.balance}
                  {valutes[endCheck?.valute]}
                </div>
              </div>
            )}
          </>
        )}

        {type !== "moving" && (
          <RadioGroup
            list={[
              {
                title: "ПДВ",
                value: "pdv",
              },
              {
                title: "Без ПДВ",
                value: "no_pdv",
              },
            ]}
            name="pdv"
            defaultSelect={edit?.pdv}
          />
        )}

        <Input
          type="number"
          name="summ"
          step="0.01"
          required={true}
          placeholder="Сума операції"
          onChange={(e: any) => setSumm(e.target.value)}
        />

        {type === "moving" &&
          startCheck &&
          endCheck &&
          startCheck?.valute !== endCheck?.valute && (
            <div className={styles.cantor}>
              <div className={styles.cantorTitle}>Конвертація валюти</div>
              <div className={styles.cantorContent}>
                <Input
                  type="number"
                  name="cource"
                  step="0.01"
                  showCaption={false}
                  required={true}
                  placeholder="Курс"
                  onChange={(e: any) => {
                    setCourse(Number(e.target.value));
                  }}
                />
                <div className={styles.value}>= {parseConvert()}</div>
              </div>
            </div>
          )}

        <Textarea
          placeholder="Коментар"
          name="comment"
          defauleValue={edit?.comment}
        />

        <ModalButtons closeFunction={closeFunction} />
      </form>
      <Modal
        title={"Створити статтю"}
        open={modal}
        closeFunction={() => {
          toggleModal(false);
        }}
      >
        <Modals.ItemsOfIncome
          edit={edit}
          closeFunction={() => {
            toggleModal(false);
          }}
          onUpdate={initItems}
        />
      </Modal>
    </>
  );
};
