import { useCallback, useEffect, useRef } from "react";
import Portal from "../hooks/portal";

export const Modal = ({
  title,
  open,
  children,
  closeFunction,
}: {
  title?: string;
  open: boolean;
  children: any;
  closeFunction?: () => void;
}) => {
  const modalRef = useRef<HTMLDivElement>(null),
    onClose = (): void => {
      closeFunction && closeFunction();
    },
    escFunction = useCallback((event: any) => {
      if (event.key === "Escape") onClose();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    const scrollContainers = document.querySelectorAll(".data");

    scrollContainers.forEach(container => {
      if (container.scrollHeight > container.clientHeight) {
        container.classList.add("scroll-padding");
      }
    });
    
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!open) return null;

  return (
    <Portal>
      <div className={`modal-data-back`} ref={modalRef} tabIndex={0}>
        <div className="content">
          <div className="title">
            <span>{title}</span>
            <button className={"close-modal-btn"} onClick={onClose}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8516 8.59375L16.7378 3.70752C17.3374 3.10791 17.3374 2.13574 16.7378 1.53564L15.6519 0.449707C15.0522 -0.149902 14.0801 -0.149902 13.48 0.449707L8.59375 5.33594L3.70752 0.449707C3.10791 -0.149902 2.13574 -0.149902 1.53564 0.449707L0.449707 1.53564C-0.149902 2.13525 -0.149902 3.10742 0.449707 3.70752L5.33594 8.59375L0.449707 13.48C-0.149902 14.0796 -0.149902 15.0518 0.449707 15.6519L1.53564 16.7378C2.13525 17.3374 3.10791 17.3374 3.70752 16.7378L8.59375 11.8516L13.48 16.7378C14.0796 17.3374 15.0522 17.3374 15.6519 16.7378L16.7378 15.6519C17.3374 15.0522 17.3374 14.0801 16.7378 13.48L11.8516 8.59375Z"
                  fill="#F99494"
                />
              </svg>
            </button>
          </div>
          <div className="data">{children}</div>
        </div>
      </div>
    </Portal>
  );
};
