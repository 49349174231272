import Modals from "..";
import styles from "./styles.module.scss";
import { FetchAPI } from "@/hooks/fetch";
import { File, ModalButtons, Select } from "@/components";
import { Modal } from "@/containers/modal";
import { Notification } from "@/hooks/notification";
import { useEffect, useState } from "react";
import {
  Input,
  Loader,
  LoadingOverlay,
  Skeleton,
  Textarea,
} from "@mantine/core";

export const Materials = ({
  edit,
  closeFunction,
  onUpdate,
}: {
  edit: any;
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const [data, setData] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(true);
  const [postLoad, setPostLoad] = useState<boolean>(false);

  const [counteragent, setCounteragent] = useState<boolean>(false);
  const [category, setCategorie] = useState<boolean>(false);
  const [selectedCounteragent, setSelectedCounteragent] =
    useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<boolean>(false);

  const init = async () => {
    const { data: categories } = await FetchAPI(
      "GET",
      `/api/helper/categories`
    );
    const { data: creditors } = await FetchAPI("GET", `/api/helper/creditors`);
    // const { data: debitors } = await FetchAPI("GET", `/api/helper/debitors`);

    await setData({
      categories: categories.data,
      counteragents: [...creditors.data], //...debitors.data
    });
    await setLoad(false);
  };

  const submit = async (e: any) => {
    e.preventDefault();
    if ((selectedCounteragent && selectedCategory) || edit) {
      await setPostLoad(true);
      const formData = new FormData(e.target);
      if (edit) {
        await FetchAPI(
          "PUT",
          `/api/helper/materials/${edit.id}`,
          formData,
          null,
          { "Content-Type": "multipart/form-data" }
        );
        await Notification(
          "Інгридієнт успішно змінено",
          { autoClose: 3000 },
          "info"
        );
      } else {
        await FetchAPI("POST", `/api/helper/materials`, formData, null, {
          "Content-Type": "multipart/form-data",
        });
        await Notification(
          "Інгридієнт успішно додано",
          { autoClose: 3000 },
          "info"
        );
      }
      await setPostLoad(false);
      closeFunction();
      onUpdate();
    }
  };

  useEffect(() => {
    (async () => {
      await init();
    })();
  }, []);

  return (
    <>
      <form
        className={styles.content}
        onSubmit={submit}
        method="post"
        encType="multipart/form-data"
      >
        {postLoad && (
          <>
            <LoadingOverlay
              visible={postLoad}
              zIndex={1000}
              overlayProps={{
                blur: 1,
                color: "rgb(255 242 242 / 50%)",
                bg: "rgb(255 242 242 / 50%)",
              }}
            >
              <Loader size={"sm"} />
            </LoadingOverlay>
          </>
        )}
        <Input.Wrapper description="Назва інгридієнту">
          <Input
            type="text"
            name="title"
            required={true}
            placeholder="Назва"
            defaultValue={edit?.title}
          />
        </Input.Wrapper>
        <Input.Wrapper description="Зображення">
          <File
            name="file"
            accept={"image/*"}
            defaultValue={{ ...edit?.image, tableName: "materials" }}
          />
        </Input.Wrapper>
        <Input.Wrapper description="Категорія">
          {!load ? (
            <Select
              placeholder={"Категорія"}
              hideLabel={true}
              required={true}
              list={data.categories}
              name={"categorie"}
              defaultSelect={edit?.categorie}
              addButton={() => setCategorie(true)}
              onChange={() => setSelectedCategory(true)}
              error={{
                enabled: data?.categories?.length === 0,
                text: "Відсутні категорії",
              }}
            />
          ) : (
            <Skeleton h={36} w={"100%"} />
          )}
        </Input.Wrapper>

        <Input.Wrapper description="Постачальник">
          {!load ? (
            <Select
              placeholder={"Постачальник"}
              required={true}
              hideLabel={true}
              list={data.counteragents}
              name={"counteragent"}
              defaultSelect={edit?.counteragent}
              onChange={() => setSelectedCounteragent(true)}
              addButton={() => setCounteragent(true)}
              error={{
                enabled: data?.counteragents?.length === 0,
                text: "Відсутні Постачальники",
              }}
            />
          ) : (
            <Skeleton h={36} w={"100%"} />
          )}
        </Input.Wrapper>

        <Input.Wrapper description="Коментар/Опис">
          <Textarea
            placeholder="Коментар"
            name="comment"
            mah={200}
            mt={5}
            defaultValue={edit?.comment}
          />
        </Input.Wrapper>
        <ModalButtons
          closeFunction={closeFunction}
          disabled={
            (data?.categories?.length === 0 ||
              !selectedCounteragent ||
              !selectedCategory) &&
            !edit
          }
        />
      </form>

      <Modal
        title={"Створити постачальника"}
        open={counteragent}
        closeFunction={() => {
          setCounteragent(false);
        }}
      >
        <Modals.Creditor
          edit={false}
          closeFunction={() => {
            setCounteragent(false);
          }}
          onUpdate={init}
        />
      </Modal>

      <Modal
        title={"Створити категорію"}
        open={category}
        closeFunction={() => {
          setCategorie(false);
        }}
      >
        <Modals.Category
          edit={false}
          closeFunction={() => {
            setCategorie(false);
          }}
          onUpdate={init}
        />
      </Modal>
    </>
  );
};
