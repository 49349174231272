import styles from "./styles.module.scss";
import { FetchAPI } from "@/hooks/fetch";
import { ModalButtons, RadioGroup } from "@/components";
import { Notification } from "@/hooks/notification";
import {
  Divider,
  Flex,
  Input,
  SegmentedControl,
  Text,
  Textarea,
} from "@mantine/core";

export const Check = ({
  edit,
  closeFunction,
  onUpdate,
}: {
  edit: any;
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const submit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    if (edit) {
      await FetchAPI("PUT", `/api/helper/checks/${edit.id}`, formData, null, {
        "Content-Type": "multipart/form-data",
      });
      await Notification(
        "Рахунок успішно змінено",
        { autoClose: 3000 },
        "info"
      );
    } else {
      await FetchAPI("POST", `/api/helper/checks`, formData, null, {
        "Content-Type": "multipart/form-data",
      });
      await Notification("Рахунок успішно додано", { autoClose: 3000 }, "info");
    }

    closeFunction();
    onUpdate();
  };

  return (
    <form className={styles.content} onSubmit={submit} method="post">
      <Input.Wrapper description="Введіть назву рахунку">
        <Input
          type="text"
          name="title"
          required={true}
          placeholder="Назва"
          defaultValue={edit?.title}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Податок (ПДВ)">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="pdv"
          mt={3}
          defaultValue={edit?.pdv}
          data={[
            { label: "з ПДВ", value: "pdv" },
            { label: "без ПДВ", value: "no_pdv" },
          ]}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Тип рахунку">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          mt={3}
          name="type"
          defaultValue={edit?.type}
          data={[
            { label: "ТОВ", value: "tov" },
            { label: "ФОП", value: "fop" },
            { label: "Фіз. Особа", value: "individual" },
          ]}
        />
      </Input.Wrapper>

      <Flex
        direction={"column"}
        gap={7}
        w={"100%"}
        bg={"#f1f3f5"}
        p={14}
        style={{ borderRadius: 7 }}
      >
        <Text size="18px">Реквізити</Text>
        <Divider my="md" m={0} />
        <Input.Wrapper description="Введіть назву продукту">
          <Input
            type="text"
            name="iban"
            required={false}
            placeholder="IBAN"
            defaultValue={edit?.iban}
          />
        </Input.Wrapper>
        <Input.Wrapper description="Введіть назву продукту">
          <Input
            type="text"
            name="mfo"
            required={false}
            placeholder="МФО"
            defaultValue={edit?.mfo}
          />
        </Input.Wrapper>
        <Input.Wrapper description="Введіть назву продукту">
          <Input
            type="text"
            name="edrpou"
            required={false}
            placeholder="ЄДРПОЮ / IПН"
            defaultValue={edit?.edrpou}
          />
        </Input.Wrapper>
        <Input.Wrapper description="Введіть назву продукту">
          <Input
            type="text"
            name="adress"
            required={false}
            placeholder="Місце реєстрацiї"
            defaultValue={edit?.adress}
          />
        </Input.Wrapper>
      </Flex>

      <Input.Wrapper description="Баланс рахунку (баланс на момент створення)">
        <Input
          type="number"
          name="balance"
          step="0.01"
          required={false}
          placeholder="Баланс"
          defaultValue={edit?.balance || 0}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Валюта">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="valute"
          mt={3}
          defaultValue={edit?.valute}
          data={[
            { label: "Гривня ₴", value: "uah" },
            { label: "Євро €", value: "eur" },
            { label: "Долар $", value: "usd" },
          ]}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Тип платежів">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="pay_type"
          mt={3}
          defaultValue={edit?.pay_type}
          data={[
            { label: "Готівка", value: "cash" },
            { label: "Безготівка", value: "bank" },
          ]}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Коментар/Опис">
        <Textarea
          placeholder="Коментар"
          name="comment"
          mah={200}
          mt={5}
          defaultValue={edit?.comment}
        />
      </Input.Wrapper>

      <ModalButtons closeFunction={closeFunction} />
    </form>
  );
};
