import * as XLSX from "xlsx";
import moment from "moment";
import { _CONST } from "@/components/contants";

export const downloadTableReport = async (
  docName: string,
  data: any[],
  columns: any[]
) => {
  const ws = XLSX.utils.json_to_sheet(
    data.map((x: any) => {
      let returnObject: any = {};
      for (const col of columns) {
        returnObject[col.title] = x[col.name];
      }

      return returnObject;
    })
  ); // Преобразование JSON в лист
  const wb = XLSX.utils.book_new(); // Создание новой книги
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1"); // Добавление листа в книгу

  // Настройка параметров для скачивания
  XLSX.writeFile(
    wb,
    `${docName}: ${moment(_CONST.current_date).format("MMMM YYYY")}.xlsx`
  );
};
