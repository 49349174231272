import getConfig from "next/config";
import LoadAuthForm from "../LoadAuthForm";
import styles from "./styles.module.scss";
import { Button, Input } from "@mantine/core";
import { Notification } from "@/hooks/notification";
import { setCookie } from "cookies-next";
import { useRouter } from "next/router";
import { useSession } from "@/context/session";
import { useState } from "react";

const {
  publicRuntimeConfig: { API_URL, MODE },
} = getConfig();

const LoginForm = () => {
  const { setSession } = useSession();
  const router = useRouter();
  const [error, setError] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const [loadForm, setLoadForm] = useState<boolean>(false);
  const [userName, setUsername] = useState<string | null>(null);

  const submit = async (e: any) => {
    e.preventDefault();

    try {
      setLoad(true);
      const result = await fetch(`${API_URL}/api/login`, {
        body: JSON.stringify({
          email: e.target.email.value,
          password: e.target.password.value,
        }),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      const data = await result.json();

      if (data?.errors?.length > 0) {
        await setError(true);
        await setLoad(false);
        if (data?.errors[0]?.text)
          await Notification(
            data?.errors[0]?.text,
            { autoClose: 3000 },
            "error"
          );
      } else {
        await setLoadForm(true);
        await setCookie("mafi-user-token", data.token);
        await setUsername(data?.username);

        await setSession(data);

        await Notification(
          `Доброго дня ${data?.username} :)`,
          { autoClose: 3000 },
          "success"
        );
        setTimeout(() => {
          router.push("/cabinet");
        }, 1500);
      }
    } catch (e) {
      console.log(e);
      await Notification(
        "Упс, якась помилка... Наші програмісти вже її виправляють",
        { autoClose: 3000 },
        "error"
      );
    } finally {
      setLoad(false);
    }
  };

  if (loadForm) return <LoadAuthForm userName={userName} />;

  return (
    <section className={styles.section}>
      <form
        onSubmit={submit}
        className={`${styles.loginForm} ${load ? styles.loading : ""}`}
      >
        <Input
          type="email"
          name="email"
          placeholder="Логін"
          defaultValue={
            MODE === "development" ? "star.systems.company@gmail.com" : ""
          }
        />
        <Input
          type="password"
          name="password"
          placeholder="Пароль"
          defaultValue={MODE === "development" ? "ogo8u566" : ""}
        />

        <Button type="submit" variant="gradient">
          Вхід
        </Button>
      </form>
      <div className={styles.buttons}>
        <Button
          type="button"
          variant="outline"
          onClick={() => router.push("/register")}
        >
          Створити акаунт
        </Button>
        <Button
          type="button"
          variant="outline"
          onClick={() => {
            router.push("/forgot");
          }}
        >
          Забули пароль ?
        </Button>
      </div>
    </section>
  );
};

export default LoginForm;
