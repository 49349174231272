import moment from "moment";
import styles from "./styles.module.scss";

interface InputInterface {
  type: string;
  name?: string;
  required: boolean;
  placeholder: string;
  defauleValue?: string;
  value?: number | string;
  readOnly?: boolean;
  showCaption?: boolean;
  step?: any;
  min?: number;
  max?: number;
  onChange?: (e: any) => void;
}

const Input = ({
  type,
  name,
  min,
  max,
  required,
  showCaption = true,
  placeholder,
  step,
  value,
  defauleValue,
  readOnly,
  onChange,
}: InputInterface) => {
  return (
    <div className={styles.box}>
      {placeholder && showCaption && <span>{placeholder}</span>}
      <input
        className={styles.input}
        type={type}
        min={min}
        max={max}
        name={name}
        required={required}
        placeholder={placeholder}
        readOnly={readOnly}
        onChange={onChange}
        {...(value ? { value: value || undefined } : {})}
        step={step}
        defaultValue={
          type === "date"
            ? defauleValue
              ? moment(defauleValue, "DD-MM-YYYY").format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD")
            : defauleValue
        }
      />
    </div>
  );
};

export default Input;
