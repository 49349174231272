import styles from "./styles.module.scss";

const LoadAuthForm = ({ userName }: { userName: string | null }) => {
  return (
    <section className={styles.section}>
      <div className={styles.line}>
        <span>Вхід в кабінет -</span>
        <b>{userName || "..."}</b>
      </div>
      <div className={styles.wait}>
        <span>Зачекайте декілька секунд</span>
        <img src="/images/spinner.svg" width={44} height={44} />
      </div>
    </section>
  );
};

export default LoadAuthForm;
