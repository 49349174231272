import moment from "moment";
import styles from "./styles.module.scss";
import { FetchAPI } from "@/hooks/fetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatCurrency } from "@/hooks/format-currency";
import { ModalButtons, MultiSelect, Select, Textarea } from "@/components";
import { Notification } from "@/hooks/notification";
import { parseSize } from "@/hooks/parse-size";
import { useEffect, useState } from "react";
import {
  Flex,
  Loader,
  Text,
  Input,
  TableData,
  SegmentedControl,
  Table,
  Alert,
  Tooltip,
} from "@mantine/core";
import {
  faBreadSlice,
  faBurger,
  faCarrot,
} from "@fortawesome/free-solid-svg-icons";
import { init } from "./init.request";
import LoadModal from "@/components/load-modal";

export const ProductionAccounting = ({
  edit,
  closeFunction,
  onUpdate,
}: {
  edit: any;
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const [data, setData] = useState<any>(null);

  const [load, setLoad] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const [product, setSelectProduct] = useState<any | null>(null);
  const [product_materials, setProductMaterials] = useState<any[] | null>(null);
  const [staffs, setStaffs] = useState<any[] | null>(null);
  const [count, setCount] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [prime_price, setPrimePrice] = useState<number>(0);

  const [priceLoad, setPriceLoad] = useState<boolean>(false);

  const [viewType, setViewType] = useState<"form" | "history">("form");
  const [historyData, setHistoryData] = useState<TableData | null>(null);

  const checkValide = () => {
    if (edit?.actual_quantity > 0) return false;

    const test = product_materials?.map((el: any) =>
      el.size !== "st"
        ? el.storage_count < (count * el.mat_count) / 1000
        : el.storage_count < count * el.mat_count
    );

    return !test?.find((x) => x === true) && price > 0 && !priceLoad;
  };
  const changeProduct = async (e: any) => {
    await setSelectProduct(null);
    await setProductMaterials(null);

    const { data: product } = await FetchAPI("GET", `/api/products/${e}`);

    if (product) {
      setProductMaterials([
        ...(product?.product_materials || []),
        ...(product?.product_not_formals || []),
        ...(product?.product_childs || []),
      ]);
      setSelectProduct(product);
    }
  };

  const submit = async (e: any) => {
    e.preventDefault();
    await setLoad(true);
    const formData: any = new FormData(e.target);
    formData.append("expected_price", price.toString());
    formData.append("expected_prime_price", prime_price.toString());
    formData.append("product_id", product.id.toString());
    formData.append(
      "manufacturing_cost",
      (product?.manufacturing_cost || 0).toString()
    );

    await FetchAPI(
      edit ? "PUT" : "POST",
      edit
        ? `/api/accounting/production_accounting/${edit.id}`
        : `/api/accounting/production_accounting`,
      formData,
      null,
      {
        "Content-Type": "multipart/form-data",
      }
    );

    if (edit)
      await Notification(
        `Облік виробництва успішно змінено`,
        { autoClose: 3000 },
        "success"
      );
    else
      await Notification(
        `Облік виробництва успішно створено`,
        { autoClose: 3000 },
        "success"
      );
    await setLoad(false);
    closeFunction();
    onUpdate();
  };
  const formatUnits = (value: number, unit: "kg" | "l" | "st") => {
    if (unit === "st") return `${value} шт`;
    if (unit === "kg") {
      const kg = Math.floor(value / 1000);
      const grams = value % 1000;
      if (kg > 0 && grams > 0) {
        return `${kg} кг ${grams} гр`;
      } else if (kg > 0) {
        return `${kg} кг`;
      } else {
        return `${grams} гр`;
      }
    } else if (unit === "l") {
      const liters = Math.floor(value / 1000);
      const milliliters = value % 1000;
      if (liters > 0 && milliliters > 0) {
        return `${liters} л ${milliliters} мл`;
      } else if (liters > 0) {
        return `${liters} л`;
      } else {
        return `${milliliters} мл`;
      }
    } else {
      return "Невідома одиниця вимірювання";
    }
  };
  const getSummPrice = async () => {
    await setPriceLoad(true);
    const values = product_materials?.map((el: any) => {
      return {
        id: el.id,
        material_id: el.material_id,
        type: el.type,
        dev_count: el.mat_count,
        count: count,
        size_od: el.size,
      };
    });

    const { data: calculation } = await FetchAPI(
      "POST",
      `/api/calculate-cost`,
      values,
      null,
      {
        "Content-Type": "application/json",
      }
    );

    await setPrice(calculation?.summ || 0);
    await setPrimePrice(calculation?.prime_summ || 0);

    await setPriceLoad(false);
  };

  useEffect(() => {
    (async () => {
      if (count > 0) await getSummPrice();
      else setPrice(0);
    })();
  }, [count]);

  useEffect(() => {
    (async () => {
      await init(setData, setStaffs, setFirstLoad);
    })();
  }, []);

  console.log(firstLoad);
  useEffect(() => {
    (async () => {
      if (edit && data && !load) {
        await changeProduct(edit.product_id);
        await setCount(edit?.expected_quantity);
      }
    })();
  }, [edit, data, load]);

  useEffect(() => {
    (async () => {
      if (edit) {
        const { data: history } = await FetchAPI(
          "GET",
          `/api/history/production_accounting/${edit.id}`
        );

        await setHistoryData({
          head: ["Дата", "Час", "Працівник"],
          body: history,
        });
      }
    })();
  }, [edit]);

  return firstLoad ? (
    <LoadModal length={5} />
  ) : (
    <>
      {!!edit && (
        <SegmentedControl
          fullWidth
          data={[
            { label: "Редагувати", value: "form" },
            { label: "Історія", value: "history" },
          ]}
          onChange={(e: any) => setViewType(e)}
        />
      )}

      {edit?.actual_quantity > 0 && (
        <Alert variant="light" w="100%" color="red">
          Неможливо редагувати завершене виробництво
        </Alert>
      )}

      <form
        className={styles.content}
        onSubmit={submit}
        method="post"
        encType="multipart/form-data"
        style={{ display: viewType === "form" ? "" : "none" }}
      >
        <Input.Wrapper
          label="Дата"
          description="Дата початку виробництва"
          error={edit ? `Неможливо редагувати дату` : ""}
        >
          <Input
            type="date"
            name="start_date"
            disabled={!!edit}
            required={true}
            defaultValue={moment(edit?.start_date).format("YYYY-MM-DD")}
            placeholder="Дата початку виробництва"
          />
        </Input.Wrapper>
        {!load && (
          <Select
            placeholder="Виріб (Товар або напівфабрикат)"
            required={true}
            list={data.products}
            defaultSelect={edit?.product_id}
            disabled={edit}
            name={"product_id"}
            onChange={(e: any) => changeProduct(e.target.value)}
            error={{
              enabled: data?.products?.length === 0,
              text: "Відсутні товари та напівфабрикати",
            }}
          />
        )}
        {product && product_materials && (
          <>
            <div className={styles.techMap}>
              <h3 className={styles.techTitle}>Тех. карта</h3>
              <ul className={styles.materialsList}>
                {product_materials?.map((el: any, key: number) => (
                  <li className={styles.matLine} key={key}>
                    <div className={styles.name}>
                      <div className={styles.nameValue}>
                        {el.title}
                        {el.type === "materials" && (
                          <Flex className={styles.typed} bg={"red"}>
                            <FontAwesomeIcon
                              width={14}
                              height={14}
                              icon={faCarrot}
                            />
                            Інгридієнт
                          </Flex>
                        )}
                        {el.type === "not_formal" && (
                          <Flex className={styles.typed} bg={"blue"}>
                            <FontAwesomeIcon
                              width={14}
                              height={14}
                              icon={faBreadSlice}
                            />
                            Напівфабрикат
                          </Flex>
                        )}
                        {el.type === "product" && (
                          <Flex className={styles.typed} bg={"green"}>
                            <FontAwesomeIcon
                              width={14}
                              height={14}
                              icon={faBurger}
                            />
                            Продукт
                          </Flex>
                        )}
                      </div>
                      <div className={styles.storageCount}>
                        На складі:{el.storage_count}{" "}
                        {parseSize(el.size, "full")}
                      </div>
                      {el.type === "materials" ? (
                        <div className={styles.storageCount}>
                          {el.price ? (
                            `Вартість за:${formatCurrency(
                              el.price || 0
                            )}/${parseSize(el.size, "full")}`
                          ) : (
                            <Text c={"red"} size="14px">
                              Відсутній на складі
                            </Text>
                          )}
                        </div>
                      ) : (
                        <div className={styles.storageCount}>
                          {el.prime_price ? (
                            `Вартість за:${formatCurrency(
                              el.prime_price || 0
                            )}/${parseSize(el.size, "full")}`
                          ) : (
                            <Text c={"red"} size="14px">
                              Відсутній на складі
                            </Text>
                          )}
                        </div>
                      )}
                    </div>
                    <div
                      className={`${styles.val} ${
                        (
                          el.size !== "st"
                            ? el.storage_count < (count * el.mat_count) / 1000
                            : el.storage_count < count * el.mat_count
                        )
                          ? styles.error
                          : ""
                      }`}
                    >
                      {formatUnits(count * el.mat_count, el.size)}
                    </div>
                  </li>
                ))}
              </ul>

              <Flex
                className={styles.inputs}
                justify={"space-between"}
                gap={"24px"}
                align={"center"}
              >
                <Input
                  type="number"
                  name="expected_quantity"
                  required={true}
                  placeholder="Запланована кількість"
                  disabled={edit?.actual_quantity > 0}
                  defaultValue={edit?.expected_quantity || ""}
                  min={1}
                  step="0.01"
                  onChange={(e: any) => setCount(Number(e.target.value))}
                />
                <Flex
                  direction={"column"}
                  justify={"flex-end"}
                  className={styles.summ}
                >
                  {priceLoad ? (
                    <Loader size={"sm"} />
                  ) : (
                    <Flex align={"center"} gap={5}>
                      Вартість інгридієнтів:{" "}
                      <Tooltip
                        multiline
                        label={
                          "Точна вартість за розрахунком: " +
                          prime_price +
                          " грн"
                        }
                        color="blue"
                        position="bottom-end"
                        withArrow
                      >
                        <Text fw={"bold"}>
                          {formatCurrency(prime_price || 0)}
                        </Text>
                      </Tooltip>
                    </Flex>
                  )}
                  {priceLoad && product?.manufacturing_cost > 0 ? (
                    <Loader size={"sm"} />
                  ) : (
                    <Flex align={"center"} gap={5}>
                      Вартість виробництва:{" "}
                      <Text fw={"bold"}>
                        {formatCurrency(
                          count * (product?.manufacturing_cost || 0)
                        )}
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </div>
          </>
        )}
        {staffs && !load && (
          <MultiSelect
            placeholder={"Працівник що виготовив"}
            name={"staffs[]"}
            list={staffs}
            isDelete={true}
            disabled={edit?.actual_quantity > 0}
            defaultKey={"staff_id"}
            defaultValues={edit?.production_accounting_staffs}
            error={{
              enabled: staffs?.length === 0,
              text: "Відсутні працівники",
            }}
          />
        )}
        <Textarea
          placeholder="Коментар"
          disabled={edit?.actual_quantity > 0}
          name="comment"
        />
        <ModalButtons
          closeFunction={closeFunction}
          disabled={!checkValide()}
          load={load}
        />{" "}
      </form>

      <div
        className={styles.history}
        style={{ display: viewType === "history" ? "" : "none" }}
      >
        {historyData && historyData.body && historyData.body.length > 0 ? (
          <Table data={historyData} />
        ) : (
          <Alert variant="light" w="100%" color="blue" title="Історія">
            Історія редагування відсутня
          </Alert>
        )}
      </div>
    </>
  );
};
