import { FetchAPI } from "@/hooks/fetch";

export const init = async (
  setData: (d: any) => void,
  setLoad: (v: boolean) => void
) => {
  const { data: products } = await FetchAPI(
    "GET",
    `/api/accounting/production_accounting`
  );
  const { data: products_storages } = await FetchAPI(
    "GET",
    `/api/accounting/not_formal_storages`
  );

  await setData({
    materials: products.data.map((el: any) => {
      return { ...el, title: el.product_name };
    }),
    products_storages: products_storages,
  });

  setTimeout(() => {
    setLoad(false);
  }, 1000);
};
