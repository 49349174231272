import styles from "./styles.module.scss";
import { FetchAPI } from "@/hooks/fetch";
import { ModalButtons, RadioGroup } from "@/components";
import { useEffect, useState } from "react";
import { Input, SegmentedControl, Textarea } from "@mantine/core";

const list = [
  {
    label: "Редагування",
    value: "edit",
  },
  {
    label: "Перегляд",
    value: "view",
  },
  {
    label: "Заборонено",
    value: "disabled",
  },
];

const list2 = [
  {
    label: "Перегляд",
    value: "view",
  },
  {
    label: "Заборонено",
    value: "disabled",
  },
];
export const Role = ({
  edit,
  closeFunction,
  onUpdate,
}: {
  edit: any;
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const [data, setData] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(true);

  const init = async () => {
    const { data: staff_roles } = await FetchAPI(
      "GET",
      `/api/helper/staff_roles`
    );

    await setData({
      staff_roles: staff_roles.data,
    });
    await setLoad(false);
  };

  const submit = async (e: any) => {
    e.preventDefault();
    const formData: any = new FormData(e.target);

    const jsonObject: any = {};
    for (const [key, value] of formData.entries()) {
      jsonObject[key] = value;
    }

    if (edit)
      await FetchAPI("PUT", `/api/roles/${edit.id}`, jsonObject, null, {
        "Content-Type": "application/json",
      });
    else
      await FetchAPI("POST", `/api/roles`, jsonObject, null, {
        "Content-Type": "application/json",
      });

    closeFunction();
    onUpdate();
  };

  useEffect(() => {
    (async () => {
      await init();
    })();
  }, []);

  return (
    <form className={styles.content} onSubmit={submit}>
      <Input.Wrapper description="Введіть назву ролі">
        <Input
          type="text"
          name="title"
          required={true}
          placeholder="Назва"
          defaultValue={edit?.title}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Коментар/Опис">
        <Textarea
          placeholder="Коментар"
          name="description"
          mah={200}
          mt={5}
          defaultValue={edit?.description}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Інгридієнти">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="materials"
          mt={3}
          defaultValue={edit?.materials}
          data={list}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Категорії">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="categories"
          mt={3}
          defaultValue={edit?.categories}
          data={list}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Напівфабрикати">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="not_formal"
          mt={3}
          defaultValue={edit?.not_formal}
          data={list}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Продукти">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="product"
          mt={3}
          defaultValue={edit?.product}
          data={list}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Рахунки">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="check_data"
          mt={3}
          defaultValue={edit?.check_data}
          data={list}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Постачальники">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="creditors"
          mt={3}
          defaultValue={edit?.creditors}
          data={list}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Покупці">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="debitors"
          mt={3}
          defaultValue={edit?.debitors}
          data={list}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Співробітники">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="employee"
          mt={3}
          defaultValue={edit?.employee}
          data={list}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Статі доходів та витрат">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="items_of_income_and_expenses"
          mt={3}
          defaultValue={edit?.items_of_income_and_expenses}
          data={list}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Облік виробництва">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="production_accounting"
          mt={3}
          defaultValue={edit?.production_accounting}
          data={list}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Облік сировини">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="accounting_of_raw_materials"
          mt={3}
          defaultValue={edit?.accounting_of_raw_materials}
          data={list}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Облік продуктів">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="product_accounting"
          mt={3}
          defaultValue={edit?.product_accounting}
          data={list}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Облік продаж">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="sales_accounting"
          mt={3}
          defaultValue={edit?.sales_accounting}
          data={list}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Облік коштів">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="accounting_of_funds"
          mt={3}
          defaultValue={edit?.accounting_of_funds}
          data={list}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Рух сировини">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="raw"
          mt={3}
          defaultValue={edit?.raw}
          data={list2}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Рух напівфабрикатів">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="nf"
          mt={3}
          defaultValue={edit?.nf}
          data={list2}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Рух продукції">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="products"
          mt={3}
          defaultValue={edit?.products}
          data={list2}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Бронювання">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="booking"
          mt={3}
          defaultValue={edit?.booking}
          data={list}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Покупці">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="debitors_control"
          mt={3}
          defaultValue={edit?.debitors_control}
          data={list}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Постачальники">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="creditors_control"
          mt={3}
          defaultValue={edit?.creditors_control}
          data={list}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Контроль заробітньої плати">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="wage_control"
          mt={3}
          defaultValue={edit?.wage_control}
          data={list}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Склад">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="storage"
          mt={3}
          defaultValue={edit?.storage}
          data={list}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Ролі">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="roles"
          mt={3}
          defaultValue={edit?.roles}
          data={list}
        />
      </Input.Wrapper>

      <ModalButtons closeFunction={closeFunction} />
    </form>
  );
};
