import axios, { AxiosRequestConfig, Method } from "axios";
import getConfig from "next/config";
import { getCookie } from "cookies-next";
const {
  publicRuntimeConfig: { API_URL },
} = getConfig();

export const FetchAPI = async (
  type: Method,
  url: string,
  formData?: any,
  config?: any,
  customHeaders?: any,
  fetchType?: "user" | "staff"
) => {
  const institution = getCookie("mafi_institution_token");
  let jwt = getCookie(
    fetchType === "user" ? "mafi-user-token" : "mafi_staff_token"
  );

  const headers = {
    Authorization: `Bearer ${jwt}`,
    Institution: institution,
    ...customHeaders,
  };

  const fetchObj: AxiosRequestConfig = {
    method: type,
    url: API_URL + url,
    headers: headers,
    data: formData,
    ...config,
  };

  return await axios(fetchObj);
};

export const ServerSideAPI = async (
  type: Method,
  url: string,
  context: any | null,
  formData?: any,
  fetchType: "user" | "staff" = "staff"
) => {
  const institution = getCookie("mafi_institution_token", {
    req: context.req,
    res: context.res,
  });

  let jwt = null;

  if (fetchType === "staff") {
    jwt = getCookie("mafi_staff_token", {
      req: context.req,
      res: context.res,
    });
  } else {
    jwt = getCookie("mafi-user-token", {
      req: context.req,
      res: context.res,
    });
  }

  const headers = {
    Authorization: `Bearer ${jwt}`,
    Institution: institution,
  };

  const fetchObj: AxiosRequestConfig = {
    method: type,
    url: API_URL + url,
    headers: headers,
    data: formData,
  };

  return await axios(fetchObj);
};
