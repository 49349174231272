import styles from "./styles.module.scss";
import { FetchAPI } from "@/hooks/fetch";
import { ModalButtons, Select, Textarea } from "@/components";
import { useEffect, useState } from "react";
import moment from "moment";
import { Input } from "@mantine/core";
import { Notification } from "@/hooks/notification";

export const ProductAccount = ({
  edit,
  closeFunction,
  onUpdate,
}: {
  edit?: any;
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const [data, setData] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(true);
  const [movement, setMovement] = useState<string>("coming");
  const [partions, setPartions] = useState<any[]>([]);

  const init = async () => {
    const { data: products } = await FetchAPI(
      "GET",
      `/api/accounting/production_accounting`
    );
    const { data: products_storages } = await FetchAPI(
      "GET",
      `/api/helper/product`
    );

    await setData({
      products: products.data.map((el: any) => {
        return { ...el, title: el.product_name };
      }),
      products_storages: products_storages?.data || [],
    });

    await setLoad(false);
  };

  const submit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    try {
      await FetchAPI(
        edit ? "PUT" : "POST",
        `/api/accounting/product_accounting${edit ? `/${edit.id}` : ""}`,
        formData,
        null,
        {
          "Content-Type": "multipart/form-data",
        }
      );

      await Notification(
        edit
          ? "Облік товарів успішно змінено"
          : `Облік товарів успішно створено`,
        { autoClose: 3000 },
        "success"
      );

      await onUpdate();
      await closeFunction();
    } catch (e: any) {
      await Notification(
        "Упс... нажаль сталася помилка",
        { autoClose: 3000 },
        "error"
      );
    }
  };

  useEffect(() => {
    (async () => {
      await init();
    })();
  }, []);

  console.log(partions);
  return (
    <form
      className={styles.content}
      onSubmit={submit}
      method="post"
      encType="multipart/form-data"
    >
      <Input.Wrapper description="Дата">
        <Input
          type="date"
          name="date"
          required={true}
          placeholder="Дата"
          defaultValue={moment(edit?.start_date).format("YYYY-MM-DD")}
        />
      </Input.Wrapper>
      <Select
        defaultSelect={edit?.movement || movement}
        placeholder="Рух"
        required={true}
        disabled={!!edit?.movement}
        list={[
          { id: "coming", title: "Прихід" },
          { id: "adjustment_plus", title: "Коригування +" },
          { id: "adjustment_minus", title: "Коригування -" },
          { id: "brak", title: "Брак" },
          { id: "return", title: "Повернення" },
          { id: "degustation", title: "Дегустація" },
          { id: "no_pay", title: "Списання без оплати" },
        ]}
        onChange={(e) => {
          setMovement(e.target.value);
        }}
        name={"movement"}
      />

      {!load && (
        <>
          {movement !== "coming" ? (
            <>
              <Select
                required={true}
                list={data.products_storages}
                placeholder="Продукт"
                name={"production_id"}
                customValueKey={"id"}
                onChange={async (e: any) => {
                  const _val = e.target.value;
                  const { data: resd } = await FetchAPI(
                    "GET",
                    `/api/partions/${_val}/product`
                  );

                  await setPartions(resd);
                }}
                error={{
                  enabled: data?.products?.length === 0,
                  text: "Відсутні продукти",
                }}
              />

              <Select
                required={true}
                list={
                  partions?.map((x: any) => {
                    return {
                      ...x,
                      title: `(№ партії: ${
                        x.batch_of_products?.split("T")[0]
                      }) - ${x.title}`,
                    };
                  }) || []
                }
                placeholder="Партія"
                name={"part_id"}
                defaultSelect={partions[0]?.id}
                error={{
                  enabled: partions?.length === 0,
                  text: "Товари відсутні на складіі",
                }}
              />
            </>
          ) : (
            <>
              <Select
                required={true}
                disabled={!!edit}
                customValueKey={"id"}
                list={(edit
                  ? [
                      {
                        ...edit,
                        title: edit.product_title,
                        expected_quantity: edit.counts,
                        start_date: edit.date,
                        staff_name: edit.staff_name,
                        end_date: null,
                      },
                    ]
                  : data.products
                )
                  .filter((x: any) => x.end_date === null)
                  .map((x: any) => {
                    return {
                      ...x,
                      title: `${x.title} | Зап. к-сть. ${
                        x.expected_quantity
                      } | №${moment(x.start_date).format("YYYY-MM-DD")} | ${
                        x.staff_name
                      }`,
                    };
                  })}
                placeholder="Продукт"
                name={"production_id"}
                defaultSelect={edit?.product_id}
                error={{
                  enabled: data?.categories?.length === 0,
                  text: "Відсутні продукти",
                }}
              />
            </>
          )}
        </>
      )}
      <Input.Wrapper description="Кількість">
        <Input
          type="number"
          step="0.01"
          name="counts"
          disabled={
            movement !== "coming" && (!partions || partions?.length === 0)
          }
          required={true}
          placeholder="Кількість"
          defaultValue={edit?.counts}
        />
      </Input.Wrapper>
      <Textarea
        placeholder="Коментар"
        name="comment"
        defauleValue={edit?.comment}
      />
      <ModalButtons
        closeFunction={closeFunction}
        disabled={data?.categories?.length < 1}
      />
    </form>
  );
};
