import RegisterForm from "../register-form";
import styles from "./styles.module.scss";

const MainWidget = () => {
  return (
    <div className={styles.registerPage}>
      <div className={styles.data}>
        <RegisterForm />
      </div>
    </div>
  );
};

export default MainWidget;
