import getConfig from "next/config";
import styles from "./styles.module.scss";
import { Box, Button, Flex, LoadingOverlay } from "@mantine/core";
import { FetchAPI } from "@/hooks/fetch";
import { useState } from "react";
import { DateInput } from "@mantine/dates";
import moment from "moment";
import { downloadTableReport } from "@/hooks/table.report";

const {
  publicRuntimeConfig: { API_URL },
} = getConfig();

export const AccountReport = ({
  data,
  closeFunction,
}: {
  data?: any;
  closeFunction: () => void;
}) => {
  const [load, setLoad] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState(moment().toDate());

  const fd = (date: any) => moment(date).format("YYYY-MM-DD");

  const submit = async () => {
    await setLoad(true);

    try {
      const { data: response } = await FetchAPI(
        "GET",
        `/api/account/report/${data.id}/${fd(startDate)}/${fd(endDate)}`
      );

      downloadTableReport(
        `Виписка за рахунком з ${fd(startDate)} до ${fd(endDate)}`,
        response?.records,
        response?.fields
      );

      closeFunction();
    } catch (e: any) {
    } finally {
      await setLoad(false);
    }
  };
  return (
    <Box className={styles.content} pos="relative">
      <LoadingOverlay visible={load} zIndex={1000} />
      <Flex direction={"column"} gap={7}>
        <Flex gap={14} w={"100%"}>
          <DateInput
            w={"100%"}
            label="Початок"
            withAsterisk
            description="Дата початку"
            placeholder="Початок"
            value={startDate}
            onChange={(date: any) => setStartDate(date)}
          />

          <DateInput
            w={"100%"}
            label="Кінець"
            withAsterisk
            description="Дата кінця"
            placeholder="Кінець"
            value={endDate}
            onChange={(date: any) => setEndDate(date)}
          />
        </Flex>
      </Flex>
      <Flex gap={14} w={"100%"} p={"7px 0"}>
        <Button
          variant="outline"
          type="button"
          w={"50%"}
          onClick={closeFunction}
        >
          Відміна
        </Button>
        <Button
          variant="gradient"
          type="button"
          onClick={submit}
          w={"50%"}
          loading={load}
          disabled={!startDate || !endDate}
        >
          Отримати виписку
        </Button>
      </Flex>
    </Box>
  );
};
