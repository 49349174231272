import { toast } from "react-toastify";

export const Notification = (
  text: string,
  properties: any,
  type: "info" | "success" | "warn" | "error" | "loading"
) => {
  let sound = null;

  sound = new Audio("/sound/notify.mp3");
  sound.play();

  const canVibrate: any = window.navigator.vibrate;
  if (canVibrate) window.navigator.vibrate(300);

  switch (type) {
    case "info":
      return toast.info(text, { ...properties });
    case "success":
      return toast.success(text, { ...properties });
    case "warn":
      return toast.warn(text, { ...properties });
    case "error":
      return toast.error(text, { ...properties });
    case "loading":
      return toast.loading(text, { ...properties });

    default:
      return toast(text, { ...properties });
  }
};
