import Modals from "..";
import styles from "./styles.module.scss";
import { FetchAPI } from "@/hooks/fetch";
import {
  Button,
  Flex,
  Input,
  SegmentedControl,
  Skeleton,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { Modal } from "@/containers/modal";
import { Notification } from "@/hooks/notification";
import { useEffect, useState } from "react";
import { File, ModalButtons, MultiSelect } from "@/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

export const Products = ({
  edit,
  closeFunction,
  onUpdate,
}: {
  edit: any;
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const [data, setData] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(true);
  const [material, setMaterial] = useState<boolean>(false);

  const init = async () => {
    const { data: categories } = await FetchAPI(
      "GET",
      `/api/helper/categories`
    );
    const { data: not_formal } = await FetchAPI(
      "GET",
      `/api/helper/not_formal`
    );
    const { data: products } = await FetchAPI("GET", `/api/helper/product`);

    const { data: packages } = await FetchAPI("GET", `/api/helper/package`);

    await setData({
      categories: categories.data,
      not_formals: not_formal.data,
      packages: packages.data,
      products: products.data,
    });
    await setLoad(false);
  };

  const submit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    if (edit) {
      await FetchAPI("PUT", `/api/helper/product/${edit.id}`, formData, null, {
        "Content-Type": "multipart/form-data",
      });
      await Notification(
        "Продукт успішно змінено",
        { autoClose: 3000 },
        "info"
      );
    } else {
      await FetchAPI("POST", `/api/helper/product`, formData, null, {
        "Content-Type": "multipart/form-data",
      });
      await Notification("Продукт успішно додано", { autoClose: 3000 }, "info");
    }

    closeFunction();
    onUpdate();
  };

  useEffect(() => {
    (async () => {
      await init();
    })();
  }, []);

  return (
    <>
      <form className={styles.content} onSubmit={submit}>
        <Input.Wrapper description="Введіть назву продукту">
          <Input
            type="text"
            name="title"
            required={true}
            placeholder="Назва"
            defaultValue={edit?.title}
          />
        </Input.Wrapper>
        <File
          name="file"
          accept={"image/*"}
          defaultValue={{ ...edit?.image, tableName: "product" }}
        />

        <Input.Wrapper description="Одиниця виміру">
          <SegmentedControl
            fullWidth
            radius="xl"
            color="#228be6"
            name="size"
            mt={3}
            defaultValue={edit?.size}
            data={[
              { label: "КГ", value: "kg" },
              { label: "ШТ", value: "st" },
              { label: "Л", value: "l" },
            ]}
          />
        </Input.Wrapper>

        <Input.Wrapper description="Сировина">
          {!load ? (
            <MultiSelect
              placeholder={"Сировина"}
              name={"categories[ids]"}
              hideLabel={true}
              list={data.categories}
              addButton={() => setMaterial(true)}
              isDelete={true}
              subInputs={[
                {
                  type: "number",
                  name: `categories[size]`,
                  readOnly: false,
                  defaltField: "size",
                  subSizeCustom: "Кількість",
                  unit: "size",
                  isDelete: true,
                  step: 0.001,
                },
              ]}
              error={{
                enabled: data?.categories?.length === 0,
                text: "Відсутні категорії",
              }}
              defaultKey="categorie_id"
              defaultValues={edit?.product_materials_category}
            />
          ) : (
            <Skeleton
              style={{ flex: "none" }}
              h={40}
              mt={5}
              w={"100%"}
              radius="sm"
            />
          )}
        </Input.Wrapper>
        <Input.Wrapper description="Напівфабрикати">
          {!load ? (
            <MultiSelect
              placeholder={"Напівфабрикати"}
              name={"not_formals[ids]"}
              list={data.not_formals}
              hideLabel={true}
              subInputs={[
                {
                  type: "number",
                  name: `not_formals[size]`,
                  readOnly: false,
                  defaltField: "size",
                  subSizeCustom: "Кількість",
                  unit: "size",
                  isDelete: true,
                  step: 0.001,
                },
              ]}
              isDelete={true}
              defaultKey="not_formal_id"
              defaultValues={edit?.product_not_formals}
            />
          ) : (
            <Skeleton
              style={{ flex: "none" }}
              h={40}
              mt={5}
              w={"100%"}
              radius="sm"
            />
          )}
        </Input.Wrapper>
        <Input.Wrapper description="Продукти">
          {!load ? (
            <MultiSelect
              placeholder={"Продукти"}
              name={"products[ids]"}
              list={data.products}
              hideLabel={true}
              subInputs={[
                {
                  type: "number",
                  name: `products[size]`,
                  readOnly: false,
                  defaltField: "size",
                  unit: "size",
                  subSizeCustom: "Кількість",
                  isDelete: true,
                  step: 1,
                },
              ]}
              isDelete={true}
              defaultKey="child_id"
              defaultValues={edit?.product_childs}
            />
          ) : (
            <Skeleton
              style={{ flex: "none" }}
              h={40}
              mt={5}
              w={"100%"}
              radius="sm"
            />
          )}
        </Input.Wrapper>
        <Flex gap={14} w={"100%"}>
          <Input.Wrapper description="Введіть роздрібну ціну" w={"100%"}>
            <Input
              type="number"
              step="0.01"
              name="small_price"
              required={true}
              placeholder="Ціна роздріб"
              defaultValue={edit?.small_price}
              onFocus={(e: any) => e.target.select()}
            />
          </Input.Wrapper>
          <Input.Wrapper description="Введіть ціну на дрібний опт" w={"100%"}>
            <Input
              type="number"
              step="0.01"
              name="wholesale"
              required={true}
              placeholder="Ціна дрібний ОПТ"
              defaultValue={edit?.wholesale}
              onFocus={(e: any) => e.target.select()}
            />
          </Input.Wrapper>

          <Input.Wrapper description="Введіть оптову ціну" w={"100%"}>
            <Input
              type="number"
              step="0.01"
              name="opt_price"
              required={true}
              placeholder="Оптова ціна"
              defaultValue={edit?.opt_price}
              onFocus={(e: any) => e.target.select()}
            />
          </Input.Wrapper>
        </Flex>
        <Flex align={"flex-end"} gap={7}>
          <Input.Wrapper
            w={"100%"}
            description="Введіть вартість за одиницю при виробництві"
          >
            <Input
              type="number"
              step="0.01"
              name="manufacturing_cost"
              required={false}
              placeholder="Вартість за одиницю виробництва"
              defaultValue={edit?.manufacturing_cost}
              onFocus={(e: any) => e.target.select()}
            />
          </Input.Wrapper>
          <Tooltip label="Вартість за одиницю виробництва буде нарахована працівникам які виготовили продукт">
            <Button variant="light" type="button">
              <FontAwesomeIcon width={14} height={14} icon={faQuestion} />
            </Button>
          </Tooltip>
        </Flex>

        <Input.Wrapper description="Коментар/Опис">
          <Textarea
            placeholder="Коментар"
            name="comment"
            mah={200}
            mt={5}
            defaultValue={edit?.comment}
          />
        </Input.Wrapper>

        <ModalButtons
          closeFunction={closeFunction}
          disabled={data?.products?.length < 0}
        />
      </form>

      <Modal
        title={"Створити ігридієнт"}
        open={material}
        closeFunction={() => {
          setMaterial(false);
        }}
      >
        <Modals.Materials
          edit={false}
          closeFunction={() => {
            setMaterial(false);
          }}
          onUpdate={init}
        />
      </Modal>
    </>
  );
};
