import styles from "./styles.module.scss";
import { FetchAPI } from "@/hooks/fetch";
import { ModalButtons, Select } from "@/components";
import { NumberInput } from "@mantine/core";
import { useEffect, useState } from "react";

export const MoveStorageItem = ({
  defaultFrom,
  closeFunction,
  onUpdate,
}: {
  defaultFrom: any;
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const [data, setData] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [load, setLoad] = useState<boolean>(true);
  const changeSection = async (val: any) => {
    const { data } = await FetchAPI("GET", `/api/controls/storage?id=${val}`);

    await setItems(data);
  };

  useEffect(() => {
    (async () => {
      const { data } = await FetchAPI("GET", `/api/controls/storages`);

      await setData(data.data);
      await setLoad(false);
      await changeSection(defaultFrom);
    })();
  }, []);

  const submit = async (e: any) => {
    e.preventDefault();
    const formData: any = new FormData(e.target);

    const jsonObject: any = {};
    for (const [key, value] of formData.entries()) {
      jsonObject[key] = value;
    }

    await FetchAPI("PUT", `/api/controls/storage/move`, jsonObject, null, {
      "Content-Type": "application/json",
    });

    closeFunction();
    onUpdate();
  };

  return (
    <form className={styles.content} onSubmit={submit}>
      {!load && (
        <Select
          defaultSelect={defaultFrom}
          placeholder="З секції"
          required={true}
          list={data?.map((el: any) => {
            return { id: el.id, title: el.title };
          })}
          name={"from"}
          onChange={(e: any) => changeSection(e.target.value)}
        />
      )}

      {!load && (
        <Select
          placeholder="В секцію"
          required={true}
          list={data
            ?.filter((x: any) => Number(x.id) !== Number(defaultFrom))
            .map((el: any) => {
              return { id: el.id, title: el.title };
            })}
          name={"to"}
        />
      )}

      {items && (
        <div className={styles.flex}>
          <Select
            placeholder="Продукт"
            required={true}
            list={items?.map((el: any) => {
              return { id: el.product_id, title: el.product_title };
            })}
            name={"item"}
            error={{
              enabled: items.length === 0,
              text: "Елементи для переміщення відсутні",
            }}
          />
          <NumberInput max={100} min={1} name="counts" label="Кількість" />
        </div>
      )}

      <ModalButtons closeFunction={closeFunction} />
    </form>
  );
};
