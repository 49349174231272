import styles from "./styles.module.scss";
import { FetchAPI } from "@/hooks/fetch";
import { Notification } from "@/hooks/notification";
import { useEffect, useState } from "react";
import { File, ModalButtons, MultiSelect } from "@/components";
import {
  Button,
  Flex,
  Input,
  SegmentedControl,
  Skeleton,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

export const NF = ({
  edit,
  closeFunction,
  onUpdate,
}: {
  edit: any;
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const [data, setData] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(true);
  const init = async () => {
    const { data: materials } = await FetchAPI("GET", `/api/helper/categories`);

    materials.data.sort((a: any, b: any) =>
      a.title.localeCompare(b.title, "ru", { sensitivity: "base" })
    );

    await setData({
      materials: materials.data,
    });
    await setLoad(false);
  };

  const submit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    if (edit) {
      await FetchAPI(
        "PUT",
        `/api/helper/not_formal/${edit.id}`,
        formData,
        null,
        { "Content-Type": "multipart/form-data" }
      );
      await Notification(
        "Напівфабрикат успішно змінено",
        { autoClose: 3000 },
        "info"
      );
    } else {
      await FetchAPI("POST", `/api/helper/not_formal`, formData, null, {
        "Content-Type": "multipart/form-data",
      });
      await Notification(
        "Напівфабрикат успішно створено",
        { autoClose: 3000 },
        "info"
      );
    }

    closeFunction();
    onUpdate();
  };

  useEffect(() => {
    (async () => {
      await init();
    })();
  }, []);

  return (
    <form className={styles.content} onSubmit={submit}>
      <Input.Wrapper description="Назва напівфабрикату">
        <Input
          type="text"
          name="title"
          required={true}
          placeholder="Назва"
          defaultValue={edit?.title}
        />
      </Input.Wrapper>
      <Input.Wrapper description="Фото">
        <File
          name="file"
          accept={"image/*"}
          defaultValue={{ ...edit?.image, tableName: "not_formal" }}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Одиниця виміру">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="size"
          mt={5}
          defaultValue={edit?.size}
          data={[
            { label: "КГ", value: "kg" },
            { label: "ШТ", value: "st" },
            { label: "Л", value: "l" },
          ]}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Постачальник">
        {!load ? (
          <MultiSelect
            placeholder={"Сировина"}
            hideLabel={true}
            name={"materials[ids]"}
            list={data.materials}
            defaultValues={edit?.not_formal_materials}
            defaultKey="material_id"
            subInputs={[
              {
                type: "number",
                name: `materials[size]`,
                readOnly: false,
                defaltField: "size",
                unit: "size",
                subSizeCustom: "Кількість",
                isDelete: true,
                step: 0.1,
              },
            ]}
            isDelete={true}
            error={{
              enabled: data?.materials?.length === 0,
              text: "Відсутня сировина",
            }}
          />
        ) : (
          <Skeleton h={36} w={"100%"} />
        )}
      </Input.Wrapper>

      <Flex align={"flex-end"} gap={7}>
        <Input.Wrapper
          w={"100%"}
          description="Введіть вартість за одиницю при виробництві"
        >
          <Input
            type="number"
            step="0.01"
            name="manufacturing_cost"
            required={false}
            placeholder="Вартість за одиницю виробництва"
            defaultValue={edit?.manufacturing_cost}
            onFocus={(e: any) => e.target.select()}
          />
        </Input.Wrapper>
        <Tooltip label="Вартість за одиницю виробництва буде нарахована працівникам які виготовили продукт">
          <Button variant="light" type="button">
            <FontAwesomeIcon width={14} height={14} icon={faQuestion} />
          </Button>
        </Tooltip>
      </Flex>

      <Input.Wrapper description="Коментар/Опис">
        <Textarea
          placeholder="Коментар"
          name="comment"
          mah={200}
          mt={5}
          defaultValue={edit?.comment}
        />
      </Input.Wrapper>
      <ModalButtons
        closeFunction={closeFunction}
        disabled={data?.materials?.length < 1 && !edit}
      />
    </form>
  );
};
