import Icons from '../Icons';
import Input from '../Input';
import styles from './styles.module.scss';
import { useState } from 'react';

interface RadioElement {
  title: string,
  value: string | number
}
interface InputListInterface {
  title: string,
  name: string
}

export interface InputBlock {
  id: number;
}

const InputList = ({ title, name }: InputListInterface) => {
  const [selectedOption, setSelectedOption] = useState("btnradio1");
  const [inputBlocks, setInputBlocks] = useState<InputBlock[]>([{ id: 1 }]);

  const addInputCounterparty = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setInputBlocks((prevBlocks) => [...prevBlocks, { id: Date.now() }]);
  };

  const removeInputCounterparty = (id: number) => {
    setInputBlocks((prevBlocks) =>
      prevBlocks.filter((block) => block.id !== id)
    );
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.id);
  };

  return (<div className={styles.inputList}>
    {inputBlocks.map((block) => (
      <div className={styles.inputLine} key={block.id}>
        <Input type="text" name={`${name}[]`} required={true} placeholder={title} />
        {block.id > 1
          ? <button
            type='button'
            onClick={() => removeInputCounterparty(block.id)}
            className={styles.removeInput}
          >
            <Icons.Trash />
          </button>
          : <button
            type='button'
            onClick={addInputCounterparty}
            className={styles.addInput}
          >
            +
          </button>}
      </div>
    ))}
  </div>);
};
export default InputList;
