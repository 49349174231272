import Icons from "../Icons";
import styles from "./styles.module.scss";
import { ActionIcon, Button, Input } from "@mantine/core";
import { parseSize } from "@/hooks/parse-size";
import { useState } from "react";
import { IconPlus } from "@tabler/icons-react";

interface MultiSelectInterface {
  name: string;
  list: any[];
  placeholder: string;
  hidenSelect?: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
  subParams?: {
    mathType?: "multiplication" | null;
    process?: { index: number }[];
    summIndex: number;
  };
  subInputs?: {
    type: "text" | "number";
    name: string;
    subSize?: string;
    subSizeCustom?: string;
    readOnly: boolean;
    defaltField?: string;
    updator?: boolean;
    defValue?: boolean;
    title?: string;
    isDelete?: boolean;
    hidden?: boolean;
    resultField?: boolean;
    step?: number;
    unit?: string;
  }[];
  error?: {
    enabled: boolean;
    text: string;
  };
  summes?: number[] | null;
  defaultValues?: any[];
  defaultKey?: string;
  isDelete?: boolean;
  addButton?: () => void;
}

const SubInput = ({
  sub_key,
  subInput,
  identifier,
  defValue,
  value,
  subParams,
  select,
  onChange,
  unit,
}: any) => {
  let properiesValue: any = {
    defaultValue: defValue || select[subInput?.defaltField || ""] || 0,
  };

  return (
    <div
      key={sub_key}
      style={subInput?.hidden ? { display: "none" } : {}}
      className={styles.inputBox}
    >
      {/* {subInput?.title && <span>123</span>} */}
      <Input.Wrapper
        description={`
        ${
          subInput?.subSizeCustom
            ? subInput?.subSizeCustom
            : subInput?.subSize
            ? select[subInput?.subSize]
            : "гр"
        } 
        ${unit ? `(${unit})` : ""}
        `}
      >
        <Input
          type={subInput.type}
          name={`${subInput.name}`}
          step={subInput?.step || 1}
          {...(value ? { value: value } : {})}
          readOnly={subInput.readOnly}
          placeholder="0"
          size="xs"
          onChange={(e: any) => onChange && onChange(e.target.value)}
          {...properiesValue}
        />
      </Input.Wrapper>

      {/* <div className={styles.valute}>
        {subInput?.subSizeCustom
          ? subInput?.subSizeCustom
          : subInput?.subSize
          ? select[subInput?.subSize]
          : "гр"}{" "}
        {unit ? `(${unit})` : ""}
      </div> */}
    </div>
  );
};

const InputsLine = ({
  index,
  select,
  name,
  subParams,
  subInputs,
  updator,
  defaultValues,
  summes,
  defaultKey,
  removeItem,
  isDelete,
  disabled,
}: any) => {
  const [values, setValues] = useState<any[]>([]);
  console.log(defaultValues);
  return (
    <div key={index + "_B"} id={index + "_B"} className={styles.listLine}>
      <input
        className={styles.input}
        style={{ display: "none" }}
        value={select?.id}
        readOnly={true}
        name={name}
      />
      <div className={styles.title}>{select?.title}</div>
      {subInputs &&
        !updator &&
        subInputs?.map((subInput: any, sub_key: number) => {
          let unit = null;
          if (subInput?.unit && select[subInput?.unit])
            unit = parseSize(select[subInput?.unit], "minimal");

          const defFind =
            defaultValues &&
            defaultValues.find((x: any) => x[defaultKey] === select.id);

          const defValue = defFind
            ? defFind[subInput?.defaltField || "price"] || 0
            : select[subInput?.defaltField || "price"] || 0;

          let res: number | string = "";

          if (
            (values[sub_key] === undefined || values[sub_key] === null) &&
            !subInput.resultField
          ) {
            setValues((old: any) => {
              return { ...old, [sub_key]: defValue };
            });
          }
          if (subInput.resultField && subParams) {
            if (subParams.mathType === "multiplication") {
              res = Object.values(values)
                .reduce((accumulator, current) => accumulator * current, 1)
                .toFixed(2);
            }
          }

          return (
            !subInput.updator && (
              <SubInput
                sub_key={sub_key}
                onChange={(e: any) => {
                  !subInput.resultField &&
                    setValues((old: any) => {
                      return { ...old, [sub_key]: Number(e || 0) };
                    });
                }}
                subInput={subInput}
                identifier={index + "_B"}
                defValue={defValue}
                value={res}
                subParams={subParams}
                select={select}
                unit={unit}
              />
            )
          );
        })}
      {isDelete && !disabled && (
        <button
          type="button"
          className={styles.delete}
          onClick={() => removeItem(select.id)}
        >
          <Icons.Trash />
        </button>
      )}
    </div>
  );
};

const MultiSelect = ({
  name,
  list = [],
  hidenSelect = false,
  placeholder,
  summes,
  subParams,
  subInputs,
  error,
  defaultValues,
  defaultKey = "id",
  disabled,
  isDelete = false,
  hideLabel,
  addButton,
}: MultiSelectInterface) => {
  const filteredData = list.filter((item) =>
    defaultValues?.some((filterItem) => filterItem[defaultKey] === item.id)
  );

  const [selected, setSelected] = useState<any[]>(filteredData || []);
  const [selectedOption, setSelectedOption] = useState<any>(0);
  const [updator, setUpdate] = useState<boolean>(false);

  const setNewSelect = (e: any) => {
    if (Number(e.target.value) > 0) {
      setUpdate(true);
      setSelectedOption(e.target.value);
      const item = list.find((x: any) => x.id === Number(e.target.value));

      setSelected((old: any[]) => [...old, item]);
      setSelectedOption(0);
      setUpdate(false);
    }
  };
  const removeItem = async (id: number) => {
    await setUpdate(true);
    await setSelected([]);
    const updatedItems = selected.filter((item) => item.id !== id);
    await setSelected(updatedItems);
    await setUpdate(false);
  };
  const parseList = () => {
    const result = list?.filter(
      (obj) => !selected?.some((item) => item?.id === obj.id)
    );

    return result;
  };

  return (
    <div
      className={`${styles.multiselect} ${error?.enabled ? styles.error : ""}`}
    >
      <div className={styles.selectBox}>
        {!hideLabel && <h3 className={styles.selectTitle}>{placeholder}</h3>}
        {hidenSelect && parseList().length <= 0 ? null : (
          <div className={styles.selectContainer}>
            <select
              value={selectedOption}
              defaultValue={0}
              className={`${styles.select} ${disabled ? styles.disabled : ""}`}
              onChange={setNewSelect}
            >
              {error?.enabled && (
                <option disabled value={0}>
                  {error.text}
                </option>
              )}
              {!error?.enabled && placeholder && (
                <option disabled value={0}>
                  Виберіть: {placeholder}
                </option>
              )}

              {parseList().map(
                (option: { title: string; id: any }, key: number) => (
                  <option key={option.id + key} value={option.id}>
                    {option.title}
                  </option>
                )
              )}
            </select>
            {addButton && (
              <ActionIcon
                variant="light"
                color="indigo"
                onClick={addButton}
                aria-label="Додати"
                size="lg"
              >
                <IconPlus
                  style={{ width: "70%", height: "70%" }}
                  stroke={1.5}
                />
              </ActionIcon>
            )}
          </div>
        )}
      </div>

      {selected.length > 0 && (
        <div className={styles.selectedList}>
          {selected.map((select: any, index: number) => {
            return (
              <InputsLine
                key={index}
                index={index}
                select={select}
                disabled={disabled}
                name={name}
                summes={summes}
                subInputs={subInputs}
                updator={updator}
                defaultValues={defaultValues}
                defaultKey={defaultKey}
                subParams={subParams}
                removeItem={removeItem}
                isDelete={isDelete}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
