import styles from "./styles.module.scss";
import { FetchAPI } from "@/hooks/fetch";
import { ModalButtons } from "@/components";
import { useEffect, useState } from "react";
import { Notification } from "@/hooks/notification";
import { Input, SegmentedControl, Textarea } from "@mantine/core";

export const ItemsOfIncome = ({
  edit,
  closeFunction,
  onUpdate,
}: {
  edit: any;
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const [type, setType] = useState<string>("income");

  const submit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    if (edit) {
      await FetchAPI(
        "PUT",
        `/api/helper/items_of_income_and_expenses/${edit.id}`,
        formData,
        null,
        { "Content-Type": "multipart/form-data" }
      );
      await Notification(
        "Статю доходів/витрат успішно змінено",
        { autoClose: 3000 },
        "info"
      );
    } else {
      await FetchAPI(
        "POST",
        `/api/helper/items_of_income_and_expenses`,
        formData,
        null,
        { "Content-Type": "multipart/form-data" }
      );
      await Notification(
        "Статю доходів/витрат успішно додано",
        { autoClose: 3000 },
        "info"
      );
    }

    closeFunction();
    onUpdate();
  };

  useEffect(() => {
    if (edit) {
      setType(edit.intype);
    }
  }, [edit]);

  return (
    <form className={styles.content} onSubmit={submit}>
      <Input.Wrapper description="Введіть назву">
        <Input
          type="text"
          name="title"
          required={true}
          placeholder="Назва"
          defaultValue={edit?.title}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Тип статті">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="intype"
          mt={3}
          defaultValue={edit?.intype}
          onChange={setType}
          data={[
            {
              label: "Дохід",
              value: "income",
            },
            {
              label: "Витрати",
              value: "costs",
            },
            {
              label: "Переміщення",
              value: "moving",
            },
          ]}
        />
      </Input.Wrapper>

      {type === "costs" && (
        <Input.Wrapper description="Підтип статті">
          <SegmentedControl
            fullWidth
            radius="xl"
            color="#228be6"
            name="subintype"
            mt={3}
            defaultValue={edit?.subintype}
            data={[
              {
                label: "Постійні",
                value: "Permanent",
              },
              {
                label: "Змінні",
                value: "variables",
              },
              {
                label: "Інше",
                value: "other",
              },
            ]}
          />
        </Input.Wrapper>
      )}

      <Input.Wrapper description="Знижки">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="is_statistic"
          mt={3}
          defaultValue={edit?.is_statistic.toString() || "false"}
          data={[
            {
              label: "Бере участь у статистиці",
              value: "true",
            },
            {
              label: "Не бере участь у статистиці",
              value: "false",
            },
          ]}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Коментар/Опис">
        <Textarea
          placeholder="Коментар"
          name="comment"
          mah={200}
          mt={5}
          defaultValue={edit?.comment}
        />
      </Input.Wrapper>
      <ModalButtons closeFunction={closeFunction} />
    </form>
  );
};
