import Image from 'next/image';
import { useEffect, useState } from 'react';
// const {
//     publicRuntimeConfig: { API_URL },
// } = getConfig();

const stringToColor = (str: string) => {
    var hash = 0;
    var color = "#";
    var i;
    var value;
    var strLength;

    if (!str) {
        return color + "333333";
    }

    strLength = str.length;

    for (i = 0; i < strLength; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    for (i = 0; i < 3; i++) {
        value = (hash >> (i * 8)) & 0xff;
        color += ("00" + value.toString(16)).substr(-2);
    }

    return color;
};

const generateAvatar = (data: any, size: number) => {
    let symbols = data?.title ? data?.title?.trim() : data?.name;

    const canvas = document.createElement("canvas");
    canvas.id = "mini_user_avatar";
    canvas.width = size;
    canvas.height = size;
    const ctx = canvas.getContext("2d");

    if ((!data?.avatar || data?.avatar === "undefined" || data?.avatar === null) && ctx) {
        ctx.beginPath();
        ctx.fillStyle = stringToColor(symbols[0] + " " + symbols[1]);
        ctx.fillRect(0, 0, size, size);
        ctx.closePath();

        ctx.beginPath();
        ctx.font = `${Number(size) / 2}px Arial`;
        ctx.fillStyle = "white";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(
            `${symbols[0]}${symbols[1]}`,
            canvas.width / 2,
            canvas.width / 2
        );
        ctx.closePath();
        return canvas.toDataURL();
    }

    return data.avatar;
};

export const Avatar = ({ data, size }: { data: any; size: number }) => {
    const [isLoad, setLoad] = useState<boolean>(false);

    useEffect(() => setLoad(true), []);
    if (isLoad && data) {

        return (
            <Image
                src={generateAvatar(data, size)}
                alt={`image`}
                width={size}
                height={size}
            />

        );
    } else {
        return null;
    }
};
