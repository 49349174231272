import styles from "./styles.module.scss";
import { Box, Button, Flex, Text } from "@mantine/core";
import { FetchAPI } from "@/hooks/fetch";
import { formatCurrency } from "@/hooks/format-currency";
import { Notification } from "@/hooks/notification";
import { useState } from "react";

export const SaleModal = ({
  data,
  closeFunction,
  onUpdate,
}: {
  data?: any;
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const [load, setLoad] = useState<boolean>(false);
  const submit = async () => {
    try {
      setLoad(true);

      await FetchAPI(
        "PUT",
        `/api/accounting/success-sale`,
        { id: data.id, is_success_sale: true },
        null,
        {
          "Content-Type": "application/json",
        }
      );
      await Notification("Продаж підтверджено", { autoClose: 3000 }, "success");
    } catch (e) {
      console.log(e);
      await Notification(
        "Упс, якась помилка... Наші програмісти вже її виправляють",
        { autoClose: 3000 },
        "error"
      );
    } finally {
      closeFunction();
      onUpdate();
      await setLoad(false);
    }
  };
  return (
    <Box className={styles.content} pos="relative">
      <Flex w={"100%"} gap={7} direction={"column"}>
        <Flex gap={7}>
          <Text size="14px">Покупець:</Text>
          <Text size="14px">{data?.debitor_name}</Text>
        </Flex>
        <Flex gap={7}>
          <Text size="14px">Сумма:</Text>
          <Text size="14px">{formatCurrency(data?.summ)}</Text>
        </Flex>
      </Flex>
      <Box w={"100%"} bg={"blue"} p={14} style={{ borderRadius: 7 }}>
        <Text c={"white"} size="14px">
          Ви дійсно хочете підтвердити продаж ?
        </Text>
      </Box>
      <Flex w={"100%"} gap={14}>
        <Button
          w={"100%"}
          variant="light"
          type="button"
          onClick={closeFunction}
        >
          Відміна
        </Button>

        <Button w={"100%"} variant="gradient" type="button" onClick={submit}>
          Підтвердити
        </Button>
      </Flex>
    </Box>
  );
};
