import { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";

export const Accordion = ({ title, defaultOpen = false, children = null, changeOpen = null }: any) => {
    const [open, setOpen] = useState(defaultOpen),
        accordion = useRef(null),
        setHeight = () => {
            const { current }: any = accordion;

            if (current) {
                let childNodes: any = current.childNodes;

                if (!open) {
                    current.style.height = 0 + "px";
                    return;
                }

                let newHeight = 0;

                for (const child of childNodes) newHeight += child.clientHeight;

                current.style.height = newHeight + "px";
            }

        },
        toggle = async () => {
            await setOpen(!open);
        };

    useEffect(() => {
        setHeight();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <ul className={styles.accItem}>
            <li className={`${styles.accTitle} ${open ? styles.accOpen : ""}`}>
                <button onClick={toggle}>
                    <label>{title}</label>
                    <span>
                        <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.22993 11V0.199951H7.79793V11H5.22993ZM0.981934 6.79995V4.39995H12.0459V6.79995H0.981934Z" fill="#7F7DDD" />
                        </svg>
                    </span>
                </button>
            </li>
            <li
                className={`${styles.accContent} ${open ? styles.accOpen : ""}`}
                ref={accordion}
                style={defaultOpen ? { transition: "0s" } : {}}
            >
                {children}
            </li>
        </ul>
    );
}

