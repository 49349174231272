import { Flex, Skeleton } from "@mantine/core";
import styles from "./styles.module.scss";

const LoadModal = ({ length }: { length: number }) => {
  const arr = Array.from({ length }, (_, i) => i);

  return (
    <Flex direction={"column"} w={"100%"} gap={7} miw={600}>
      {arr.map((e: number, key: number) => (
        <Flex direction={"column"} gap={5} key={key}>
          <Skeleton h={18} w={120} />
          <Skeleton h={32} w={"100%"} />
        </Flex>
      ))}

      <Flex direction={"row"} gap={5} justify={"space-between"} pt={24}>
        <Skeleton h={38} w={140} />
        <Skeleton h={38} w={140} />
      </Flex>
    </Flex>
  );
};

export default LoadModal;
