import getConfig from "next/config";
import Icons from "../Icons";
import Modals from "@/modals";
import moment from "moment";
import styles from "./styles.module.scss";
import { Button, Flex, Text } from "@mantine/core";
import {
  faBoxes,
  faDownload,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FetchAPI } from "@/hooks/fetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatCurrency } from "@/hooks/format-currency";
import { IconAlertCircle } from "@tabler/icons-react";
import { Modal } from "@/containers/modal";
import { MultiSelect, Select, Tooltip } from "@mantine/core";
import { useState } from "react";

const {
  publicRuntimeConfig: { API_URL },
} = getConfig();

const Section = ({ data }: { data: any }) => {
  const getFileLink = (tableName: string, filename: string) => {
    return `${API_URL}/${tableName}/${filename}`;
  };

  return (
    <div className={styles.section}>
      <div className={`${styles.sectionContent} ${styles[data.product_type]}`}>
        <div className={styles.sectionTitle}>
          <span>{data.product_title}</span>
          {data.comment && (
            <Tooltip
              multiline
              w={300}
              label={data.comment}
              color="blue"
              position="bottom-end"
              withArrow
            >
              <div>
                <IconAlertCircle
                  size="1rem"
                  style={{ display: "block", opacity: 0.5 }}
                />
              </div>
            </Tooltip>
          )}
        </div>
        <div className={styles.sectionData}>
          <div className={styles.image}>
            {data?.image?.filename ? (
              <img
                className={styles.tableImg}
                width="42px"
                height="42px"
                src={getFileLink(data.product_type, data.image.filename)}
              />
            ) : (
              <div className={styles.notPhoto}>
                <Icons.Image />
              </div>
            )}
          </div>
          <div className={styles.information}>
            <div className={styles.infoLine}>
              <span>Бронь</span>
              <span>{data.book_count || 0}</span>
            </div>
            <div className={styles.infoLine}>
              <span>Залишок</span>
              <span>{data.counts}</span>
            </div>
            <div className={styles.infoLine}>
              <span>Доступно</span>
              <span>{data.counts - (data.book_count || 0)}</span>
            </div>
            <div className={styles.infoLine}>
              <span>Собівартість заг.</span>
              <span>{formatCurrency(data.cost_price_summ || 0)}</span>
            </div>
            <div className={styles.infoLine}>
              <span>Собівартість од.</span>
              <span>{formatCurrency(data.cost_price || 0)}</span>
            </div>

            <div className={styles.infoLine}>
              <span>Секція</span>
              <span>{data.storage_title}</span>
            </div>
          </div>
        </div>
        <div className={styles.sectionFooter}>
          <div className={styles.date}>
            {moment(data.create_date).format("YYYY-MM-DD")}
          </div>
          <div className={styles.part}>
            (№ партії {moment(data.batch_of_products).format("YYYY-MM-DD")})
          </div>
        </div>
      </div>
    </div>
  );
};

export const StorageData = ({
  data,
  items,
  selectSection,
  setSelectSection,
  update,
  downloadReport,
}: {
  data: any;
  items: any;
  selectSection: number | string;
  setSelectSection: (e: any) => void;
  update: () => void;
  downloadReport: () => void;
}) => {
  const [modalAdd, toggleModalAdd] = useState<boolean>(false);
  const [modalMove, toggleModalMove] = useState<boolean>(false);
  const [types, setTypes] = useState<string[]>([]);

  const parseSections = () => {
    const _sections =
      types.length > 0
        ? items?.filter((obj: any) => types.includes(obj.product_type))
        : items;

    if (_sections?.length > 0)
      return _sections?.map((el: any, index: number) => (
        <Section data={el} key={index} />
      ));
    else
      return (
        <Flex
          direction={"column"}
          gap={14}
          align={"center"}
          justify={"center"}
          w={"100%"}
          h={"100%"}
          c={"#646fcf"}
        >
          <FontAwesomeIcon width={38} height={38} icon={faBoxes} />
          <Text size="16px">На складі відсутні товари та інгридієнти</Text>
        </Flex>
      );
  };
  return (
    <div className={styles.storageData}>
      <div className={styles.controls}>
        <div className={styles.row}>
          <Select
            searchable
            nothingFoundMessage="Не знайдено"
            clearable
            data={[
              { value: "all", label: "Всі" },
              ...data?.map((el: any) => {
                return {
                  value: el.id.toString(),
                  label: el.title,
                };
              }),
            ]}
            defaultValue={"all"}
            placeholder="Виберіть секцію"
            rightSection={
              <Tooltip
                label="Пошук по назві секції"
                position="top-end"
                withArrow
              >
                <div>
                  <IconAlertCircle
                    size="1rem"
                    style={{ display: "block", opacity: 0.5 }}
                  />
                </div>
              </Tooltip>
            }
            onChange={setSelectSection}
          />

          <MultiSelect
            clearable
            data={[
              { value: "materials", label: "Сировина" },
              { value: "packages", label: "Упаковка" },
              { value: "not_formal", label: "Напівфабрикати" },
              { value: "product", label: "Продукти" },
            ]}
            placeholder="Виберіть тип"
            onChange={(e: any) => setTypes(e)}
          />
        </div>
        <div className={styles.row}>
          <Button type="button" onClick={downloadReport} variant="light">
            <FontAwesomeIcon icon={faDownload} />
            PDF
          </Button>
          <Button
            variant="light"
            type={"button"}
            onClick={() => toggleModalAdd(true)}
          >
            Створити секцію
          </Button>
          <Button
            variant="filled"
            type={"button"}
            onClick={() => toggleModalMove(true)}
          >
            Переміщення
          </Button>

          {!data.find((x: any) => x.id === selectSection)?.is_default &&
            selectSection !== "all" && (
              <Button
                variant="filled"
                type={"button"}
                onClick={async () => {
                  await FetchAPI("DELETE", `/api/controls/storage/${data.id}`);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
                Видалити секцію
              </Button>
            )}
        </div>
      </div>
      <div className={styles.storages}>
        <div className={styles.storageContent}>{parseSections()}</div>
      </div>

      <Modal
        title={`Створити секцію`}
        open={modalAdd}
        closeFunction={() => {
          toggleModalAdd(false);
        }}
      >
        <Modals.AddStorageSection
          edit={false}
          closeFunction={() => {
            toggleModalAdd(false);
          }}
          onUpdate={update}
        />
      </Modal>

      <Modal
        title={`Переміщення`}
        open={modalMove}
        closeFunction={() => {
          toggleModalMove(false);
        }}
      >
        <Modals.MoveStorageItem
          defaultFrom={selectSection}
          closeFunction={() => {
            toggleModalMove(false);
          }}
          onUpdate={update}
        />
      </Modal>
    </div>
  );
};
