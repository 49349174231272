import styles from "./styles.module.scss";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const NoneChartData = ({ text }: { text: string }) => {
  return (
    <div className={styles.noneChart}>
      <FontAwesomeIcon icon={faChartLine} />
      <span>{text}</span>
    </div>
  );
};
