import getConfig from "next/config";
import styles from "./styles.module.scss";
import { Button, Input } from "@mantine/core";
import { useRouter } from "next/router";
import { useState } from "react";

const {
  publicRuntimeConfig: { API_URL },
} = getConfig();

const ForgotForm = () => {
  const router = useRouter();
  const [error, setError] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const submit = async (e: any) => {
    e.preventDefault();

    try {
      setLoad(true);
      const result = await fetch(`${API_URL}/api/forgot`, {
        body: JSON.stringify({
          email: e.target.email.value,
        }),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      const data = await result.json();

      if (data?.errors?.length > 0) {
        // if (data.error) {
        setError(true);
        setLoad(false);
        // }
      } else {
        setLoad(false);
        setSuccess(true);
      }
    } catch (e) {}
  };

  if (load) return <p>Завантаження...</p>;

  return (
    <section className={styles.section}>
      {!success && error && (
        <div className={styles.error}>
          <p>Упс... такий користувач відсутній в системі</p>
          <button className={styles.close} onClick={() => setError(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g id="close">
                <path
                  id="x"
                  d="M18.717 6.697l-1.414-1.414-5.303 5.303-5.303-5.303-1.414 1.414 5.303 5.303-5.303 5.303 1.414 1.414 5.303-5.303 5.303 5.303 1.414-1.414-5.303-5.303z"
                />
              </g>
            </svg>
          </button>
        </div>
      )}
      {success ? (
        <div className={styles.loginForm}>
          <p className={styles.successText}>
            Новий пароль відправлено вам на електронну пошту.
          </p>
          <button
            type="button"
            className={styles.submit}
            onClick={() => router.push("/")}
          >
            На сторінку входу
          </button>
        </div>
      ) : (
        <>
          <form onSubmit={submit} className={styles.loginForm}>
            <Input.Wrapper description="Введіть ваш email">
              <Input type="email" name="email" placeholder="Електронна пошта" />
            </Input.Wrapper>
            <Button type="submit" variant="gradient">
              Відновити пароль
            </Button>
            <Button
              type="button"
              variant="outline"
              onClick={() => router.push("/")}
            >
              Вхід
            </Button>
          </form>
        </>
      )}
    </section>
  );
};

export default ForgotForm;
