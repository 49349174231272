import styles from "./styles.module.scss";
import { FetchAPI } from "@/hooks/fetch";
import { Input, ModalButtons, Textarea } from "@/components";
import { useState } from "react";

export const AddStorageSection = ({
  edit,
  closeFunction,
  onUpdate,
}: {
  edit: any;
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const [data, setData] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(true);

  const submit = async (e: any) => {
    e.preventDefault();
    const formData: any = new FormData(e.target);

    const jsonObject: any = {};
    for (const [key, value] of formData.entries()) {
      jsonObject[key] = value;
    }
    await FetchAPI("POST", `/api/controls/storage`, formData, null, {
      "Content-Type": "application/json",
    });

    closeFunction();
    onUpdate();
  };

  return (
    <form className={styles.content} onSubmit={submit}>
      <Input
        type="text"
        name="title"
        required={true}
        placeholder="Назва"
        defauleValue={edit?.title}
      />
      <Textarea
        placeholder="Коментар"
        name="comment"
        defauleValue={edit?.comment}
      />

      <ModalButtons
        closeFunction={closeFunction}
        disabled={data?.products?.length < 0}
      />
    </form>
  );
};
