import LoginForm from "../login-form";
import styles from "./styles.module.scss";
import { Logotype } from "../Logotype";

const RegisterWidget = () => {
  return (
    <div className={styles.loginPage}>
      <div className={styles.data}>
        <div className={styles.logo}>
          <Logotype />
        </div>
        <LoginForm />
      </div>
    </div>
  );
};

export default RegisterWidget;
