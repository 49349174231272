import getConfig from "next/config";
import Icons from "../Icons";
import moment from "moment";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatCurrency } from "@/hooks/format-currency";
import { Paginations } from "../Paginations";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useStaff } from "@/context/staff";
import {
  Button,
  Input,
  CloseButton,
  Menu,
  Flex,
  HoverCard,
  Text,
} from "@mantine/core";
import {
  faBan,
  faCircle,
  faDownload,
  faQuestion,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import { title } from "process";

const {
  publicRuntimeConfig: { API_URL },
} = getConfig();

const SummData = ({ all_summ_average }: { all_summ_average: number }) => {
  return all_summ_average ? (
    <div>{all_summ_average} ГРН</div>
  ) : (
    <div>Невірні вхідні дані</div>
  );
};

export const Table = ({
  tableName,
  columns,
  values,
  counts,
  titleTable,
  subTitle,
  add,
  ignoreRole = true,
  customButtons,
  exports,
  customStyles,
  customManagement = null,
  searchField,
  activeValue,
  searchValue,
  alt_components,
  parseSearch,
  search,
  toDelete,
  toEdit,
  toView,
  selectElement,
  role_param,
  customs,
  isEditField,
}: {
  tableName: string;
  columns: {
    name: string;
    type?: string;
    click?: (e: any) => void;
    title: string;
    sort?: () => void;
    caption?: string;
  }[];
  values: any[];
  counts: number;
  titleTable: string;
  subTitle?: string;
  add?: {
    title: string;
    click: () => void;
  };
  ignoreRole?: boolean;
  customButtons?: any[];
  exports?: boolean;
  customStyles?: any;
  customManagement?: any;
  activeValue?: number | string;
  searchField?: string;
  searchValue?: string | null;
  alt_components?: any;
  parseSearch?: (text: string, size: number, sv?: string) => void;
  search?: (val: string | null) => void;
  toEdit?: (e: any) => void;
  toDelete?: (e: any) => void;
  toView?: (e: any) => void;
  role_param?: string;
  selectElement?: { event: (key: any) => void; key: string; active: boolean };
  customs?: any;
  isEditField?: string;
}) => {
  const { session } = useStaff();
  const router: any = useRouter();
  const [serachValue, setSearchValue] = useState<string | null>(null);
  const { page = 1 } = router.query;

  const getFileLink = (filename: string) => {
    return `${API_URL}/${tableName}/${filename}`;
  };

  const ViewDate = ({
    data,
    alt,
    comp,
  }: {
    data: string | null;
    alt?: any;
    comp?: any;
  }) => {
    return (
      <div>
        {data
          ? comp
            ? alt_components[comp.component_name](
                moment(data).format("YYYY-MM-DD")
              )
            : moment(data).format("YYYY-MM-DD")
          : alt?.type === "component"
          ? alt_components[alt.component_name] || "..."
          : alt?.value}
      </div>
    );
  };

  const valutes: any = {
    usd: {
      title: "Долар",
    },
    uah: {
      title: "Гривня",
    },
    eur: {
      title: "Євро",
    },
  };
  const types: any = {
    tov: { title: "ТОВ" },
    fop: { title: "ФОП" },
    individual: { title: "Фіз. Особа" },
  };
  const intypes: any = {
    income: { title: "Дохід" },
    costs: { title: "Витрати" },
    moving: { title: "Переміщення" },
  };
  const movements: any = {
    return: { title: "Повернення", bg: "silver", color: "#fff" },
    sale_from_realization: {
      title: "Продаж від реалізації",
      color: "lightgreen",
    },
    foreclosure_sale: { title: "Продаж", bg: "green", color: "#fff" },
    booking: { title: "Бронювання", bg: "yellow", color: "#fff" },
    coming: { title: "Прихід", bg: "blue", color: "#fff" },
    discharge: { title: "Розхід", bg: "red", color: "#fff" },
    shipment: { title: "Відвантаження", bg: "pink", color: "#fff" },
    brak: { title: "Брак", bg: "darkgrey", color: "#fff" },
    adjustment_plus: { title: "Коригування +", bg: "silver", color: "#fff" },
    adjustment_minus: { title: "Коригування -", bg: "silver", color: "#fff" },
    degustation: { title: "Дегустація", bg: "magenta", color: "#fff" },
    no_pay: { title: "Списання без оплати", bg: "red", color: "#fff" },
  };
  const pay_types: any = {
    cash: { title: "Готівка" },
    bank: { title: "Безготівка" },
  };
  const pdvs: any = {
    pdv: { title: "ПДВ" },
    no_pdv: { title: "Без ПДВ" },
  };
  const sizes: any = {
    kg: { title: "кілограм" },
    st: { title: "штук" },
    l: { title: "літр" },
  };
  const object_types: any = {
    not_formal: { title: "Напівфакрикат" },
    product: { title: "Продукт" },
  };

  const parseData = (
    val: any,
    col: any,
    size?: boolean,
    k?: any,
    alt?: any,
    comp?: any,
    properties?: any
  ) => {
    switch (col) {
      case "numeric":
        return k + 10 * (Number(page) - 1);
      case "pay_type":
        return pay_types[val[col]].title;
      case "type":
        return types[val[col]].title;
      case "object_type":
        return object_types[val[col]].title;
      case "valute":
        return valutes[val[col]].title;
      case "intype":
        return val[col] ? intypes[val[col]].title : "...";
      case "size":
        return sizes[val[col]].title;

      case "movement":
        return (
          <Flex
            h={32}
            p={"0 14px"}
            align={"center"}
            bg={movements[val[col]]?.bg}
            style={{ borderRadius: 24 }}
            gap={7}
            c={movements[val[col]]?.color}
          >
            <FontAwesomeIcon icon={faCircle} width={6} height={6} />
            <Text size={"13px"} fw={"bold"}>
              {movements[val[col]]?.title}
            </Text>
          </Flex>
        );
      case "pdv":
        return pdvs[val[col]]?.title;
      case "price":
        return formatCurrency(val[col] || 0);
      case "all_summ":
        return formatCurrency(val[col] || 0);
      case "total_sales_value":
        return formatCurrency(val[col] || 0);
      case "expected_prime_price":
        return formatCurrency(val[col] || 0);
      case "debt":
        return formatCurrency(val[col] || 0);
      case "accurals_summ":
        return formatCurrency(val[col] || 0);
      case "balance":
        return formatCurrency(val[col] || 0);
      case "one_cost_price":
        return formatCurrency(val[col] || 0);
      case "all_cost_price":
        return formatCurrency(val[col] || 0);
      case "prime_price":
        return formatCurrency(val[col] || 0);
      case "product_accounting_price":
        return formatCurrency(val[col] || 0);
      case "operate_summ":
        return formatCurrency(val[col] || 0);
      case "cost":
        return formatCurrency(val[col] || 0);
      case "manufacturing_cost":
        return formatCurrency(val[col] || 0);
      case "expected_price":
        return formatCurrency(val[col] || 0);

      case "created_time":
        return val[col].split(".")[0];

      case "summ":
        return val[col] ? formatCurrency(val[col] || 0) : "Невалідні дані";
      case "create_date":
        return <ViewDate comp={comp} alt={alt} data={val[col]} />;
      case "start_date":
        return <ViewDate comp={comp} alt={alt} data={val[col]} />;
      case "end_date":
        return <ViewDate comp={comp} alt={alt} data={val[col]} />;
      case "date":
        return <ViewDate comp={comp} alt={alt} data={val[col]} />;
      case "from_date":
        return <ViewDate comp={comp} alt={alt} data={val[col]} />;
      case "to_date":
        return <ViewDate comp={comp} alt={alt} data={val[col]} />;

      case "payment_summ":
        return formatCurrency(val[col] || 0);
      case "total_sales_value":
        return formatCurrency(val[col] || 0);
      case "accrued":
        return formatCurrency(val[col] || 0);
      case "summ_expected_price":
        return formatCurrency(val[col] || 0);
      case "expected_price":
        return formatCurrency(val[col] || 0);

      case "category":
        return (
          <Flex
            w={120}
            justify={"center"}
            style={{ borderRadius: 7 }}
            p={"10px 14px"}
            bg={properties?.colors[val[col]]}
          >
            <Text c={"#fff"} size={"14px"} fw={"bold"}>
              {val[col]}
            </Text>
          </Flex>
        );

      case "native":
        return val[col] ? (
          <Flex
            h={32}
            p={"0 14px"}
            align={"center"}
            justify={"center"}
            w={200}
            bg={"cyan"}
            style={{ borderRadius: 24 }}
            gap={7}
            c={"#fff"}
          >
            <Text size={"13px"} fw={"bold"}>
              Системна
            </Text>
          </Flex>
        ) : (
          <Flex
            h={32}
            w={200}
            p={"0 14px"}
            align={"center"}
            justify={"center"}
            bg={"lime"}
            style={{ borderRadius: 24 }}
            gap={7}
            c={"#fff"}
          >
            <Text size={"13px"} fw={"bold"}>
              Користувальницька
            </Text>
          </Flex>
        );

      case "all_summ_average":
        return <SummData all_summ_average={val[col]} />;

      case "comment":
        return val[col] ? (
          <HoverCard width={280} shadow="md">
            <HoverCard.Target>
              <Button variant="white">
                {val[col].slice(0, 5)}...
                <Flex
                  ml={14}
                  bg={"blue"}
                  w={24}
                  h={24}
                  justify={"center"}
                  align={"center"}
                  c={"#fff"}
                  style={{ borderRadius: 14 }}
                >
                  <FontAwesomeIcon icon={faQuestion} />
                </Flex>
              </Button>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Flex direction={"column"}>
                <Text size={"12px"} c={"#bebebe"}>
                  Коментар
                </Text>
                <Text size={"14px"}>{val[col]}</Text>
              </Flex>
            </HoverCard.Dropdown>
          </HoverCard>
        ) : (
          "..."
        );

      case "pin":
        return (
          <div className={styles.pin}>
            <button className="new-pin-btn">
              <Icons.Key />
            </button>
            {val[col]}
          </div>
        );
      default:
        return (
          (size ? val[col] || 0 : val[col] || "...") +
          ` ${size && val?.size ? sizes[val?.size].title : ""}`
        );
    }
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "+") {
      // Здесь можно добавить логику, которая выполнится при нажатии клавиши "+"
      if (add) add.click();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className={styles.tableBox} style={customStyles || {}}>
      <div className={styles.containerboxShadow}>
        <div className={styles.tableBoxData}>
          <div className={styles.containersHeaderTable}>
            <span className={styles.titleTable}>
              {titleTable}
              <p>{subTitle}</p>
            </span>
            <div className={styles.flex}>
              {search && (
                <Input
                  type="search"
                  placeholder="Пошук"
                  onReset={(e: any) => search(null)}
                  value={serachValue || ""}
                  rightSectionPointerEvents="auto"
                  onChange={(e: any) => {
                    setSearchValue(e.target.value);
                    search(e.target.value);
                  }}
                  rightSection={
                    <CloseButton
                      aria-label="Clear input"
                      onClick={() => {
                        setSearchValue(null);
                        search(null);
                      }}
                      style={{ display: serachValue ? undefined : "none" }}
                    />
                  }
                />
              )}

              {add &&
                (ignoreRole ||
                  session?.role[role_param || "null"] === "edit") && (
                  <Button variant="filled" onClick={add.click}>
                    {add.title}
                  </Button>
                )}
              {customManagement}
              {exports && (
                <Button variant="light">
                  <FontAwesomeIcon icon={faDownload} />
                  Експорт
                </Button>
              )}
            </div>
          </div>
          <div className={styles.tableContent}>
            <table className={styles.table} border={0} cellSpacing={0}>
              {values.length > 0 && (
                <thead>
                  <tr>
                    {columns?.map((head: any, key: number) => (
                      <td key={key}>
                        <div className={styles.thContent}>
                          <span>{head.title}</span>
                        </div>
                      </td>
                    ))}
                    <td></td>
                  </tr>
                </thead>
              )}
              <tbody>
                {!values ||
                  (values.length <= 0 && (
                    <tr style={{ border: 0 }}>
                      <td colSpan={columns.length} style={{ border: 0 }}>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            padding: "24px 0",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 14,
                            color: "rgb(138 117 226)",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTable}
                            style={{ width: 24, height: 24 }}
                          />
                          <span
                            style={{
                              fontSize: 14,
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            }}
                          >
                            Данні відсутні
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                {values?.map((val: any, key: number) => (
                  <tr
                    key={key}
                    onClick={
                      selectElement
                        ? () => selectElement.event(val[selectElement.key])
                        : () => {}
                    }
                    style={
                      selectElement
                        ? {
                            cursor: "pointer",
                            backgroundColor:
                              val[selectElement.key] === activeValue
                                ? "#cdeff7"
                                : "",
                          }
                        : {}
                    }
                  >
                    {columns?.map((col: any, key2: number) => {
                      if (col.name === "image") {
                        return (
                          <td key={key2} className={styles.image}>
                            <div className={styles.tbContent}>
                              {val[col.name].filename ? (
                                <img
                                  className={styles.tableImg}
                                  width="42px"
                                  height="42px"
                                  src={getFileLink(val[col.name].filename)}
                                />
                              ) : (
                                <div className={styles.notPhoto}>
                                  <Icons.Image />
                                </div>
                              )}
                            </div>
                          </td>
                        );
                      }

                      return (
                        <td key={key2}>
                          {parseSearch && searchField === col.name ? (
                            <div
                              className={`${styles.tbContent} ${
                                col?.size && !val[col.name]
                                  ? styles.noneData
                                  : ""
                              }`}
                              dangerouslySetInnerHTML={{
                                __html:
                                  searchField === col.name
                                    ? parseSearch(
                                        `${parseData(
                                          val,
                                          col.name,
                                          col.size,
                                          key + 1,
                                          null,
                                          null,
                                          col.properties
                                        )}`,
                                        23,
                                        searchValue || ""
                                      )
                                    : parseData(
                                        val,
                                        col.name,
                                        col.size,
                                        key + 1,
                                        null,
                                        null,
                                        col.properties
                                      ),
                              }}
                            />
                          ) : (
                            <div
                              key={key2}
                              className={`${styles.tbContent} ${
                                col?.size && !val[col.name]
                                  ? styles.noneData
                                  : ""
                              }`}
                            >
                              {col?.icon ? (
                                <FontAwesomeIcon
                                  color="#bebebe"
                                  icon={col?.icon}
                                />
                              ) : null}
                              {parseData(
                                val,
                                col.name,
                                col.size,
                                key + 1,
                                col.alternative_data,
                                col.component,
                                col.properties
                              )}
                            </div>
                          )}
                        </td>
                      );
                    })}
                    <td>
                      <div className={styles.buttons}>
                        {/* <button type="button">
                        <Icons.Trash />
                      </button> */}
                        {customButtons?.map((btn: any, key: number) => {
                          let disabled = btn?.disabled
                            ? val[btn.disabled.field] === btn.disabled.value
                            : false;

                          let title_b = btn?.disabled
                            ? val[btn.disabled.field] === btn.disabled.value
                              ? btn.disabled?.title || btn.title
                              : btn.title
                            : btn.title;

                          let click = () => btn.onClick(val);
                          let color = "blue";

                          if (
                            btn?.alternative &&
                            val[btn.alternative.field] === btn.alternative.value
                          ) {
                            title_b = btn.alternative.title;
                            click = () => btn.alternative.onClick(val);
                            color = btn.alternative.color;
                          }

                          return (
                            <Button
                              key={key}
                              variant={btn.variant}
                              type={btn.type}
                              disabled={disabled}
                              color={color}
                              onClick={click}
                            >
                              <Text size="14px" fw={"bold"}>
                                {title_b}
                              </Text>
                            </Button>
                          );
                        })}

                        {((toEdit &&
                          !(isEditField ? val[isEditField] : false)) ||
                          (toDelete &&
                            !(isEditField ? val[isEditField] : false)) ||
                          toView ||
                          customs?.list?.length > 0) && (
                          <Menu shadow="md" width={200} disabled={val?.native}>
                            <Menu.Target>
                              <Button disabled={val?.native} variant="light">
                                {val?.native ? (
                                  <FontAwesomeIcon icon={faBan} />
                                ) : (
                                  "..."
                                )}
                              </Button>
                            </Menu.Target>

                            <Menu.Dropdown>
                              <Menu.Label>Дії</Menu.Label>
                              {toEdit &&
                                !(isEditField ? val[isEditField] : false) && (
                                  <Menu.Item
                                    color="#7d7d7d"
                                    onClick={() => toEdit(val)}
                                  >
                                    Редагувати
                                  </Menu.Item>
                                )}
                              {toDelete &&
                                !(isEditField ? val[isEditField] : false) && (
                                  <Menu.Item
                                    color="red"
                                    onClick={() => toDelete(val)}
                                  >
                                    Видалити
                                  </Menu.Item>
                                )}

                              {toView && (
                                <Menu.Item
                                  color="green"
                                  onClick={() => toView(val)}
                                >
                                  Перегляд
                                </Menu.Item>
                              )}

                              {customs?.label && (
                                <Menu.Label>{customs?.label}</Menu.Label>
                              )}

                              {customs?.list?.map(
                                (custom: any, btn_index: number) => (
                                  <Menu.Item
                                    key={btn_index}
                                    onClick={() => custom.function(val)}
                                  >
                                    {custom.caption}
                                  </Menu.Item>
                                )
                              )}
                            </Menu.Dropdown>
                          </Menu>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.counters}>
            Показано {values.length} из {counts}
          </div>
          <Paginations counts={counts} page={page || 1} />
        </div>
      </div>
    </div>
  );
};
