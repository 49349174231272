import Image from "@tiptap/extension-image";
import StarterKit from "@tiptap/starter-kit";
import styles from "./styles.module.scss";
import { faImage, faSave } from "@fortawesome/free-solid-svg-icons";
import { FetchAPI } from "@/hooks/fetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, RichTextEditor } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import { useEffect, useState } from "react";
import {
  ActionIcon,
  Button,
  Flex,
  Indicator,
  Input,
  ScrollArea,
  Switch,
  Text,
  Textarea,
} from "@mantine/core";
import { IconDisc } from "@tabler/icons-react";

interface Help {
  id: number;
  title: string;
  key: string;
  value: string;
  active: boolean;
  use_video: boolean;
  video_widget: string | null;
}
const Helpers = ({ data }: { data: { list: any[] } }) => {
  const [selected, setSelected] = useState<Help | null>(null);
  const [video_widget, setWidget] = useState<string>("");

  const editor = useEditor({
    extensions: [StarterKit, Link, Image],
    content: selected?.value || "...",
  });

  const toggleActive = async (active: boolean, id: number) => {
    data.list.find((x: Help) => x.id === id).active = active;
    await FetchAPI(
      "PUT",
      `/api/admin/helps/${id}`,
      {
        active: active,
      },
      null,
      {
        "Content-Type": "application/json",
      },
      "user"
    );
  };

  const onSave = async (value: any, video_widget: any, id: number) => {
    data.list.find((x: Help) => x.id === id).value = value;
    await FetchAPI(
      "PUT",
      `/api/admin/helps/${id}`,
      {
        video_widget: video_widget,
        value: value.toString(),
      },
      null,
      {
        "Content-Type": "application/json",
      },
      "user"
    );
  };

  const addImage = () => {
    const url = window.prompt("URL");

    if (url) {
      editor?.chain().focus().setImage({ src: url }).run();
    }
  };

  useEffect(() => {
    if (editor && selected) {
      editor.commands.setContent(selected.value);
    }
  }, [selected, editor]);

  return (
    <div className={styles.helpers}>
      <Flex w={"100%"} h={"100%"}>
        <Flex w={"300px"}>
          <ScrollArea
            type="always"
            w={"100%"}
            p={"0 14px 0 0"}
            h={"calc(100dvh - 80px)"}
            scrollbars="y"
          >
            <Flex direction={"column"} gap={7} w={"100%"}>
              {data?.list?.map((x: any, key: number) => (
                <Flex
                  p={"7px 14px"}
                  bg={selected?.key === x.key ? "#e8e9ff" : "#e5e5e5"}
                  key={key}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelected(x);
                    setWidget(x.video_widget);
                  }}
                >
                  <Indicator
                    color={x.active ? "green" : "red"}
                    position="middle-start"
                    size={13}
                  >
                    <Flex align={"center"} ps={"14px"} gap={7}>
                      <Switch
                        defaultChecked={x.active}
                        size="xs"
                        onChange={(e: any) =>
                          toggleActive(e.target.checked, x.id)
                        }
                      />
                      <Text lh={"14px"} size="14px">
                        {x.title}
                      </Text>
                    </Flex>
                  </Indicator>
                </Flex>
              ))}
            </Flex>
          </ScrollArea>
        </Flex>
        <Flex
          direction={"column"}
          w={"calc(100% - 300px)"}
          h={"100%"}
          bg={"#ffffff"}
          gap={14}
        >
          <Flex p={"14px 14px 0 14px"} w={"100%"}>
            <Flex w={"100%"}>
              <Textarea
                description="Youtube відео"
                w={"100%"}
                onChange={(e: any) => {
                  if (selected) selected.video_widget = e.target.value;
                  setWidget(e.target.value);
                }}
                value={selected?.video_widget || ""}
                leftSectionWidth={60}
                leftSectionPointerEvents="auto"
                disabled={!selected}
                leftSection={
                  <Switch
                    checked={selected?.use_video}
                    size="xs"
                    onChange={(e: any) => {
                      if (selected) {
                        data.list.find(
                          (x: Help) => x.id === selected.id
                        ).use_video = e.target.checked;

                        setSelected((old: any) => {
                          return { ...old, use_video: e.target.checked };
                        });
                        FetchAPI(
                          "PUT",
                          `/api/admin/helps/${selected?.id}`,
                          {
                            use_video: e.target.checked,
                          },
                          null,
                          {
                            "Content-Type": "application/json",
                          },
                          "user"
                        );
                      }
                    }}
                  />
                }
                rightSection={
                  <ActionIcon
                    variant="filled"
                    color="teal"
                    aria-label="Save"
                    mr={20}
                    disabled={!selected}
                    onClick={() => {
                      if (selected)
                        onSave(editor?.getHTML(), video_widget, selected?.id);
                    }}
                  >
                    <IconDisc
                      style={{ width: "70%", height: "70%" }}
                      stroke={1.5}
                    />
                  </ActionIcon>
                }
              />
            </Flex>
          </Flex>
          <Flex p={"0 14px"} w={"100%"} h={"100%"}>
            <Input.Wrapper description="Текст" w={"100%"}>
              <RichTextEditor
                mt={5}
                editor={editor}
                w={"100%"}
                h={"calc(100% - 24px)"}
              >
                <RichTextEditor.Toolbar>
                  <RichTextEditor.ControlsGroup>
                    <Button
                      disabled={!!!selected}
                      type="button"
                      variant="outline"
                      w={34}
                      h={26}
                      p={0}
                      onClick={() => {
                        if (selected)
                          onSave(editor?.getHTML(), video_widget, selected?.id);
                      }}
                    >
                      <FontAwesomeIcon width={14} height={14} icon={faSave} />
                    </Button>
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Bold />
                    <RichTextEditor.Italic />
                    <RichTextEditor.Strikethrough />
                    <RichTextEditor.ClearFormatting />
                    <RichTextEditor.Highlight />
                    <RichTextEditor.Code />
                    <RichTextEditor.CodeBlock />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.H1 />
                    <RichTextEditor.H2 />
                    <RichTextEditor.H3 />
                    <RichTextEditor.H4 />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Blockquote />
                    <RichTextEditor.Hr />
                    <RichTextEditor.BulletList />
                    <RichTextEditor.OrderedList />
                    <RichTextEditor.Subscript />
                    <RichTextEditor.Superscript />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <Button
                      disabled={!!!selected}
                      type="button"
                      variant="outline"
                      color={"#ced4da"}
                      w={34}
                      h={26}
                      p={0}
                      onClick={addImage}
                    >
                      <FontAwesomeIcon
                        color={"#000"}
                        width={14}
                        height={14}
                        icon={faImage}
                      />
                    </Button>
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Link />
                    <RichTextEditor.Unlink />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.AlignLeft />
                    <RichTextEditor.AlignCenter />
                    <RichTextEditor.AlignJustify />
                    <RichTextEditor.AlignRight />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Undo />
                    <RichTextEditor.Redo />
                  </RichTextEditor.ControlsGroup>
                </RichTextEditor.Toolbar>

                <RichTextEditor.Content h={"100%"} />
              </RichTextEditor>
            </Input.Wrapper>
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

export default Helpers;
