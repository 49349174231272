import styles from "./styles.module.scss";
import {
  Box,
  Flex,
  Input,
  rem,
  Select,
  Switch,
  Text,
  Textarea,
  useMantineTheme,
} from "@mantine/core";
import { FetchAPI } from "@/hooks/fetch";
import { File, ModalButtons } from "@/components";
import { Notification } from "@/hooks/notification";
import { useEffect, useState } from "react";
import { IconCheck, IconX } from "@tabler/icons-react";

export const Employee = ({
  edit,
  closeFunction,
  onUpdate,
}: {
  edit: any;
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const theme = useMantineTheme();
  const [data, setData] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(true);
  const [fixedSalary, setFixedSalary] = useState<boolean>(true);
  const [percentSalary, setPercentSalary] = useState<boolean>(false);

  const init = async () => {
    const { data: staff_roles } = await FetchAPI(
      "GET",
      `/api/helper/staff_roles`
    );

    await setData({
      staff_roles: staff_roles.data.map(
        (role: { id: number; title: string }) => {
          return { value: role.id.toString(), label: role.title };
        }
      ),
    });

    await setLoad(false);
  };

  const submit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    if (edit) {
      await FetchAPI("PUT", `/api/helper/staff/${edit.id}`, formData, null, {
        "Content-Type": "multipart/form-data",
      });
      await Notification(
        "Співробітника успішно змінено",
        { autoClose: 3000 },
        "info"
      );
    } else {
      await FetchAPI("POST", `/api/helper/staff`, formData, null, {
        "Content-Type": "multipart/form-data",
      });
      await Notification(
        "Співробітника успішно додано",
        { autoClose: 3000 },
        "info"
      );
    }

    closeFunction();
    onUpdate();
  };

  useEffect(() => {
    (async () => {
      await setFixedSalary(!!edit?.stavka && edit?.stavka > 0);
      await setPercentSalary(!!edit?.percent && edit?.percent > 0);

      await init();
    })();
  }, []);

  return (
    <form className={styles.content} onSubmit={submit}>
      <Input.Wrapper description="Введіть ПІБ працівника">
        <Input
          type="text"
          name="title"
          required={true}
          placeholder="Назва"
          defaultValue={edit?.title}
        />
      </Input.Wrapper>
      <Input.Wrapper description="Фото">
        <File
          name="file"
          accept={"image/*"}
          defaultValue={{ ...edit?.image, tableName: "staff" }}
        />
      </Input.Wrapper>
      <Input.Wrapper description="Дата народження">
        <Input
          type="date"
          name="birthday"
          required={true}
          placeholder="Дата народження"
          defaultValue={edit?.birthday}
        />
      </Input.Wrapper>
      <Input.Wrapper description="Номер телефону">
        <Input
          type="phone"
          name="phone"
          required={true}
          placeholder="Номер телефону"
          defaultValue={edit?.phone}
        />
      </Input.Wrapper>
      {!load && (
        <Select
          description={"Роль/Посада"}
          placeholder="Роль"
          data={data.staff_roles}
          name={"role"}
          // defaultValue={edit?.role}
        />
      )}

      <Box
        bg={"#f1f3f5"}
        p={14}
        style={{
          borderRadius: 7,
        }}
      >
        <Flex direction={"column"} gap={7}>
          <Input.Wrapper description="Ставка працівнка за робочий місяць">
            <Input
              type="number"
              step="0.01"
              name="stavka"
              rightSectionPointerEvents="all"
              rightSection={
                <Switch
                  mr={20}
                  style={{ cursor: "pointer" }}
                  checked={fixedSalary}
                  color="lime"
                  thumbIcon={
                    fixedSalary ? (
                      <IconCheck
                        style={{ width: rem(12), height: rem(12) }}
                        color={theme.colors.teal[6]}
                        stroke={3}
                      />
                    ) : (
                      <IconX
                        style={{ width: rem(12), height: rem(12) }}
                        color={theme.colors.red[6]}
                        stroke={3}
                      />
                    )
                  }
                  onChange={(e: any) => setFixedSalary(e.target.checked)}
                />
              }
              required={true}
              placeholder="Ставка працівнка за робочий місяць"
              defaultValue={edit?.stavka}
              disabled={!fixedSalary}
            />
          </Input.Wrapper>
        </Flex>
      </Box>
      <Box
        bg={"#f1f3f5"}
        p={14}
        style={{
          borderRadius: 7,
        }}
      >
        <Flex direction={"column"} gap={7}>
          <Input.Wrapper description="Відсоток з продаж">
            <Input
              type="number"
              step="0.01"
              name="percent"
              required={true}
              rightSectionPointerEvents="all"
              rightSection={
                <Switch
                  mr={20}
                  style={{ cursor: "pointer" }}
                  checked={percentSalary}
                  color="lime"
                  thumbIcon={
                    percentSalary ? (
                      <IconCheck
                        style={{ width: rem(12), height: rem(12) }}
                        color={theme.colors.teal[6]}
                        stroke={3}
                      />
                    ) : (
                      <IconX
                        style={{ width: rem(12), height: rem(12) }}
                        color={theme.colors.red[6]}
                        stroke={3}
                      />
                    )
                  }
                  onChange={(e: any) => setPercentSalary(e.target.checked)}
                />
              }
              placeholder="Відсоток з продаж"
              defaultValue={edit?.percent}
              disabled={!percentSalary}
            />
          </Input.Wrapper>
        </Flex>
      </Box>
      <Input.Wrapper description="Коментар/Опис">
        <Textarea
          placeholder="Коментар"
          name="comment"
          mah={200}
          mt={5}
          defaultValue={edit?.comment}
        />
      </Input.Wrapper>
      <ModalButtons closeFunction={closeFunction} />
    </form>
  );
};
