import Modals from "..";
import styles from "./styles.module.scss";
import { FetchAPI } from "@/hooks/fetch";
import { Modal } from "@/containers/modal";
import { Notification } from "@/hooks/notification";
import { useEffect, useState } from "react";
import { File, ModalButtons, MultiSelect } from "@/components";
import { Input, SegmentedControl, Select, Textarea } from "@mantine/core";
import { postponement } from "./mock";

export const Creditor = ({
  edit,
  closeFunction,
  onUpdate,
}: {
  edit: any;
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const [data, setData] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(true);
  const [account, setAccount] = useState<boolean>(false);

  const init = async () => {
    const { data: checks } = await FetchAPI("GET", `/api/helper/checks`);

    await setData({
      checks: checks.data,
    });
    await setLoad(false);
  };

  const submit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    if (edit) {
      await FetchAPI(
        "PUT",
        `/api/helper/creditors/${edit.id}`,
        formData,
        null,
        { "Content-Type": "multipart/form-data" }
      );
      await Notification(
        "Постачальника успішно змінено",
        { autoClose: 3000 },
        "info"
      );
    } else {
      await FetchAPI("POST", `/api/helper/creditors`, formData, null, {
        "Content-Type": "multipart/form-data",
      });
      await Notification(
        "Постачальника успішно додано",
        { autoClose: 3000 },
        "info"
      );
    }

    closeFunction();
    onUpdate();
  };

  useEffect(() => {
    (async () => {
      await init();
    })();
  }, []);

  return (
    <>
      <form className={styles.content} onSubmit={submit}>
        <Input.Wrapper description="Введіть назву продукту">
          <Input
            type="text"
            name="title"
            required={true}
            placeholder="Назва"
            defaultValue={edit?.title}
          />
        </Input.Wrapper>
        <Input.Wrapper description="Зображення / Логотип">
          <File
            name="file"
            accept={"image/*"}
            defaultValue={{ ...edit?.image, tableName: "creditors" }}
          />
        </Input.Wrapper>

        <Input.Wrapper description="Податок (ПДВ)">
          <SegmentedControl
            fullWidth
            radius="xl"
            color="#228be6"
            name="pdv"
            mt={3}
            defaultValue={edit?.pdv}
            data={[
              { label: "з ПДВ", value: "pdv" },
              { label: "без ПДВ", value: "no_pdv" },
            ]}
          />
        </Input.Wrapper>

        <Input.Wrapper description="Рахунок">
          {!load && (
            <MultiSelect
              placeholder={"Рахунок"}
              name={"checks[]"}
              list={data.checks}
              hideLabel={true}
              error={{
                enabled: data?.checks?.length === 0,
                text: "Відсутні рахунки",
              }}
              defaultKey="check_id"
              addButton={() => setAccount(true)}
              defaultValues={edit?.creditors_checks}
            />
          )}
        </Input.Wrapper>

        <Select
          placeholder={"Відтермінування"}
          description="Відтермінування"
          required={true}
          data={postponement}
          name={"postponement"}
        />

        <Input.Wrapper description="Коментар/Опис">
          <Textarea
            placeholder="Коментар"
            name="comment"
            mah={200}
            mt={5}
            defaultValue={edit?.comment}
          />
        </Input.Wrapper>

        <ModalButtons closeFunction={closeFunction} />
      </form>

      <Modal
        title={"Створити Рахунок"}
        open={account}
        closeFunction={() => {
          setAccount(false);
        }}
      >
        <Modals.Check
          edit={false}
          closeFunction={() => {
            setAccount(false);
          }}
          onUpdate={init}
        />
      </Modal>
    </>
  );
};
