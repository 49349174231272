import styles from "./styles.module.scss";
import { useSession } from "@/context/session";
import { useState } from "react";
import { Table } from "../Table";
import { faDoorOpen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "@/containers/modal";
import Modals from "@/modals";
import { FetchAPI } from "@/hooks/fetch";
import { Notification } from "@/hooks/notification";
import { verify } from "crypto";

const columns: any[] = [
  {
    title: "№",
    name: "numeric",
  },
  {
    title: "Ім'я",
    name: "name",
  },
  {
    title: "Прізвище",
    name: "surname",
  },
  {
    title: "Компанія",
    name: "company",
  },
  {
    title: "Номер",
    name: "phone",
  },
  {
    title: "Пошта",
    name: "email",
  },
];

const ClientsList = ({ data }: { data: any }) => {
  const { session, initSession } = useSession();
  const [clients, setClients] = useState<{ list: any[]; count: number }>(
    data || {
      list: [],
      count: 0,
    }
  );
  const [modal, toggleModal] = useState<any>(null);
  const onUpdate = async () => {
    const { data } = await FetchAPI(
      "GET",
      `/api/admin/clients`,
      null,
      {},
      {},
      "user"
    );

    setClients(data);
  };
  const shadowLogin = async (e: any) => {
    console.log(e);

    toggleModal(e);
  };

  const remove = async (e: any) => {
    if (
      window.confirm(
        `Ви дійсно хочете видалити профіль користувача - "${e.name} ${e.surname}" ?`
      )
    ) {
      await FetchAPI(
        "DELETE",
        `/api/admin/clients/${e.id}`,
        {},
        null,
        {
          "Content-Type": "application/json",
        },
        "user"
      );

      await Notification(
        `Користувача успішно виделено`,
        { autoClose: 3000 },
        "success"
      );

      await onUpdate();
    }
  };
  const active = async (e: any) => {
    if (
      window.confirm(
        `Ви дійсно хочете активувати профіль користувача - "${e.name} ${e.surname}" ? \n\nКомпанія:${e.company}\nEmail: ${e.email}`
      )
    ) {
      await FetchAPI(
        "PUT",
        `/api/admin/clients/${e.id}`,
        { verify: true },
        {},
        {
          "Content-Type": "application/json",
        },
        "user"
      );

      await Notification(
        `Профіль користувача ${e.name} ${e.surname} активовано!`,
        { autoClose: 3000 },
        "success"
      );

      await onUpdate();
    }
  };
  const inactive = async (e: any) => {
    if (
      window.confirm(
        `Ви дійсно хочете деактивувати профіль користувача - "${e.name} ${e.surname}" ? \n\nКомпанія:${e.company}\nEmail: ${e.email}`
      )
    ) {
      await FetchAPI(
        "PUT",
        `/api/admin/clients/${e.id}`,
        { verify: false },
        null,
        {
          "Content-Type": "application/json",
        },
        "user"
      );

      await Notification(
        `Профіль користувача ${e.name} ${e.surname} деактивовано!`,
        { autoClose: 3000 },
        "success"
      );

      await onUpdate();
    }
  };

  return (
    <div className={styles.businessData}>
      <Table
        role_param="clients"
        tableName={"clients"}
        columns={columns}
        values={clients?.list || []}
        counts={clients?.count || 0}
        titleTable={"Клієнти"}
        search={(val: any) => {}}
        customButtons={[
          {
            variant: "filled",
            type: "button",
            title: "Активувати",
            onClick: (e: any) => {
              active(e);
            },
            alternative: {
              field: "verify",
              value: true,
              title: "Деактивувати",
              color: "red",
              onClick: (e: any) => {
                inactive(e);
              },
            },
          },
        ]}
        customs={{
          list: [
            {
              name: "score",
              caption: "Увійти",
              icon: faDoorOpen,
              function: shadowLogin,
            },
            {
              name: "active",
              caption: "Видалити",
              icon: faTrash,
              function: remove,
            },
          ],
        }}
      />
      <Modal
        title={modal ? modal.id : "..."}
        open={!!modal}
        closeFunction={() => {
          toggleModal(null);
        }}
      >
        <Modals.OpenClient
          data={modal}
          closeFunction={() => {
            toggleModal(null);
          }}
        />
      </Modal>
    </div>
  );
};

export default ClientsList;
