import { FetchAPI } from "@/hooks/fetch";

export const init = async (
  setData: (d: any) => void,
  setStaffs: (d: any) => void,
  setLoad: (v: boolean) => void
) => {
  const { data: products } = await FetchAPI("GET", `/api/helper/product`);
  const { data: not_formals } = await FetchAPI("GET", `/api/helper/not_formal`);
  const { data: staffs } = await FetchAPI("GET", `/api/helper/staff`);

  await setStaffs(staffs.data);
  await setData({
    products: [
      ...products.data.map((x: any) => {
        return { ...x, title: `(Продукт) ${x.title}` };
      }),
      ...not_formals.data.map((x: any) => {
        return { ...x, title: `(Напівфабрикат) ${x.title}` };
      }),
    ],
  });

  setTimeout(() => {
    setLoad(false);
  }, 1000);
};
