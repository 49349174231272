import styles from "./styles.module.scss";
import { Button, Flex } from "@mantine/core";
import { FetchAPIGl } from "@/hooks/fetch-global";
import { File, Input, ModalButtons, Textarea } from "@/components";
import { Notification } from "@/hooks/notification";
import { useRouter } from "next/router";
import { useState } from "react";

export const AddBusiness = ({
  type,
  data,
  closeFunction,
  onUpdate,
}: {
  type: "add" | "edit";
  data?: any;
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const router = useRouter();
  const [status, setStatus] = useState<"add" | "completed">("add");
  const [staff, setStaff] = useState<any>(null);
  const submit = async (e: any) => {
    e.preventDefault();
    const formData: any = new FormData(e.target);
    if (type === "edit") {
      formData.append("id", data.id as string);
    }
    const { data: res } = await FetchAPIGl(
      type === "add" ? "POST" : "PUT",
      `/api/institutions`,
      formData,
      null
    );

    if (type === "edit") {
      await Notification(
        "Виробництво успішно оновлено",
        { autoClose: 3000 },
        "info"
      );
      await onUpdate();
      await closeFunction();
    }
    if (type === "add") {
      await Notification(
        "Виробництво успішно створено",
        { autoClose: 3000 },
        "info"
      );
      await setStaff(res);
      await setStatus("completed");
      await onUpdate();
    }
  };

  const goToBusiness = (pin: number, token: string) => {
    router.push(`/workspace/init?token=${token}&pin=${pin}`);
  };

  if (type === "add") {
    if (status === "add")
      return (
        <form className={styles.content} onSubmit={submit}>
          <File name="file" accept={"image/*"} />
          <Input type="text" name="title" required={true} placeholder="Назва" />
          <Textarea placeholder="Коментар" name="description" />
          <ModalButtons closeFunction={closeFunction} />
        </form>
      );

    if (status === "completed")
      return (
        <div className={styles.content}>
          <p className={styles.information}>
            Ви успішно створили нове виробництво.
          </p>
          <Input
            type="text"
            readOnly={true}
            required={true}
            placeholder="Ваш PIN код адміністратора"
            value={staff.pin || ""}
          />
          <div className={styles.buttons}>
            <Button
              type="button"
              variant="gradient"
              onClick={() => goToBusiness(staff.pin, staff.institution)}
            >
              Увійти
            </Button>
            <Flex w={"100%"} gap={7} justify={"space-between"}>
              <Button
                type="button"
                variant="light"
                onClick={closeFunction}
                w={"50%"}
              >
                Закрити
              </Button>
              <Button
                type="button"
                variant="outline"
                w={"50%"}
                onClick={() => setStatus("add")}
              >
                Створити ще
              </Button>
            </Flex>
          </div>
        </div>
      );
  } else {
    return (
      <form className={styles.content} onSubmit={submit}>
        <File
          name="file"
          accept={"image/*"}
          defaultValue={{ filename: data?.image, tableName: "institutions" }}
        />
        <Input
          type="text"
          name="title"
          required={true}
          placeholder="Назва"
          defauleValue={data?.title}
        />
        <Textarea
          placeholder="Коментар"
          name="description"
          defauleValue={data?.description}
        />
        <ModalButtons closeFunction={closeFunction} />
      </form>
    );
  }

  return null;
};
