export const Logotype = () => {
  return (
    <svg
      width="652"
      height="211"
      viewBox="0 0 652 211"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="a-animate"
        d="M210.184 19.4C208.696 19.4 207.456 19.896 206.464 20.888C205.472 22.0453 204.976 23.2853 204.976 24.608C204.976 25.4347 205.059 26.1786 205.224 26.84L264.248 186.304C264.744 188.123 265.736 189.693 267.224 191.016C268.712 192.339 270.779 193 273.424 193H305.168C307.813 193 309.88 192.339 311.368 191.016C312.856 189.693 313.848 188.123 314.344 186.304L373.368 26.84C373.533 26.1786 373.616 25.4347 373.616 24.608C373.616 23.2853 373.12 22.0453 372.128 20.888C371.136 19.896 369.896 19.4 368.408 19.4H342.616C340.136 19.4 338.317 19.9787 337.16 21.136C336.003 22.2933 335.259 23.368 334.928 24.36L324.264 52.136H254.328L243.664 24.36C243.333 23.368 242.589 22.2933 241.432 21.136C240.275 19.9787 238.456 19.4 235.976 19.4H210.184ZM263.504 84.128H314.84L289.296 155.552L263.504 84.128Z"
        fill="#585D8F"
      />
      <path
        d="M404.2 192.6C402.381 192.6 400.893 192.021 399.736 190.864C398.579 189.707 398 188.219 398 186.4V25.2C398 23.3813 398.579 21.8933 399.736 20.736C400.893 19.5787 402.381 19 404.2 19L514.808 19C516.627 19 518.115 19.5787 519.272 20.736C520.429 21.8933 521.008 23.3813 521.008 25.2V46.28C521.008 48.0987 520.429 49.5867 519.272 50.744C518.115 51.9013 516.627 52.48 514.808 52.48H436.688V94.392H509.848C511.667 94.392 513.155 94.9707 514.312 96.128C515.469 97.2853 516.048 98.7733 516.048 100.592V121.672C516.048 123.325 515.469 124.731 514.312 125.888C513.155 127.045 511.667 127.624 509.848 127.624H436.688V186.4C436.688 188.219 436.109 189.707 434.952 190.864C433.795 192.021 432.307 192.6 430.488 192.6H404.2Z"
        fill="#5661C6"
      />
      <path
        d="M553.552 193C551.733 193 550.245 192.421 549.088 191.264C547.931 190.107 547.352 188.619 547.352 186.8V25.6C547.352 23.7814 547.931 22.2934 549.088 21.136C550.245 19.9787 551.733 19.4 553.552 19.4H581.576C583.395 19.4 584.883 19.9787 586.04 21.136C587.197 22.2934 587.776 23.7814 587.776 25.6V186.8C587.776 188.619 587.197 190.107 586.04 191.264C584.883 192.421 583.395 193 581.576 193H553.552Z"
        fill="#5661C6"
      />
      <rect width="196" height="211" rx="23" fill="#5661C6" />
      <path
        d="M21.2 192.6C19.5467 192.6 18.0587 192.021 16.736 190.864C15.5787 189.707 15 188.219 15 186.4V25.2C15 23.3813 15.5787 21.8933 16.736 20.736C18.0587 19.5787 19.5467 19 21.2 19H44.264C46.744 19 48.5627 19.6613 49.72 20.984C51.0427 22.3067 51.952 23.2987 52.448 23.96L98.08 108.032L144.208 23.96C144.539 23.2987 145.283 22.3067 146.44 20.984C147.763 19.6613 149.664 19 152.144 19H175.208C177.027 19 178.515 19.5787 179.672 20.736C180.829 21.8933 181.408 23.3813 181.408 25.2V186.4C181.408 188.219 180.829 189.707 179.672 190.864C178.515 192.021 177.027 192.6 175.208 192.6H149.912C148.259 192.6 146.853 192.021 145.696 190.864C144.539 189.707 143.96 188.219 143.96 186.4V86.208L112.464 145.232C111.637 146.72 110.563 148.043 109.24 149.2C107.917 150.357 106.181 150.936 104.032 150.936H92.376C90.2267 150.936 88.4907 150.357 87.168 149.2C85.8453 148.043 84.7707 146.72 83.944 145.232L52.448 86.208V186.4C52.448 188.219 51.8693 189.707 50.712 190.864C49.5547 192.021 48.1493 192.6 46.496 192.6H21.2Z"
        fill="white"
      />
      <path
        d="M617.336 193C615.517 193 614.029 192.421 612.872 191.264C611.715 190.107 611.136 188.619 611.136 186.8V159.024C611.136 157.205 611.715 155.717 612.872 154.56C614.029 153.403 615.517 152.824 617.336 152.824H645.112C646.931 152.824 648.419 153.403 649.576 154.56C650.733 155.717 651.312 157.205 651.312 159.024V186.8C651.312 188.619 650.733 190.107 649.576 191.264C648.419 192.421 646.931 193 645.112 193H617.336Z"
        fill="#ADB925"
      />
    </svg>
  );
};
