import { AccountingOfFunds } from "./AddAccountingOfFunds";
import { AccountingOfRawMaterials } from "./AddAccountingOfRawMaterials";
import { AccountReport } from "./AccountReport";
import { AccuralModal } from "./AccuralModal";
import { AddAccountingOfNotFormals } from "./AddAccountingOfNotFormals";
import { AddBooking } from "./AddBooking";
import { AddBusiness } from "./AddBusiness";
import { AddStorageSection } from "./AddStorageSection";
import { Category } from "./AddCategory";
import { Check } from "./AddCkeck";
import { Creditor } from "./AddCreditor";
import { Debitor } from "./AddDebitor";
import { Employee } from "./AddEmployee";
import { ItemsOfIncome } from "./AddItemsOfIncome";
import { Materials } from "./AddMaterials";
import { MoveStorageItem } from "./MoveStorageItem";
import { NF } from "./AddNF";
import { OpenClient } from "./OpenClient";
import { Package } from "./AddPackage";
import { PaysList } from "./PaysList";
import { ProductAccount } from "./AddProductAccount";
import { ProductionAccounting } from "./AddProductionAccounting";
import { Products } from "./AddProducts";
import { Role } from "./Role";
import { SaleModal } from "./SaleModal";
import { SalesAccounting } from "./SalesAccounting";
import { ViewProduct } from "./ViewProduct";

export default {
  Materials,
  Category,

  AccountReport,
  OpenClient,
  ViewProduct,
  AccuralModal,
  Role,
  Products,
  Package,
  NF,
  ItemsOfIncome,
  Employee,
  Debitor,
  Creditor,
  Check,
  ProductionAccounting,
  AccountingOfRawMaterials,
  AddAccountingOfNotFormals,
  ProductAccount,
  SalesAccounting,
  AccountingOfFunds,
  AddBusiness,
  AddStorageSection,
  MoveStorageItem,
  AddBooking,
  PaysList,
  SaleModal,
};
