export const parseSize = (
  size: "kg" | "st" | "l",
  type: "minimal" | "full" = "full"
) => {
  if (type === "minimal")
    switch (size) {
      case "kg":
        return "Гр";
      case "st":
        return "Шт";
      case "l":
        return "Мл";
      default:
        return "...";
    }

  if (type === "full")
    switch (size) {
      case "kg":
        return "КГ";
      case "st":
        return "ШТ";
      case "l":
        return "Л";
      default:
        return "...";
    }

  return "...";
};
