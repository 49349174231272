export const postponement = [
  {
    label: "День",
    value: "1",
  },
  {
    label: "Тиждень",
    value: "7",
  },
  {
    label: "Місяць",
    value: "30",
  },
  {
    label: "Квартал",
    value: "90",
  },
  {
    label: "Пів року",
    value: "180",
  },
  {
    label: "Рік",
    value: "365",
  },
  {
    label: "Вказувати при продажі",
    value: "0",
  },
];
