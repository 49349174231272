import styles from "./styles.module.scss";
import { FetchAPI } from "@/hooks/fetch";
import { File, ModalButtons, RadioGroup, Select, Textarea } from "@/components";
import { useEffect, useState } from "react";
import { Modal } from "@/containers/modal";
import Modals from "..";
import { Input, SegmentedControl } from "@mantine/core";

export const Package = ({
  edit,
  closeFunction,
  onUpdate,
}: {
  edit: any;
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const [data, setData] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(true);
  const [counteragent, setCounteragent] = useState<boolean>(false);

  const init = async () => {
    const { data: creditors } = await FetchAPI("GET", `/api/helper/creditors`);
    const { data: debitors } = await FetchAPI("GET", `/api/helper/debitors`);

    await setData({
      counteragents: [...creditors.data, ...debitors.data],
    });
    await setLoad(false);
  };

  const submit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    if (edit)
      await FetchAPI("PUT", `/api/helper/package/${edit.id}`, formData, null, {
        "Content-Type": "multipart/form-data",
      });
    else
      await FetchAPI("POST", `/api/helper/package`, formData, null, {
        "Content-Type": "multipart/form-data",
      });

    closeFunction();
    onUpdate();
  };

  useEffect(() => {
    (async () => {
      await init();
    })();
  }, []);

  return (
    <>
      <form className={styles.content} onSubmit={submit}>
        <Input
          type="text"
          name="title"
          required={true}
          placeholder="Назва"
          defaultValue={edit?.title}
        />
        <File
          name="file"
          accept={"image/*"}
          defaultValue={{ ...edit?.image, tableName: "package" }}
        />
        <Input.Wrapper description="Одиниця виміру">
          <SegmentedControl
            fullWidth
            radius="xl"
            color="#228be6"
            name="size"
            defaultValue={edit?.size}
            data={[
              { label: "КГ", value: "kg" },
              { label: "ШТ", value: "st" },
              { label: "Л", value: "l" },
            ]}
          />
        </Input.Wrapper>

        {!load && (
          <Select
            required={true}
            placeholder="Постачальник"
            list={data.counteragents}
            name={"counteragent"}
            addButton={() => setCounteragent(true)}
            error={{
              enabled: data?.counteragents?.length === 0,
              text: "Відсутні Постачальники",
            }}
            defaultSelect={edit?.counteragent}
          />
        )}

        <Textarea
          placeholder="Коментар"
          name="comment"
          defauleValue={edit?.comment}
        />
        <ModalButtons closeFunction={closeFunction} />
      </form>
      <Modal
        title={"Створити постачальника"}
        open={counteragent}
        closeFunction={() => {
          setCounteragent(false);
        }}
      >
        <Modals.Creditor
          edit={false}
          closeFunction={() => {
            setCounteragent(false);
          }}
          onUpdate={init}
        />
      </Modal>
    </>
  );
};
