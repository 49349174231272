import { ActionIcon, Button } from "@mantine/core";
import styles from "./styles.module.scss";
import { IconPlus } from "@tabler/icons-react";

interface SelectInterface {
  name?: string;
  list: any[];
  required?: boolean;
  placeholder?: string;
  defaultSelect?: number | string;
  hideLabel?: boolean;
  customValueKey?: string | null | any;
  disabled?: boolean;
  error?: {
    enabled: boolean;
    text: string;
  };
  addButton?: () => void;
  onChange?: (e: any) => void;
}

const Select = ({
  hideLabel = false,
  required = false,
  name,
  customValueKey = null,
  list = [],
  disabled,
  error,
  placeholder,
  defaultSelect = 0,
  addButton,
  onChange,
}: SelectInterface) => {
  return (
    <div
      className={`${styles.selectBox} ${error?.enabled ? styles.error : ""}`}
    >
      {placeholder && !hideLabel && (
        <span className={styles.placeSpan}>{placeholder}</span>
      )}
      <div className={styles.selectContainer}>
        <select
          required={required}
          className={`${styles.select} ${disabled ? styles.disabled : ""}`}
          name={name}
          defaultValue={defaultSelect || 0}
          onChange={onChange}
        >
          {error?.enabled && (
            <option disabled value={0}>
              {error.text}
            </option>
          )}
          {!error?.enabled && placeholder && (
            <option disabled value={0}>
              Виберіть: {placeholder}
            </option>
          )}
          {list?.map(
            (
              option:
                | { title: string; id: number | string; product_id?: number }
                | any
            ) => (
              <option key={option.id} value={option[customValueKey || "id"]}>
                {option.title}
              </option>
            )
          )}
        </select>

        {addButton && (
          <ActionIcon
            variant="light"
            color="indigo"
            onClick={addButton}
            aria-label="Додати"
            size="lg"
          >
            <IconPlus style={{ width: "70%", height: "70%" }} stroke={1.5} />
          </ActionIcon>
        )}
      </div>
    </div>
  );
};

export default Select;
