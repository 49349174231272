import { useEffect, useState } from "react";
import { Portal } from '@mantine/core';

const Portals = ({ children }: { children: any }) => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);

        return () => setMounted(false);
    }, []);

    return mounted
        ? <Portal>{children}</Portal>
        : null;
};

export default Portals;
