import { toJpeg } from "html-to-image";

const saveChartAsImage = (
  svgElement: any,
  name: string,
  callback: () => void
) => {
  setTimeout(() => {
    toJpeg(svgElement, { cacheBust: false, backgroundColor: "#fff" })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `${name}_${Date.now()}.png`;
        link.href = dataUrl;
        link.click();
        callback();
      })
      .catch((err) => {
        console.log(err);
        callback();
      });
  }, 500); // Задержка в 500 мс
};

export { saveChartAsImage };
