import Modals from "..";
import styles from "./styles.module.scss";
import { FetchAPI } from "@/hooks/fetch";
import { File, ModalButtons, MultiSelect } from "@/components";
import { Modal } from "@/containers/modal";
import { Notification } from "@/hooks/notification";
import { postponement } from "./mock";
import { useEffect, useState } from "react";
import {
  ActionIcon,
  Flex,
  HoverCard,
  Input,
  List,
  rem,
  ScrollArea,
  SegmentedControl,
  Select,
  Switch,
  Text,
  Textarea,
  ThemeIcon,
} from "@mantine/core";
import { IconCircleCheck, IconHelp, IconMeat } from "@tabler/icons-react";

export const Debitor = ({
  edit,
  closeFunction,
  onUpdate,
}: {
  edit: any;
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const [data, setData] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(true);
  const [updator, setUpdate] = useState<boolean>(false);
  const [selectType, setSelectType] = useState<string>("retail");
  const [account, setAccount] = useState<boolean>(false);
  const [product, setProduct] = useState<boolean>(false);
  const [allProducts, setAllProducts] = useState<boolean>(
    edit?.all_products || false
  );

  const init = async () => {
    const { data: checks } = await FetchAPI("GET", `/api/helper/checks`);
    const { data: products } = await FetchAPI("GET", `/api/helper/product`);

    await setData({
      checks: checks.data,
      products: products.data,
    });
    await setLoad(false);
  };

  const submit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    if (edit) {
      await FetchAPI("PUT", `/api/helper/debitors/${edit.id}`, formData, null, {
        "Content-Type": "multipart/form-data",
      });
      await Notification(
        "Покупця успішно змінено",
        { autoClose: 3000 },
        "info"
      );
    } else {
      await FetchAPI("POST", `/api/helper/debitors`, formData, null, {
        "Content-Type": "multipart/form-data",
      });
      await Notification("Покупця успішно додано", { autoClose: 3000 }, "info");
    }

    closeFunction();
    onUpdate();
  };

  useEffect(() => {
    (async () => {
      await init();
    })();
  }, []);

  return (
    <>
      <form className={styles.content} onSubmit={submit}>
        <Input.Wrapper description="Введіть назву продукту">
          <Input
            type="text"
            name="title"
            required={true}
            placeholder="Назва"
            defaultValue={edit?.title}
          />
        </Input.Wrapper>
        <Input.Wrapper description="Зображення / Логотип">
          <File
            name="file"
            accept={"image/*"}
            defaultValue={{ ...edit?.image, tableName: "debitors" }}
          />
        </Input.Wrapper>

        <Input.Wrapper description="Податок (ПДВ)">
          <SegmentedControl
            fullWidth
            radius="xl"
            color="#228be6"
            name="pdv"
            mt={3}
            defaultValue={edit?.pdv}
            data={[
              { label: "з ПДВ", value: "pdv" },
              { label: "без ПДВ", value: "no_pdv" },
            ]}
          />
        </Input.Wrapper>

        {!load && (
          <MultiSelect
            placeholder={"Рахунок"}
            name={"checks[]"}
            list={data.checks}
            addButton={() => setAccount(true)}
            isDelete={true}
            error={{
              enabled: data?.checks?.length === 0,
              text: "Відсутні рахунки",
            }}
            defaultKey="check_id"
            defaultValues={edit?.debitors_checks}
          />
        )}

        <Select
          placeholder={"Відтермінування"}
          description="Відтермінування"
          required={true}
          data={postponement}
          name={"postponement"}
        />

        <Input.Wrapper description="Податок (ПДВ)">
          <SegmentedControl
            fullWidth
            radius="xl"
            color="#228be6"
            name="pdv"
            mt={3}
            defaultValue={edit?.pdv}
            data={[
              {
                label: "Ритейл",
                value: "retail",
              },
              {
                label: "Дрібний опт",
                value: "wholesale",
              },
              {
                label: "Роздріб",
                value: "small_price",
              },
            ]}
          />
        </Input.Wrapper>

        {!load && (
          <>
            <Switch
              labelPosition="right"
              color="lightblue"
              name="all_products"
              label={"Всі товари"}
              defaultChecked={edit?.all_products}
              onChange={async (e: any) => {
                setAllProducts(e.target.checked);
              }}
            />

            {allProducts ? (
              <Input.Wrapper description="Товари">
                <Flex
                  w={"100%"}
                  h={36}
                  bg="#f1f3f5"
                  mt={5}
                  style={{ borderRadius: 7 }}
                  align={"center"}
                  justify={"space-between"}
                  p={"0 14px"}
                >
                  <Text size="14px">
                    Вибрано: {data.products.length} товарів
                  </Text>

                  <HoverCard width={280} shadow="md">
                    <HoverCard.Target>
                      <ActionIcon variant="transparent">
                        <IconHelp
                          style={{ width: "70%", height: "70%" }}
                          stroke={1.5}
                        />
                      </ActionIcon>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                      <ScrollArea
                        type="always"
                        w={"100%"}
                        p={"0 14px 0 0"}
                        mah={300}
                        scrollbars="y"
                      >
                        <List
                          spacing="xs"
                          size="sm"
                          center
                          icon={
                            <ThemeIcon color="blue" size={24} radius="xl">
                              <IconMeat
                                style={{ width: rem(16), height: rem(16) }}
                              />
                            </ThemeIcon>
                          }
                        >
                          {data?.products?.map((product: any) => (
                            <List.Item key={product.id}>
                              {product.title}
                            </List.Item>
                          ))}
                        </List>
                      </ScrollArea>
                    </HoverCard.Dropdown>
                  </HoverCard>
                </Flex>
              </Input.Wrapper>
            ) : (
              <Input.Wrapper description="Товари">
                <MultiSelect
                  placeholder={"Товари"}
                  name={"products[ids]"}
                  list={data.products}
                  hideLabel={true}
                  disabled={allProducts}
                  addButton={() => setProduct(true)}
                  subInputs={[
                    {
                      type: "number",
                      name: `products[prices]`,
                      readOnly: false,
                      defaltField: selectType,
                      defValue: !!edit,
                      subSizeCustom: "грн",
                      updator,
                      isDelete: true,
                    },
                    {
                      type: "number",
                      name: `products[prices]`,
                      readOnly: false,
                      defaltField: selectType,
                      defValue: !!edit,
                      updator,
                      isDelete: true,
                    },
                  ]}
                  isDelete={true}
                  error={{
                    enabled: data?.products?.length === 0,
                    text: "Відсутні продукти",
                  }}
                  defaultKey="product_id"
                  defaultValues={edit?.debitors_products}
                />
              </Input.Wrapper>
            )}
          </>
        )}

        <Input.Wrapper description="Коментар/Опис">
          <Textarea
            placeholder="Коментар"
            name="comment"
            mah={200}
            mt={5}
            defaultValue={edit?.comment}
          />
        </Input.Wrapper>

        <ModalButtons closeFunction={closeFunction} />
      </form>
      <Modal
        title={"Створити Рахунок"}
        open={account}
        closeFunction={() => {
          setAccount(false);
        }}
      >
        <Modals.Check
          edit={false}
          closeFunction={() => {
            setAccount(false);
          }}
          onUpdate={init}
        />
      </Modal>
      <Modal
        title={"Створити продукт"}
        open={product}
        closeFunction={() => {
          setProduct(false);
        }}
      >
        <Modals.Products
          edit={false}
          closeFunction={() => {
            setProduct(false);
          }}
          onUpdate={init}
        />
      </Modal>
    </>
  );
};
