import { Button } from "@mantine/core";
import styles from "./styles.module.scss";

export const ModalButtons = ({
  submit = true,
  disabled = false,
  load = false,
  closeFunction,
}: {
  submit?: boolean;
  disabled?: boolean;
  load?: boolean;
  closeFunction: () => void;
}) => {
  return (
    <div className={styles.containerButton}>
      <Button type="button" variant="light" onClick={closeFunction}>
        Відміна
      </Button>
      <Button type="submit" disabled={disabled} loading={load}>
        Зберегти
      </Button>
    </div>
  );
};
