import styles from "./styles.module.scss";
import { FetchAPI } from "@/hooks/fetch";
import { File, ModalButtons } from "@/components";
import { Notification } from "@/hooks/notification";
import { Input, SegmentedControl, Textarea } from "@mantine/core";

export const Category = ({
  edit,
  closeFunction,
  onUpdate,
}: {
  edit: any;
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const submit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    if (edit) {
      await FetchAPI(
        "PUT",
        `/api/helper/categories/${edit.id}`,
        formData,
        null,
        { "Content-Type": "multipart/form-data" }
      );
      await Notification("Категорію створено", { autoClose: 3000 }, "info");
    } else
      await FetchAPI("POST", `/api/helper/categories`, formData, null, {
        "Content-Type": "multipart/form-data",
      });

    closeFunction();
    onUpdate();
  };

  return (
    <form className={styles.content} onSubmit={submit}>
      <Input.Wrapper description="Назва категорії">
        <Input
          type="text"
          name="title"
          required={true}
          placeholder="Назва"
          defaultValue={edit?.title}
        />
      </Input.Wrapper>
      <Input.Wrapper description="Зображення">
        <File
          name="file"
          accept={"image/*"}
          defaultValue={{ ...edit?.image, tableName: "categories" }}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Одиниця виміру">
        <SegmentedControl
          fullWidth
          radius="xl"
          color="#228be6"
          name="size"
          defaultValue={edit?.size}
          data={[
            { label: "КГ", value: "kg" },
            { label: "ШТ", value: "st" },
            { label: "Л", value: "l" },
          ]}
        />
      </Input.Wrapper>

      <Input.Wrapper description="Коментар/Опис">
        <Textarea
          placeholder="Коментар"
          name="comment"
          mt={5}
          mah={200}
          defaultValue={edit?.comment}
        />
      </Input.Wrapper>

      <ModalButtons closeFunction={closeFunction} />
    </form>
  );
};
