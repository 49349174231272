import styles from "./styles.module.scss";

interface RadioElement {
  title: string;
  value: string | number;
}
interface RadioGroupInterface {
  name?: string;
  list: RadioElement[];
  defaultSelect?: string | null;
  placeholder?: string;
  onChange?: (valie: string) => void;
}

const RadioGroup = ({
  list,
  name,
  placeholder,
  defaultSelect = null,
  onChange,
}: RadioGroupInterface) => {
  const unique = `UI_${Date.now()}__`;
  return (
    <div className={styles.radioGroup}>
      {placeholder && <div className={styles.title}>{placeholder}</div>}
      <div className={styles.radioGroupContent}>
        {list.map((radio: RadioElement, index: number) => (
          <div className={styles.radio} key={index}>
            <input
              type="radio"
              className={styles.radioInput}
              name={name}
              value={radio.value}
              defaultChecked={
                defaultSelect ? defaultSelect === radio.value : index === 0
              }
              id={unique + name + "_" + index}
              autoComplete="off"
              onChange={(e: any) => onChange && onChange(e.target.value)}
            />
            <label
              className={styles.radioButton}
              htmlFor={unique + name + "_" + index}
            >
              {radio.title}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
export default RadioGroup;
