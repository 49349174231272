import Link from "next/link";
import styles from "./styles.module.scss";
import { Accordion } from "../Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Logotype } from "../Logotype";
import { useRouter } from "next/router";
import { useStaff } from "@/context/staff";
import {
  faBank,
  faBarcode,
  faBoxesPacking,
  faBreadSlice,
  faBurger,
  faCarrot,
  faCartFlatbed,
  faCartShopping,
  faCashRegister,
  faChartSimple,
  faCoins,
  faCookieBite,
  faDiagramProject,
  faFileInvoice,
  faHandHoldingDollar,
  faHistory,
  faHome,
  faLayerGroup,
  faMagnifyingGlassDollar,
  faMoneyBillTransfer,
  faMoneyBills,
  faPaste,
  faPlateWheat,
  faScaleUnbalanced,
  faTruckLoading,
  faUserLock,
  faUserShield,
  faUserTie,
  faWallet,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";

export const Navigation = ({
  isOpenList,
}: {
  isOpenList?:
    | "products"
    | "accounting"
    | "finance"
    | "helpers"
    | "analitic"
    | "reports"
    | null;
}) => {
  const { pathname } = useRouter();
  const { session } = useStaff();

  const checkView = (page: string) => {
    if (session?.role) {
      return session?.role[page] === "view" || session?.role[page] === "edit";
    }
    return false;
  };

  return (
    <section className={styles.navigation}>
      <div className={styles.logo}>
        <Logotype />
      </div>
      <nav className={styles.navsList}>
        <Link href={"/workspace/"}>
          <div className={styles.navLink}>
            <FontAwesomeIcon icon={faHome} />
            <span>Головна</span>
          </div>
        </Link>
        <Link href={"/workspace/logs"}>
          <div className={styles.navLink}>
            <FontAwesomeIcon icon={faHistory} />
            <span>Історія</span>
          </div>
        </Link>

        <Accordion title={"Товари"} defaultOpen={isOpenList === "products"}>
          <div className={styles.listLink}>
            {checkView("sales_accounting") && (
              <Link href={"/workspace/accounting/sales-accounting"}>
                <div
                  className={
                    pathname === "/workspace/accounting/sales-accounting"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faMoneyBillTransfer} />
                  <span className={styles.textNavigationList}>
                    Облік продажів (Вхідні замовлення)
                  </span>
                </div>
              </Link>
            )}

            {checkView("storage") && (
              <Link href={"/workspace/control/storage"}>
                <div
                  className={
                    pathname === "/workspace/control/storage"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faWarehouse} />
                  <span className={styles.textNavigationList}>Склад</span>
                </div>
              </Link>
            )}

            {checkView("booking") && (
              <Link href={"/workspace/control/booking"}>
                <div
                  className={
                    pathname === "/workspace/control/booking"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faUserLock} />
                  <span className={styles.textNavigationList}>Бронування</span>
                </div>
              </Link>
            )}

            <Link
              href={"/workspace/control/invertory"}
              style={{ pointerEvents: "none", opacity: 0.7 }}
            >
              <div
                className={
                  pathname === "/workspace/control/incertory"
                    ? styles.navLinkActive
                    : styles.navLink
                }
              >
                <FontAwesomeIcon icon={faBoxesPacking} />
                <span className={styles.textNavigationList}>
                  Інвентаризацыя (Скоро буде)
                </span>
              </div>
            </Link>
          </div>
        </Accordion>
        <Accordion
          title={"Виробництво"}
          defaultOpen={isOpenList === "accounting"}
        >
          <div className={styles.listLink}>
            {checkView("production_accounting") && (
              <Link href={"/workspace/accounting/production-accounting"}>
                <div
                  className={
                    pathname === "/workspace/accounting/production-accounting"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faDiagramProject} />
                  <span className={styles.textNavigationList}>
                    Облік виробництва
                  </span>
                </div>
              </Link>
            )}

            {checkView("product_accounting") && (
              <Link href={"/workspace/accounting/product-accounting"}>
                <div
                  className={
                    pathname === "/workspace/accounting/product-accounting"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faBarcode} />
                  <span className={styles.textNavigationList}>
                    Облік товарів
                  </span>
                </div>
              </Link>
            )}

            {checkView("accounting_of_raw_materials") && (
              <Link href={"/workspace/accounting/accounting-of-raw-materials"}>
                <div
                  className={
                    pathname ===
                    "/workspace/accounting/accounting-of-raw-materials"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faPaste} />
                  <span className={styles.textNavigationList}>
                    Облік Сировини
                  </span>
                </div>
              </Link>
            )}

            {/* {checkView("accounting_of_raw_not_formals") && ( */}
            <Link href={"/workspace/accounting/accounting-of-raw-not-formals"}>
              <div
                className={
                  pathname ===
                  "/workspace/accounting/accounting-of-raw-not-formals"
                    ? styles.navLinkActive
                    : styles.navLink
                }
              >
                <FontAwesomeIcon icon={faCookieBite} />
                <span className={styles.textNavigationList}>
                  Облік напівфабрикатів
                </span>
              </div>
            </Link>

            <Link
              href={"/workspace/control/booking"}
              style={{ pointerEvents: "none", opacity: 0.7 }}
            >
              <div
                className={
                  pathname === "/workspace/control/booking"
                    ? styles.navLinkActive
                    : styles.navLink
                }
              >
                <FontAwesomeIcon icon={faPlateWheat} />
                <span className={styles.textNavigationList}>
                  Запит виробництво (Скоро буде)
                </span>
              </div>
            </Link>
          </div>
        </Accordion>

        <Accordion title={"Фінанси"} defaultOpen={isOpenList === "finance"}>
          <div className={styles.listLink}>
            <Link href={"/workspace/accounting/accounting-state"}>
              <div
                className={
                  pathname === "/workspace/accounting/accounting-state"
                    ? styles.navLinkActive
                    : styles.navLink
                }
              >
                <FontAwesomeIcon icon={faWallet} />
                <span className={styles.textNavigationList}>Стан рахунків</span>
              </div>
            </Link>

            {checkView("accounting_of_funds") && (
              <Link href={"/workspace/accounting/accounting-of-funds"}>
                <div
                  className={
                    pathname === "/workspace/accounting/accounting-of-funds"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faScaleUnbalanced} />
                  <span className={styles.textNavigationList}>
                    Облік коштів
                  </span>
                </div>
              </Link>
            )}

            {checkView("wage_control") && (
              <Link href={"/workspace/control/wage-control"}>
                <div
                  className={
                    pathname === "/workspace/control/wage-control"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faHandHoldingDollar} />
                  <span className={styles.textNavigationList}>
                    Контроль заробітньої плати
                  </span>
                </div>
              </Link>
            )}

            <Link
              href={"/workspace/control/ss"}
              style={{ pointerEvents: "none", opacity: 0.7 }}
            >
              <div
                className={
                  pathname === "/workspace/control/ss"
                    ? styles.navLinkActive
                    : styles.navLink
                }
              >
                <FontAwesomeIcon icon={faMagnifyingGlassDollar} />
                <span className={styles.textNavigationList}>
                  Забов&apos;язання (Кредити, девіденти)
                </span>
              </div>
            </Link>
          </div>
        </Accordion>

        <Accordion
          title={"Центр керування"}
          defaultOpen={isOpenList === "helpers"}
        >
          <div className={styles.listLink}>
            {checkView("materials") && (
              <Link href={"/workspace/helpers/materials"}>
                <div
                  className={
                    pathname === "/workspace/helpers/materials"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faCarrot} />
                  <span className={styles.textNavigationList}>Сировина</span>
                </div>
              </Link>
            )}
            {checkView("categories") && (
              <Link href={"/workspace/helpers/categories"}>
                <div
                  className={
                    pathname === "/workspace/helpers/categories"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faLayerGroup} />
                  <span className={styles.textNavigationList}>Категорії</span>
                </div>
              </Link>
            )}
            {checkView("not_formal") && (
              <Link href={"/workspace/helpers/not-formal"}>
                <div
                  className={
                    pathname === "/workspace/helpers/not-formal"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faBreadSlice} />
                  <span className={styles.textNavigationList}>
                    Напівфабрикати
                  </span>
                </div>
              </Link>
            )}
            {checkView("product") && (
              <Link href={"/workspace/helpers/product"}>
                <div
                  className={
                    pathname === "/workspace/helpers/product"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faBurger} />
                  <span className={styles.textNavigationList}>Продукти</span>
                </div>
              </Link>
            )}
            {checkView("check_data") && (
              <Link href={"/workspace/helpers/check"}>
                <div
                  className={
                    pathname === "/workspace/helpers/check"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faBank} />
                  <span className={styles.textNavigationList}> Рахунок</span>
                </div>
              </Link>
            )}
            {checkView("creditors") && (
              <Link href={"/workspace/helpers/creditors"}>
                <div
                  className={
                    pathname === "/workspace/helpers/creditors"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faCartFlatbed} />
                  <span className={styles.textNavigationList}>
                    Постачальники
                  </span>
                </div>
              </Link>
            )}
            {checkView("debitors") && (
              <Link href={"/workspace/helpers/debitors"}>
                <div
                  className={
                    pathname === "/workspace/helpers/debitors"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faCartShopping} />
                  <span className={styles.textNavigationList}>Покупці</span>
                </div>
              </Link>
            )}
            {checkView("employee") && (
              <Link href={"/workspace/helpers/employee"}>
                <div
                  className={
                    pathname === "/workspace/helpers/employee"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faUserTie} />
                  <span className={styles.textNavigationList}>
                    Співробітник
                  </span>
                </div>
              </Link>
            )}
            {checkView("roles") && (
              <Link href={"/workspace/helpers/roles"}>
                <div
                  className={
                    pathname === "/workspace/helpers/roles"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faUserShield} />
                  <span className={styles.textNavigationList}>
                    Ролі та права
                  </span>
                </div>
              </Link>
            )}
            {checkView("items_of_income_and_expenses") && (
              <Link href={"/workspace/helpers/items-of-income-and-expenses"}>
                <div
                  className={
                    pathname ===
                    "/workspace/helpers/items-of-income-and-expenses"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faMoneyBills} />
                  <span className={styles.textNavigationList}>
                    Статі доходів і витрат
                  </span>
                </div>
              </Link>
            )}
          </div>
        </Accordion>

        <Accordion title={"Аналітика"} defaultOpen={isOpenList === "analitic"}>
          <div className={styles.listLink}>
            {checkView("debitors_control") && (
              <Link href={"/workspace/control/debitors-control"}>
                <div
                  className={
                    pathname === "/workspace/control/debitors-control"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faCashRegister} />
                  <span className={styles.textNavigationList}>Покупці</span>
                </div>
              </Link>
            )}
            {checkView("creditors_control") && (
              <Link href={"/workspace/control/creditors-control"}>
                <div
                  className={
                    pathname === "/workspace/control/creditors-control"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faFileInvoice} />
                  <span className={styles.textNavigationList}>
                    Постачальники
                  </span>
                </div>
              </Link>
            )}

            <Link href={"/workspace/analitic/cost-of-goods"}>
              <div
                className={
                  pathname === "/workspace/analitic/cost-of-goods"
                    ? styles.navLinkActive
                    : styles.navLink
                }
              >
                <FontAwesomeIcon icon={faCoins} />
                <span className={styles.textNavigationList}>Собівартість</span>
              </div>
            </Link>

            {checkView("raw") && (
              <Link href={"/workspace/movement/raw"}>
                <div
                  className={
                    pathname === "/workspace/movement/raw"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faTruckLoading} />
                  <span className={styles.textNavigationList}>
                    Рух сировини
                  </span>
                </div>
              </Link>
            )}
            {checkView("nf") && (
              <Link href={"/workspace/movement/nf"}>
                <div
                  className={
                    pathname === "/workspace/movement/nf"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faTruckLoading} />
                  <span className={styles.textNavigationList}>
                    Рух напівфабрикатів
                  </span>
                </div>
              </Link>
            )}
            {checkView("products") && (
              <Link href={"/workspace/movement/products"}>
                <div
                  className={
                    pathname === "/workspace/movement/products"
                      ? styles.navLinkActive
                      : styles.navLink
                  }
                >
                  <FontAwesomeIcon icon={faTruckLoading} />
                  <span className={styles.textNavigationList}>
                    Рух продукції
                  </span>
                </div>
              </Link>
            )}
          </div>
        </Accordion>

        <Accordion title={"Звіти"} defaultOpen={isOpenList === "reports"}>
          <div className={styles.listLink}>
            <Link href={"/workspace/reports/production/default"}>
              <div
                className={
                  pathname === "/workspace/reports/production/default"
                    ? styles.navLinkActive
                    : styles.navLink
                }
              >
                <FontAwesomeIcon icon={faChartSimple} />
                <span className={styles.textNavigationList}>
                  Звіт по виробництву
                </span>
              </div>
            </Link>

            <Link href={"/workspace/reports/production/abc"}>
              <div
                className={
                  pathname === "/workspace/reports/production/abc"
                    ? styles.navLinkActive
                    : styles.navLink
                }
              >
                <FontAwesomeIcon icon={faChartSimple} />
                <span className={styles.textNavigationList}>
                  Звіт по виробництву (ABC)
                </span>
              </div>
            </Link>

            <Link href={"/workspace/reports/accounting/default"}>
              <div
                className={
                  pathname === "/workspace/reports/accounting/default"
                    ? styles.navLinkActive
                    : styles.navLink
                }
              >
                <FontAwesomeIcon icon={faChartSimple} />
                <span className={styles.textNavigationList}>
                  Звіт по продажах
                </span>
              </div>
            </Link>

            <Link href={"/workspace/reports/accounting/abc"}>
              <div
                className={
                  pathname === "/workspace/reports/accounting/abc"
                    ? styles.navLinkActive
                    : styles.navLink
                }
              >
                <FontAwesomeIcon icon={faChartSimple} />
                <span className={styles.textNavigationList}>
                  Звіт по продажах (ABC)
                </span>
              </div>
            </Link>

            <Link href={"/workspace/reports/salary-report"}>
              <div
                className={
                  pathname === "/workspace/reports/salary-report"
                    ? styles.navLinkActive
                    : styles.navLink
                }
              >
                <FontAwesomeIcon icon={faChartSimple} />
                <span className={styles.textNavigationList}>
                  Звіт по зарплатах
                </span>
              </div>
            </Link>

            <Link href={"/workspace/reports/pnl"}>
              <div
                className={
                  pathname === "/workspace/reports/pnl"
                    ? styles.navLinkActive
                    : styles.navLink
                }
              >
                <FontAwesomeIcon icon={faChartSimple} />
                <span className={styles.textNavigationList}>P&L</span>
              </div>
            </Link>

            <Link href={"/workspace/reports/cashflow"}>
              <div
                className={
                  pathname === "/workspace/reports/cashflow"
                    ? styles.navLinkActive
                    : styles.navLink
                }
              >
                <FontAwesomeIcon icon={faChartSimple} />
                <span className={styles.textNavigationList}>
                  CashFlow (Звіт по фінансах)
                </span>
              </div>
            </Link>
          </div>
        </Accordion>
      </nav>
    </section>
  );
};
