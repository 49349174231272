import styles from "./styles.module.scss";
import { Button, Flex, Input } from "@mantine/core";
import { FetchAPI } from "@/hooks/fetch";
import { Notification } from "@/hooks/notification";
import { Select } from "@/components";
import { useEffect, useState } from "react";

export const AccuralModal = ({
  closeFunction,
  onUpdate,
}: {
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const [load, setLoad] = useState<boolean>(true);
  const [staffs, setStaffs] = useState<any[] | null>(null);
  const [selectedStaff, setSelectedStaff] = useState<any>(null);

  const init = async () => {
    await setLoad(true);
    const { data: staffs } = await FetchAPI("GET", `/api/helper/staff`);

    await setStaffs(staffs.data);

    await setLoad(false);
  };

  const submit = async (e: any) => {
    e.preventDefault();

    const formData: any = new FormData(e.target);
    const jsonObject: any = {};

    for (const [key, value] of formData.entries()) {
      jsonObject[key] = value;
    }

    await FetchAPI("POST", `/api/controls/accural`, jsonObject, null, {
      "Content-Type": "application/json",
    });

    await Notification("Нарахування виконано", { autoClose: 3000 }, "info");

    closeFunction();
    onUpdate();
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <form className={styles.content} onSubmit={submit}>
      {staffs && (
        <Select
          placeholder={"Працівник"}
          name={"staff_id"}
          list={staffs}
          required={true}
          onChange={(e: any) => setSelectedStaff(e.target.value)}
          error={{
            enabled: staffs?.length === 0,
            text: "Відсутні працівники",
          }}
        />
      )}

      <Input.Wrapper label="Сумма оплати" description="Введіть сумму оплати">
        <Input
          type="number"
          step={"0.01"}
          name="summ"
          required={true}
          placeholder="Сумма"
        />
      </Input.Wrapper>

      <Flex gap={14} w={"100%"}>
        <Button
          variant="outline"
          type="button"
          w={"50%"}
          onClick={closeFunction}
        >
          Відміна
        </Button>
        <Button
          variant="gradient"
          type="submit"
          w={"50%"}
          disabled={!selectedStaff}
        >
          Внести оплату
        </Button>
      </Flex>
    </form>
  );
};
