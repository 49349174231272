import Modals from "..";
import moment from "moment";
import styles from "./styles.module.scss";
import { FetchAPI } from "@/hooks/fetch";
import { Modal } from "@/containers/modal";
import { useCallback, useEffect, useState } from "react";
import {
  ModalButtons,
  MultiSelect,
  ProductsList,
  RadioGroup,
  Select,
  Textarea,
} from "@/components";
import {
  Alert,
  Input,
  SegmentedControl,
  Table,
  TableData,
} from "@mantine/core";
import { Notification } from "@/hooks/notification";

export const SalesAccounting = ({
  edit,
  closeFunction,
  onUpdate,
}: {
  edit?: any;
  closeFunction: () => void;
  onUpdate: () => void;
}) => {
  const [data, setData] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(true);
  const [debitor, setDebitor] = useState<boolean>(false);
  const [debitorType, setDebitorType] = useState<string>("");
  const [products, setProducts] = useState<any[]>([]);
  const [customPostponement, setCustomPostponement] = useState<boolean>(false);
  const [allProducts, setAllProducts] = useState<boolean>(false);
  const [updator, setUpdate] = useState<boolean>(false);
  const [selectType, setSelectType] = useState<string>("retail");
  const [viewType, setViewType] = useState<"form" | "history">("form");
  const [historyData, setHistoryData] = useState<TableData | null>(null);

  const changeDebitor = useCallback(
    async (value: any) => {
      const find = data?.debitors?.find((x: any) => x.id === Number(value));

      if (find) {
        await setDebitorType(find.type);

        if (find.all_products) {
          const { data: products } = await FetchAPI(
            "GET",
            `/api/helper/product`
          );
          await setProducts(products.data);
        } else {
          const { data: products } = await FetchAPI(
            "GET",
            `/api/debitor/${value}/products`
          );
          await setProducts(products);
        }

        await setCustomPostponement(find.postponement === 0);
        await setAllProducts(find.all_products);
      }
    },
    [load]
  );

  const init = async () => {
    const { data: debitors } = await FetchAPI("GET", `/api/helper/debitors`);
    const { data: checks } = await FetchAPI("GET", `/api/helper/checks`);

    await setData({
      debitors: debitors.data,
      checks: checks.data,
    });

    await setLoad(false);
  };
  const submit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("type", debitorType);

    await FetchAPI(
      edit ? "PUT" : "POST",
      edit
        ? `/api/accounting/sales_accounting/${edit.id}`
        : `/api/accounting/sales_accounting`,
      formData,
      null,
      {
        "Content-Type": "multipart/form-data",
      }
    );

    if (edit)
      await Notification(
        `Облік продаж успішно змінено`,
        { autoClose: 3000 },
        "success"
      );
    else
      await Notification(
        `Облік продаж створено`,
        { autoClose: 3000 },
        "success"
      );
    closeFunction();
    onUpdate();
  };

  useEffect(() => {
    (async () => {
      await init();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (edit && data && !load) {
        await changeDebitor(edit.debitor_id);
      }
    })();
  }, [edit, data, load]);

  useEffect(() => {
    (async () => {
      if (edit) {
        const { data: history } = await FetchAPI(
          "GET",
          `/api/history/sales_accounting/${edit.id}`
        );

        await setHistoryData({
          head: ["Дата", "Час", "Працівник"],
          body: history,
        });
      }
    })();
  }, [edit]);

  console.log(edit);
  return (
    <>
      {!!edit && (
        <SegmentedControl
          fullWidth
          data={[
            { label: "Редагувати", value: "form" },
            { label: "Історія", value: "history" },
          ]}
          onChange={(e: any) => setViewType(e)}
        />
      )}

      <form
        className={styles.content}
        onSubmit={submit}
        style={{ display: viewType === "form" ? "" : "none" }}
      >
        <Input.Wrapper
          label="Дата"
          description="Дата продажі/повернення/бронювання"
          error={edit ? `Неможливо редагувати дату` : ""}
        >
          <Input
            type="date"
            name="date"
            disabled={!!edit}
            required={true}
            value={moment(edit?.date).format("YYYY-MM-DD")}
            placeholder="Дата"
          />
        </Input.Wrapper>

        {!load && (
          <Select
            placeholder={"Рух"}
            required={true}
            defaultSelect={edit?.movement}
            list={[
              { id: "return", title: "Повернення" },
              { id: "sale_from_realization", title: "Продаж від реалізації" },
              { id: "foreclosure_sale", title: "Продаж" },
              { id: "booking", title: "Бронювання" },
            ]}
            name={"movement"}
          />
        )}

        {!load && (
          <Select
            placeholder={"Покупець"}
            required={true}
            list={data.debitors}
            name={"debitor_id"}
            defaultSelect={edit?.debitor_id}
            addButton={() => setDebitor(true)}
            error={{
              enabled: data?.debitors?.length === 0,
              text: "Відсутні покупці",
            }}
            onChange={(e: any) => changeDebitor(e.target.value)}
          />
        )}

        {customPostponement && (
          <Input.Wrapper
            label="Відтермінування"
            description="Відтермінування оплати"
          >
            <Input
              type="number"
              min={0}
              max={356}
              name="postponement"
              defaultValue={edit?.postponement}
              required={true}
              placeholder="Відтермінування (к-сть днів)"
            />
          </Input.Wrapper>
        )}

        {!load && (
          <Select
            placeholder={"Рахунок"}
            required={true}
            list={data.checks}
            name={"check_id"}
            defaultSelect={edit?.check_id}
            error={{
              enabled: data?.checks?.length === 0,
              text: "Відсутні рахунки",
            }}
          />
        )}

        <RadioGroup
          list={[
            {
              title: "ПДВ",
              value: "pdv",
            },
            {
              title: "Без ПДВ",
              value: "no_pdv",
            },
          ]}
          defaultSelect={edit?.pdv}
          name="pdv"
        />

        {((products && products?.length > 0) || edit) && !allProducts && (
          <ProductsList
            placeholder={"Товари"}
            name={"products"}
            list={
              edit
                ? products.map((p: any) => {
                    const find = edit.sales_accounting_data?.find(
                      (x: any) => x.product_id === p.id
                    );

                    return {
                      ...p,
                      counts: find?.product_counts || 0,
                      price: find?.product_price || 0,
                    };
                  })
                : products
            }
          />
        )}

        {(products || edit) && allProducts && (
          <MultiSelect
            placeholder={"Товар"}
            name={"products[ids]"}
            list={products}
            subParams={{
              mathType: "multiplication",
              process: [{ index: 0 }, { index: 1 }],
              summIndex: 2,
            }}
            subInputs={[
              {
                type: "number",
                name: `products[counts]`,
                readOnly: false,
                defaltField: "product_counts",
                defValue: !!edit,
                updator,
                isDelete: true,
                subSizeCustom: "Кількість",
              },
              {
                type: "number",
                name: `products[prices]`,
                readOnly: false,
                defaltField: edit ? "product_price" : "wholesale",
                updator,
                isDelete: true,
                subSizeCustom: "Вартість",
              },
              {
                type: "number",
                name: `products[total]`,
                readOnly: false,
                defaltField: "product_price",
                updator,
                isDelete: true,
                resultField: true,
                subSizeCustom: "Сумма",
              },
            ]}
            isDelete={true}
            summes={[1, 2]}
            error={{
              enabled: data?.products?.length === 0,
              text: "Відсутні продукти",
            }}
            defaultKey="product_id"
            defaultValues={edit?.sales_accounting_data}
          />
        )}

        <Textarea
          placeholder="Коментар"
          defauleValue={edit?.comment}
          name="comment"
        />

        <ModalButtons
          closeFunction={closeFunction}
          disabled={data?.products?.length < 0}
        />
      </form>

      <div
        className={styles.history}
        style={{ display: viewType === "history" ? "" : "none" }}
      >
        {historyData ? (
          <Table data={historyData} />
        ) : (
          <Alert variant="light" w="100%" color="blue" title="Історія">
            Історія редагування відсутня
          </Alert>
        )}
      </div>
      <Modal
        title={"Створити покупця"}
        open={debitor}
        closeFunction={() => {
          setDebitor(false);
        }}
      >
        <Modals.Debitor
          edit={false}
          closeFunction={() => {
            setDebitor(false);
          }}
          onUpdate={init}
        />
      </Modal>
    </>
  );
};
