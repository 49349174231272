import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { DatePicker } from "@mantine/dates";
import { useRouter } from "next/router";
import "moment/locale/uk";
import type { DateValue } from "@mantine/dates";
import { Button } from "@mantine/core";

moment.locale("uk");

const RangeCalendarData = ({
  defaultValues,
  defaultActive,
  onChange,
}: {
  defaultValues?: any;
  defaultActive?: number;
  onChange: (e: any) => void;
}): any => {
  const [values, setValues] = useState<DateValue[] | any>(
    defaultValues || [new Date()]
  );
  const [open, setOpen] = useState<boolean>(false);
  const [active, setActive] = useState<number | null>(defaultActive || null);
  const containerRef = useRef<any>(null);
  const changeValue = (e: DateValue[], reset = true) => {
    if (reset) setActive(null);
    setValues(e);
  };

  const toggle = () => {
    setOpen(!open);
  };

  const selectDefault = (int: number, type: "days" | "months" | "year") => {
    if (int === 0) return changeValue([new Date()]);
    changeValue([moment().subtract(int, type).toDate(), new Date()], false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setOpen(false);
    }
  };
  const router = useRouter();
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [router]);

  return (
    <div className={styles.dateRanges} ref={containerRef}>
      <div className={styles.value} onClick={toggle}>
        {moment(values[0]).format("YYYY MMMM DD")}{" "}
        {`${values[1] ? `- ${moment(values[1]).format("YYYY MMMM DD")}` : ""}`}
      </div>
      <div className={`${styles.containerPopUp} ${open ? styles.show : ""}`}>
        <div className={styles.pickerContent}>
          <DatePicker
            type="range"
            numberOfColumns={2}
            size="sm"
            value={values}
            defaultValue={values}
            onChange={changeValue}
          />
          <div className={styles.defaultsVariants}>
            <button
              className={`${styles.variant} ${
                active === 1 ? styles.active : ""
              }`}
              onClick={() => {
                setActive(1);
                selectDefault(0, "days");
              }}
            >
              Сьогодні
            </button>
            <button
              className={`${styles.variant} ${
                active === 2 ? styles.active : ""
              }`}
              onClick={() => {
                setActive(2);
                selectDefault(7, "days");
              }}
            >
              Тиждень
            </button>
            <button
              className={`${styles.variant} ${
                active === 3 ? styles.active : ""
              }`}
              onClick={() => {
                setActive(3);
                selectDefault(1, "months");
              }}
            >
              Місяць
            </button>
            <button
              className={`${styles.variant} ${
                active === 4 ? styles.active : ""
              }`}
              onClick={() => {
                setActive(4);
                selectDefault(3, "months");
              }}
            >
              Квартал
            </button>
            <button
              className={`${styles.variant} ${
                active === 5 ? styles.active : ""
              }`}
              onClick={() => {
                setActive(5);
                selectDefault(6, "months");
              }}
            >
              Пів року
            </button>
            <button
              className={`${styles.variant} ${
                active === 6 ? styles.active : ""
              }`}
              onClick={() => {
                setActive(6);
                selectDefault(1, "year");
              }}
            >
              Рік
            </button>
            <button
              className={`${styles.variant} ${
                active === 7 ? styles.active : ""
              }`}
              onClick={() => {
                setActive(7);
                selectDefault(10, "year");
              }}
            >
              Весь час
            </button>
          </div>
        </div>
        <div className={styles.pickerFooter}>
          <Button
            w={"50%"}
            variant="outline"
            onClick={() => {
              changeValue([new Date()]);
              setOpen(false);
            }}
          >
            Відміна
          </Button>
          <Button
            w={"50%"}
            variant="filled"
            onClick={() => {
              onChange(values);
              setOpen(false);
            }}
          >
            Прийняти
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RangeCalendarData;
