import Link from "next/link";
import styles from "./styles.module.scss";
import {
  faCogs,
  faHome,
  faInfo,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Logotype } from "../Logotype";
import { useRouter } from "next/router";
import { useSession } from "@/context/session";

export const CabinetMenu = () => {
  const { pathname } = useRouter();

  const { session } = useSession();
  return (
    <section className={styles.navigation}>
      <div className={styles.logo}>
        <Logotype />
      </div>
      <nav className={styles.navsList}>
        <Link href={"/cabinet"}>
          <div
            className={
              pathname === "/cabinet" ? styles.navLinkActive : styles.navLink
            }
          >
            <FontAwesomeIcon icon={faHome} />
            <span>Виробництва</span>
          </div>
        </Link>
        <Link href={"#"} className={styles.disabled}>
          <div
            className={`${
              pathname === "/settings" ? styles.navLinkActive : styles.navLink
            } `}
          >
            <FontAwesomeIcon icon={faCogs} />
            <span>Налаштування</span>
          </div>
        </Link>
      </nav>

      {session.is_admin && (
        <nav className={styles.navsList}>
          <Link href={"/admin/clients"}>
            <div
              className={
                pathname === "/admin/clients"
                  ? styles.navLinkActive
                  : styles.navLink
              }
            >
              <FontAwesomeIcon icon={faUserCog} />
              <span>Клієнти</span>
            </div>
          </Link>

          <Link href={"/admin/helps"}>
            <div
              className={
                pathname === "/admin/helps"
                  ? styles.navLinkActive
                  : styles.navLink
              }
            >
              <FontAwesomeIcon icon={faInfo} />
              <span>Довідник</span>
            </div>
          </Link>
        </nav>
      )}
    </section>
  );
};
