import styles from "./styles.module.scss";
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { Flex, Box, Button } from "@mantine/core";
import { useState } from "react";
import { saveChartAsImage } from "@/hooks/save-chart";
import { NoneChartData } from "../NoneChartData";

type AreaType = { key: string; color: string };

// Пользовательский Tooltip, который отображается только для активной точки
const CustomTooltip = ({ active, payload, data }: any, areas: any[]) => {
  if (active && payload && payload.length) {
    const tooltipData = payload.map((entry: any) => {
      const dataItem = data?.find((item: any) => item.name === entry.name);
      return { ...entry, ...dataItem };
    });

    return (
      <div className={styles.chartTooltip}>
        {tooltipData
          .filter((e: any) => e.value > 0)
          .map((entry: any, index: any) => (
            <p key={index}>
              {areas.find((x) => x.key === entry.name)?.title}:{" "}
              <b>{entry.value}</b>
            </p>
          ))}
      </div>
    );
  }

  return null;
};

const ReportChart = ({
  axisKey,
  chart,
  areas,
  boxes,
  values,
  panel = true,
  mediaBoxP = 14,
}: {
  axisKey: string;
  chart: any[];
  areas: AreaType[];

  boxes?: any[];
  values?: any;
  panel?: boolean;
  mediaBoxP?: number;
}) => {
  const [screened, setScreened] = useState<boolean>(false);

  return (
    <div className={styles.reportChart}>
      <div className={styles.mediaBox} style={{ padding: mediaBoxP }}>
        <div
          className={styles.content}
          style={
            screened
              ? { borderRadius: 0, background: "#fff", boxShadow: "none" }
              : {}
          }
        >
          {chart?.length <= 0 && <NoneChartData text={"Данні відсутні"} />}
          <ResponsiveContainer width="100%" height={300} id={"chart-data-el"}>
            <LineChart
              width={10000}
              height={300}
              data={chart}
              margin={{ top: 25, right: 36, bottom: 5, left: 36 }}
            >
              <XAxis
                dataKey={axisKey}
                fontSize={12}
                color={screened ? "#000" : "#fff"}
                fill={screened ? "#000" : "#fff"}
                stroke={screened ? "#000" : "#fff"}
                strokeWidth={0.5}
                padding={{ left: 36, right: 36 }}
                interval={chart.length > 7 ? Math.round(chart.length / 7) : 0}
              />
              <Tooltip
                content={(e: any) => CustomTooltip(e, areas)}
                isAnimationActive={false}
                cursor={false}
              />
              {areas.map((area: AreaType) => (
                <Line
                  key={area.key}
                  dataKey={area.key}
                  type="monotone"
                  stroke={screened ? "#000" : "#fff"}
                  strokeWidth={3}
                  activeDot={true}
                  animationDuration={0}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>

          {panel && (
            <Box p={"7px 14px"} style={{ borderRadius: 7 }} bg={"#fff"}>
              <Flex gap={14} justify={"space-between"} align={"center"}>
                <Flex gap={14} justify={"center"} align={"center"}>
                  {/* <Switch
                label={"Кількість"}
                defaultChecked={viewTotalSalesCounts}
                onChange={(e: any) => setViewTotalSalesCounts(e.target.checked)}
              />
              <Switch
                label={"Сумма"}
                defaultChecked={viewTotalSalesValue}
                onChange={(e: any) => setViewTotalSalesValue(e.target.checked)}
              /> */}
                </Flex>
                <Button
                  variant="outline"
                  onClick={async () => {
                    await setScreened(true);
                    const svgElement: any =
                      document.querySelector("#chart-data-el"); // Найти элемент SVG на странице

                    if (svgElement)
                      saveChartAsImage(
                        svgElement,
                        "Графік постачальників",
                        () => {
                          setScreened(false);
                        }
                      );
                  }}
                >
                  Зберегти
                </Button>
              </Flex>
            </Box>
          )}
        </div>
      </div>
      {values && (
        <div className={styles.mediaBox}>
          {boxes?.map((box: any, key: number) => (
            <div
              key={key}
              className={styles.dataCount}
              style={{ width: `calc(100% / ${boxes.length})` }}
            >
              <div className={styles.countTitle}>{box.title}</div>
              <div className={styles.countValue}>
                {values[box.key]}
                {/* {loadItems ? (
                <Loader color="blue" size="xs" m={"7px 0 0 0"} />
              ) : (
                formatCurrency(0)
              )} */}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ReportChart;
