import axios, { AxiosRequestConfig, Method } from "axios";
import getConfig from "next/config";
import { getCookie } from "cookies-next";
const {
  publicRuntimeConfig: { API_URL },
} = getConfig();

export const FetchAPIGl = async (
  type: Method,
  url: string,
  formData?: any,
  config?: any,
  customHeaders?: any
) => {
  const institution = getCookie("mafi_institution_token");
  
  const headers = {
    Authorization: `Bearer ${getCookie("mafi-user-token")}`,
    Institution: institution,
    ...customHeaders,
  };

  const fetchObj: AxiosRequestConfig = {
    method: type,
    url: API_URL + url,
    headers: headers,
    data: formData,
    ...config,
  };

  return await axios(fetchObj);
};
